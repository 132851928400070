import React from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import { KeywordType } from "types";
import { Catgroup, Gender } from "utils/constants";
import { capitalize } from "lodash";
import CtaButton from "components/atoms/CtaButton";
import { useMemo } from "react";
import sanitizeName from "utils/sanitizeName";
import AIStreammingIcon from "public/icons/ai_generate_16.svg";
import Icon from "components/atoms/Icon";
import BigSpinner from "components/atoms/BigSpinner";
import { useEffect } from "react";

interface IAutoClassificationViewProps {
  data?: {
    gender?: string;
    catgroup?: string;
    basecat?: string;
    subcat?: string;
    colorai?: string;
  };
  loading?: boolean;
  errors?: string[];
  className?: string;
  onApply: (keywords: KeywordType[]) => void;
  isRequestError?: boolean;
  isAutomatic?: boolean;
}

const DataRow: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="mb-1.5">
      <p className="body-100">
        {label + ": "}
        <span className="font-semibold">{value}</span>
      </p>
    </div>
  );
};

const AutoClassificationView: React.FC<IAutoClassificationViewProps> = ({
  data,
  loading,
  errors,
  className,
  isRequestError,
  onApply,
  isAutomatic,
}) => {
  const { t } = useTranslation("common");

  const keywords = useMemo(() => {
    if (data) {
      return Object.entries(data).map(([key, value]) => {
        switch (key) {
          case "colorai":
            return {
              style: "color",
              value: capitalize(t(value)),
              origin: value,
            };
          case "gender":
            return {
              style: key,
              value: Gender[value as keyof typeof Gender],
              origin: value,
            };
          case "catgroup":
            return {
              style: key,
              value: Catgroup[value as keyof typeof Catgroup],
              origin: value,
            };
          default:
            return {
              style: key,
              value: capitalize(sanitizeName(value)),
              origin: value,
            };
        }
      });
    }
  }, [data]);

  const applyKeywords = () => {
    if (keywords) {
      onApply(keywords);
    }
  };

  useEffect(() => {
    if (keywords) {
      applyKeywords();
    }
  }, [isAutomatic, keywords]);

  return (
    <div className={cn("mx-auto max-w-[265px]", className)}>
      {!!errors?.length || isRequestError ? (
        <p className="body-100">{t("cant_classify")}</p>
      ) : (
        <div>
          {loading && (
            <p className="mb-2 text-sm leading-tight !text-cta-600 flex items-center justify-start gap-2">
              <Icon
                component={AIStreammingIcon}
                viewBox="0 0 16 16"
                width={16}
                height={16}
                className="fill-cta-600"
              />
              {t("classification_in_progress")}
            </p>
          )}
          {loading && (
            <div className="grid mt-4 place-items-center">
              <BigSpinner className="w-10 h-10" />
            </div>
          )}

          {!!data && (
            <div className="max-w-[252px]">
              <div className="w-full text-sm leading-tight font-normal text-typography-body-normal">
                {t(Gender[data.gender as keyof typeof Gender])}
              </div>
              <div className="w-full text-sm leading-tight font-normal text-typography-body-normal mt-1">
                {t(Catgroup[data?.catgroup as keyof typeof Catgroup])},{" "}
                {!!data?.basecat && capitalize(t(data.basecat))},{" "}
                {!!data?.subcat && capitalize(t(data.subcat))}
              </div>
              {!!data?.colorai && (
                <div className="flex items-center gap-1 mt-1">
                  <div
                    style={{ backgroundColor: data.colorai }}
                    className="w-3 h-3 border border-border-normal rounded-full"
                  />
                  <span className="first-letter:uppercase text-sm">
                    {t(data.colorai)}
                  </span>
                </div>
              )}
              {!isAutomatic && (
                <div className="w-full flex items-center justify-end">
                  <CtaButton
                    className="!mt-4 !py-2"
                    onClick={applyKeywords}
                    variant="secondary"
                  >
                    {t("apply_classification")}
                  </CtaButton>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutoClassificationView;
