import { useApi } from "contexts/ApiContext";
import { UseQueryOptions, useQuery } from "react-query";

export const useGetShopifyAvailalbeFilterDates = (
  id: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { shopifyGlobalApi } = useApi();
  return useQuery(
    ["allStyleList", id],
    () => shopifyGlobalApi.getShopifyAvailalbeFilterDates(id),
    config
  );
};

export const useGetShopifyProducts = (
  params: { id: string; date: string },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { shopifyGlobalApi } = useApi();
  return useQuery(
    ["allStyleList", params],
    () => shopifyGlobalApi.getShopifyProducts(params),
    config
  );
};
