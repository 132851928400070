import { IProductDetailData } from "api/ProductApi";
import { useApi } from "contexts/ApiContext";
import { useQuery, UseQueryOptions } from "react-query";

export const useGetProductDetail = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { products } = useApi();
  return useQuery<IProductDetailData[]>(
    ["daily-latest-data", params],
    () => products.getProductDetails(params).then((res) => res),
    config
  );
};
