import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";

interface BrandOptionCardProps {
  icon: any;
  title: string;
  subTitle: string;
  onClick?: () => void;
  className?: string;
  children: any;
}

const BrandOptionCard = ({
  icon,
  title,
  subTitle,
  onClick,
  className,
  children,
}: BrandOptionCardProps) => {
  const { t } = useTranslation("common");
  const [inputValue, setInputValue] = useState<string>();

  return (
    <button
      className={cn(
        "w-full px-10 py-6 rounded-md border border-border-normal hover:bg-gray-20 active:border-2 active:border-gray-50 active:px-[39px] active:py-[23px]",
        className
      )}
      onClick={onClick}
    >
      <div className="w-full flex items-center gap-4">
        <div className="w-[66px] h-14 border rounded-full border-border-normal">
          <div className="w-full h-full flex items-center justify-center">
            {icon}
          </div>
        </div>
        <div className="flex flex-col justify-between w-full">
          <h3 className="text-lg leading-tight font-bold text-black w-full text-left">
            {title}
          </h3>
          <p className="text-sm leading-normal font-normal text-typography-tag-subtle w-full text-left">
            {subTitle}
          </p>
        </div>
      </div>
      {children}
    </button>
  );
};

export default BrandOptionCard;
