import React, { useMemo } from "react";
import { AdminPageNames, PageNames } from "utils/constants";
import cn from "classnames";
import SidebarItem from "./SidebarItem";
import { Fragment } from "react";
import { useUser } from "contexts/UserContext";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import SidebarItemGroup from "./SidebarItemGroup";
import Icon from "components/atoms/Icon";
import SettingIcon from "public/icons/setting_16.svg";

interface IPage {
  icon: (props: any) => any;
  name: PageNames | string;
  href: string;
  title: string;
  onClick?: Function;
  isMoreGroup?: boolean;
  className?: string;
  isHidden?: boolean;
  hideIcon?: boolean;
  showBadge?: boolean;
}

interface INavigationProps {
  pages: IPage[];
  adminPages: IPage[];
  style?: any;
  className?: string;
  isExpanded: boolean;
  isMoreOpened?: boolean;
}

const Navigation: React.FC<INavigationProps> = ({
  pages,
  adminPages,
  style,
  className,
  isMoreOpened,
  isExpanded,
}) => {
  const { t } = useTranslation("common");
  const { isAdmin } = useUser();
  const router = useRouter();
  const preparedPages = pages.filter((el) => !el.isHidden && !el.isMoreGroup);
  const moreGroupPages = pages.filter((el) => el.isMoreGroup);
  const preparedAdminPages = adminPages.filter((el) => !el.isHidden);

  const isAdminPage = useMemo(
    () =>
      !!AdminPageNames.filter((pageName) => router.pathname.includes(pageName))
        .length,
    [AdminPageNames, router.pathname]
  );

  return (
    <div
      style={style}
      className={cn(
        "overflow-y-auto px-4",
        !isExpanded && "no-scrollbar !px-0",
        className
      )}
    >
      {preparedPages.map((page, index) => (
        <Fragment key={page.name + index.toString()}>
          {page.name === "#" ? (
            <>
              <SidebarItem expanded={isExpanded} {...page} />
              {isMoreOpened && (
                <>
                  {moreGroupPages?.map((el, i) => (
                    <SidebarItem
                      key={el.name + i.toString()}
                      expanded={isExpanded}
                      {...el}
                    />
                  ))}
                </>
              )}
            </>
          ) : (
            <SidebarItem expanded={isExpanded} {...page} />
          )}
        </Fragment>
      ))}
      {isAdmin && (
        <SidebarItemGroup
          icon={
            <Icon
              component={SettingIcon}
              viewBox="0 0 16 16"
              width={16}
              height={16}
            />
          }
          label={t("admin")}
          expanded={isExpanded}
          defaultOpen={isAdminPage}
        >
          {preparedAdminPages.map((page, index) => (
            <SidebarItem
              expanded={isExpanded}
              key={page.name + index.toString()}
              hideIcon={isExpanded}
              {...page}
            />
          ))}
        </SidebarItemGroup>
      )}
    </div>
  );
};

export default Navigation;
