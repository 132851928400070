import cn from "classnames";
import CloseIcon from "public/icons/close_16.svg";
import Icon from "./Icon";
import ColorTile from "./ColorTile";
import FlagIcon from "./ExploreTrends/FlagIcon";

interface FilterLabelButtonProps {
  label: string;
  regionCode?: string;
  isColor?: boolean;
  onClose?: Function | null;
  className?: string;
}

const FilterLabelButton: React.FC<FilterLabelButtonProps> = ({
  label,
  regionCode,
  isColor = false,
  onClose,
  className,
}) => {
  return (
    <div
      className={cn(
        "bg-gray-40 rounded-[50px] py-[3px] px-[9px] flex items-center justify-start gap-1 text-xs text-heading",
        className
      )}
    >
      {regionCode && <FlagIcon countryCode={regionCode} />}
      {isColor && <ColorTile color={label} className="w-3 h-3 rounded-full" />}
      <span>{label}</span>
      {onClose && (
        <Icon
          component={CloseIcon}
          viewBox="0 0 16 16"
          width={16}
          height={16}
          onClick={() => onClose()}
          className="fill-gray-70 cursor-pointer"
        />
      )}
    </div>
  );
};

export default FilterLabelButton;
