import { useCallback } from "react";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";
import { useDrop } from "react-dnd";
import { useTeam } from "contexts/TeamContext";

interface DropableLibraryButtonProps {
  id?: number;
  name?: string;
}

export default function DropableLibraryButton({
  id,
  name,
}: DropableLibraryButtonProps) {
  const { t } = useTranslation("common");
  const { push } = useTeam();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "Card",
    drop: () => ({ name: name || "Library", id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  const backToMediaLib = useCallback(() => {
    if (id) {
      push(`/media-lib/${id}`);
    } else {
      push("/media-lib");
    }
  }, [push]);

  return (
    <button
      ref={drop}
      className={cn(
        "font-bold text-typography-tag-subtle text-lg leading-tight hover:!text-gray-950 hover:underline rounded-2xl py-0 border border-white px-1",
        isActive && "border-gray-950 border"
      )}
      onClick={backToMediaLib}
    >
      {name || t("library")}
    </button>
  );
}
