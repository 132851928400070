import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Direction } from "react-range";
import { theme } from "tailwind.config";
// components
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import CustomButton from "components/atoms/CustomButton";
import { OneSideRange } from "components/atoms/Range";
// icon
import FilterIcon from "public/icons/filter_16.svg";
// lodash
import { debounce } from "lodash";

interface IBenchmarkHeaderProps {
  similarity: number;
  filterCount: number;
  handleFilter: () => void;
  setSimilarity: (value: number) => void;
}

export const BenchmarkHeader: React.FC<IBenchmarkHeaderProps> = ({
  similarity,
  handleFilter,
  setSimilarity,
  filterCount = 0,
}) => {
  const { t } = useTranslation("common");

  const [similarityTempValue, setSimilarityTempValue] =
    useState<number>(similarity);

  useEffect(() => {
    const sendAPIRequest = debounce((value) => {
      setSimilarity(value);
    }, 500);

    sendAPIRequest(similarityTempValue);

    return () => {
      sendAPIRequest.cancel();
    };
  }, [similarityTempValue]);

  return (
    <div className="flex xxs:flex-col md:!flex-row xxs:items-start md:!items-center justify-end gap-2">
      <div className="flex items-center xxs:py-4 md:!py-0 justify-end gap-3">
        <div className="text-typography-body-normal text-xs font-normal leading-tight">
          {t("similarity")}
        </div>
        <div className="md:!w-[148px] xxs:w-[calc(100vw_-_100px)] relative">
          <ParentSize>
            {({ width }) => (
              <div style={{ width }} className="h-3 pt-[5px]">
                <OneSideRange
                  trackStyle={{
                    marginTop: 0,
                  }}
                  hideValues
                  max={100}
                  min={0}
                  trackColors={[
                    theme.extend.colors.gray[30],
                    theme.extend.colors.gray[30],
                  ]}
                  hoverTrackColors={[
                    theme.extend.colors.gray[40],
                    theme.extend.colors.gray[40],
                  ]}
                  thumbColor="!bg-gray-950 !ring-0 !ring-offset-0 !w-3 !h-3"
                  barStyle={{
                    height: "3px",
                    borderRadius: "6px",
                  }}
                  direction={Direction.Right}
                  onChange={setSimilarityTempValue}
                  value={similarityTempValue}
                />
              </div>
            )}
          </ParentSize>
          <div
            className="absolute bg-gray-950 h-[3px] top-[5px] rounded-md"
            style={{
              width: `${similarityTempValue}%`,
            }}
          />
        </div>
      </div>
      <CustomButton
        icon={FilterIcon}
        iconClassName="w-4 h-4"
        variant="secondary"
        label={
          <span>
            {t("filters")}
            {filterCount > 0 ? ` | ${filterCount}` : ""}
          </span>
        }
        className="!py-2 !px-3 xxs:w-full md:!w-auto"
        buttonTextClassName="!font-normal !text-xs"
        onClick={handleFilter}
      />
    </div>
  );
};
