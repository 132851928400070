import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import axios from "axios";
import { useUploadFile } from "./admin";
import { useEffect, useState } from "react";
import { useApi } from "contexts/ApiContext";

export const getImageDetails = async (url: string) => {
  return await axios
    .request({
      baseURL: "https://zsclassify.jellibeans.net/auto",
      method: "GET",
      params: { url },
    })
    .then((res) => res?.data);
};

export const useGetImageDetails = (
  url: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  return useQuery(["image-details", url], () => getImageDetails(url), config);
};

export const useUploadImageAndGetDetails = (
  image: File,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);

  const { mutate: upload } = useUploadFile({
    onSuccess({ data }) {
      setUploadedUrl(data);
    },
  });

  useEffect(() => {
    if (image && !!config?.enabled) {
      const data = new FormData();
      data.append("file", image as File);

      upload(data);
    }

    return () => setUploadedUrl(null);
  }, [image, config?.enabled]);

  const result = useGetImageDetails(uploadedUrl as string, {
    ...config,
    enabled: config?.enabled && !!uploadedUrl,
  });

  return { ...result, data: { data: result.data, uploadedUrl } };
};

export const useWarmGettingImageDetailsEnv = (
  config?: UseMutationOptions<any, any, any>
) => {
  const api = useApi();
  return useMutation(() => api.aiDesignAssets.warmAssetClassifier(), config);
};
