import CustomButton from "components/atoms/CustomButton";
import { useBreakpoint } from "contexts/BreakpointContext";
import { usePortal } from "contexts/PortalContext";
import { useRouter } from "next/router";
import BookIcon from "public/icons/book_16.svg";
import { useTutorialStateStore } from "stores/TutorialStateStore";

interface TutorialButtonProps {}

const TutorialButton: React.FC<TutorialButtonProps> = ({}) => {
  const { handleOpenTutorial } = useTutorialStateStore();
  const { xxs } = useBreakpoint();
  const router = useRouter();
  const { portalName } = usePortal();

  const onClick = () => {
    if (xxs) {
      router.push(`/${portalName}/tutorial`);
    } else {
      handleOpenTutorial();
    }
  };

  return (
    <CustomButton
      variant="tertiary"
      icon={BookIcon}
      viewBox="0 0 16 16"
      className="!p-2"
      onClick={onClick}
    />
  );
};

export default TutorialButton;
