import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { isDev } from "utils/checkEnv";

const AI_SEARCH_IMAGE_PROD_ENDPOINT =
  "https://ai-image-search-prod-pvns2entxq-uc.a.run.app/pinecone/query_inference";
const AI_SEARCH_IMAGE_STAGING_ENDPOINT =
  "https://ai-image-search-staging-pvns2entxq-uc.a.run.app/pinecone/query_inference";
const AI_SEARCH_IMAGE_DEV_ENDPOINT =
  "https://ai-image-search-dev-pvns2entxq-uc.a.run.app/pinecone/query_inference";

const getImageEndpoint = () => {
  if (
    process.env.NEXT_PUBLIC_ENV === "local" ||
    process.env.NEXT_PUBLIC_ENV === "development"
  ) {
    return AI_SEARCH_IMAGE_DEV_ENDPOINT;
  } else if (process.env.NEXT_PUBLIC_ENV === "staging") {
    return AI_SEARCH_IMAGE_STAGING_ENDPOINT;
  } else {
    return AI_SEARCH_IMAGE_PROD_ENDPOINT;
  }
};

export interface AIImageSearchResultType {
  request_id: number;
  similar_jbids: string[];
  referece_caption?: string;
  euclidian_distances: number[];
  score: number[];
  metadata: {
    basecat: string;
    brand: string;
    colormain: string;
    fabric: string;
    gender: string;
    geosource: string;
    price_usd: number;
    region: string;
    retailer: string;
    first_seen: number;
    restocks_8w?: number;
    restocks_6w?: number;
    restocks_4w?: number;
    restocks_2w?: number;
    jbid: string;
    name: string;
  }[];
  statistics: {
    categorical: {
      basecat: {
        [key: string]: number;
      };
      brand: {
        [key: string]: number;
      };
      colormain: {
        [key: string]: number;
      };
      fabric: {
        [key: string]: number;
      };
      gender: {
        [key: string]: number;
      };
      geosource: {
        [key: string]: number;
      };
      region: {
        [key: string]: number;
      };
      retailer: {
        [key: string]: number;
      };
    };
    numerical: {
      price_usd: {
        [key: string]: {
          p: number;
          c: number;
        };
      };
    };
  };
  distance_statistics_summary: {
    n_values_before_threshold: number;
    n_values_after_threshold: number;
    min_dist: number;
    max_dist: number;
    mean_dist: number;
    std_dist: number;
  };
  caption: string[];
}

export const getAIImageSearch = async (params: any) => {
  const customParams = {
    ...params,
    limit: 1000,
    include_metadata: true,
    include_stats: true,
    include_no_value_in_stats: true,
    index_name: isDev()
      ? "fashion-clip-all-catalogs-segmented"
      : "fashion-clip-all-catalogs",
    inference_source: "cloud_run",
    input_type: "image",
  };

  return await axios
    .request({
      method: "post",
      baseURL: getImageEndpoint(),
      data: customParams,
    })
    .then((res) => res?.data);
};

export const useAiImageSearch = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  return useQuery<AIImageSearchResultType>(
    ["ai-image-search", params],
    () => getAIImageSearch(params),
    config
  );
};
