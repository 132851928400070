import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import Icon from "components/atoms/Icon";
import ChevronRightIcon from "public/icons/caret-right_16.svg";
import ChevronEndRightIcon from "public/icons/chevron_right_end_bold_16.svg";
import ChevronLeftIcon from "public/icons/caret-left_16.svg";
import ChevronEndLeftIcon from "public/icons/chevron_left_end_bold_16.svg";
import ChevronRightDisableIcon from "public/icons/chevron_right_disable_bold_16.svg";
import ChevronEndRightDisableIcon from "public/icons/chevron_right_end_disable_bold_16.svg";
import ChevronLeftDisableIcon from "public/icons/chevron_left_disable_bold_16.svg";
import ChevronEndLeftDisableIcon from "public/icons/chevron_left_end_disable_bold_16.svg";
import isNil from "lodash/isNil";

interface IPaginationProps {
  totalPages: number;
  activePage: number;
  onChange: (page: number) => void;
  className?: string;
  buttonClassName?: string;
  maxButton?: boolean;
  alt?: boolean;
}

const PaginationButton: React.FC<{
  onClick: () => void;
  page?: number;
  active: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  alt?: boolean;
}> = ({ onClick, page, icon, active, className, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "w-10 h-10 group !rounded-md text-sm font-medium text-typography-headline leading-[17.5px] flex items-center justify-center shadow hover:shadow-hover",
        className,
        active && "!bg-gray-30"
      )}
    >
      {icon ? icon : !isNil(page) && page + 1}
    </button>
  );
};

const Pagination: React.FC<IPaginationProps> = ({
  totalPages,
  activePage,
  onChange,
  className,
  buttonClassName,
  maxButton = false,
  alt,
}) => {
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(10);
  const pages = useMemo(() => {
    const pages = [];
    for (let i = 0; i < totalPages; i++) {
      pages.push(i);
    }

    return pages;
  }, [totalPages]);

  const onLeft = () => {
    onChange(activePage - 1);
  };

  const onRight = () => {
    onChange(activePage + 1);
  };

  const onLeftEnd = () => {
    onChange(0);
  };

  const onRightEnd = () => {
    onChange(totalPages - 1);
  };

  const onPageClick = (page: number) => {
    onChange(page);
  };

  useEffect(() => {
    if (endPage === activePage + 1 && activePage !== totalPages - 1) {
      setStartPage(startPage + 1);
      setEndPage(endPage + 1);
    } else if (startPage === activePage && activePage !== 0) {
      setStartPage(startPage - 1);
      setEndPage(endPage - 2);
    } else if (activePage === 0) {
      setStartPage(0);
      setEndPage(10);
    } else if (activePage === totalPages - 1) {
      setStartPage(totalPages < 10 ? 0 : totalPages - 10);
      setEndPage(totalPages + 1);
    } else if (activePage < startPage || activePage > endPage) {
      setStartPage(activePage - 1);
      setEndPage(activePage + 10);
    }
  }, [activePage]);

  return (
    <div className={cn("flex items-center", className)}>
      {maxButton && (
        <PaginationButton
          active={false}
          alt={alt}
          onClick={onLeftEnd}
          disabled={activePage === 0}
          className={buttonClassName}
          icon={
            <Icon
              viewBox="0 0 16 16"
              className="fill-title"
              component={
                activePage === 0
                  ? ChevronEndLeftDisableIcon
                  : ChevronEndLeftIcon
              }
            />
          }
        />
      )}
      <PaginationButton
        active={false}
        alt={alt}
        onClick={onLeft}
        disabled={activePage === 0}
        className={buttonClassName}
        icon={
          <Icon
            viewBox="0 0 16 16"
            className="fill-title"
            component={
              activePage === 0 ? ChevronLeftDisableIcon : ChevronLeftIcon
            }
          />
        }
      />
      {startPage !== 0 && totalPages > 10 && <span>...</span>}
      {pages.slice(startPage, endPage).map((page, index) => (
        <PaginationButton
          alt={alt}
          key={page}
          onClick={() => onPageClick(page)}
          active={activePage === page}
          page={page}
          className={buttonClassName}
        />
      ))}
      {endPage < totalPages && totalPages > 10 && <span>...</span>}
      <PaginationButton
        alt={alt}
        active={false}
        onClick={onRight}
        disabled={activePage === pages.length - 1}
        className={cn("mr-0", buttonClassName)}
        icon={
          <Icon
            component={
              activePage === pages.length - 1
                ? ChevronRightDisableIcon
                : ChevronRightIcon
            }
            viewBox="0 0 16 16"
            className="ml-1 fill-title"
          />
        }
      />

      {maxButton && (
        <PaginationButton
          alt={alt}
          active={false}
          onClick={onRightEnd}
          disabled={activePage === pages.length - 1}
          className={cn("mr-0", buttonClassName)}
          icon={
            <Icon
              component={
                activePage === pages.length - 1
                  ? ChevronEndRightDisableIcon
                  : ChevronEndRightIcon
              }
              viewBox="0 0 16 16"
              className="ml-1 fill-title"
            />
          }
        />
      )}
    </div>
  );
};

export default Pagination;
