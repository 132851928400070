import { useUser } from "contexts/UserContext";
import { INotificationType } from "hooks/useNotifications";
import { formatTimestamp } from "utils/dates";
import { getUserNameFromEmail } from "utils/getUserNameFromEmail";
import InfoCircleIcon from "public/icons/info_circle.svg";
import Avatar from "./Avatar";
import Icon from "./Icon";
import useTranslation from "next-translate/useTranslation";

interface NotificationMessageProps {
  notification: INotificationType;
  handleClickNotification: (value: INotificationType) => void;
}

const NotificationMessage: React.FC<NotificationMessageProps> = ({
  notification,
  handleClickNotification,
}) => {
  const { t } = useTranslation("common");
  const { user } = useUser();

  return (
    <div
      className="p-2 flex items-start justify-start gap-4 cursor-pointer rounded-xl hover:bg-gray-20"
      onClick={() => handleClickNotification(notification)}
    >
      <div className="flex items-center gap-1 flex-none">
        <div className="w-[5px] h-full">
          {!notification.isRead && (
            <div className="h-[5px] w-[5px] rounded-full bg-red-50" />
          )}
        </div>
        {notification.thumbnailData?.imageUrl ? (
          <Avatar
            image={notification.thumbnailData?.imageUrl}
            fullName={notification.thumbnailData?.alternativeText}
            className="!w-8 !h-8"
          />
        ) : (
          <SystemInfoIcon />
        )}
      </div>
      <div className="flex flex-col w-full">
        <div className="text-xs text-gray-950 leading-normal">
          <div
            dangerouslySetInnerHTML={{
              __html: (user?.id ===
              notification?.dataReferences?.actor?.entityId
                ? notification.content?.replace(
                    user?.name ||
                      user?.displayName ||
                      getUserNameFromEmail(user?.email) ||
                      "",
                    "You"
                  )
                : notification.content
              )
                .replace("rejected proposal to", t("rejected proposal to"))
                .replace("added proposal to", t("added proposal to"))
                .replace("returned proposal to", t("returned proposal to"))
                .replace("accepted proposal to", t("accepted proposal to"))
                .replace("revoke proposal to", t("revoke proposal to"))
                .replace("started production of", t("started production of"))
                .replace("Order", t("Order")),
            }}
          />
        </div>
        <p className="text-typography-tab-subtle text-xs leading-normal">
          {formatTimestamp(notification.updatedat)}
        </p>
      </div>
    </div>
  );
};

export default NotificationMessage;

export const SystemInfoIcon = () => {
  return (
    <div className="p-2 rounded-full bg-gray-60">
      <Icon
        component={InfoCircleIcon}
        viewBox="0 0 20 20"
        className="h-4 w-4 stroke-white"
      />
    </div>
  );
};
