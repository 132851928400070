import React from "react";
import cn from "classnames";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { OneSideRange } from "components/atoms/Range";
import { Direction } from "react-range";
import { theme } from "tailwind.config";

interface RangeBarProps {
  value: number;
  setValue: (v: number) => void;
  min?: number;
  max?: number;
  leftTitle?: string;
  rightTitle?: string;
  className?: string;
  width?: number | string;
  arrow?: "left" | "right";
  disabled?: boolean;
}

const RangeBar = ({
  value,
  setValue,
  min = 0,
  max = 100,
  leftTitle,
  rightTitle,
  className,
  width,
  arrow = "right",
  disabled = false,
}: RangeBarProps) => {
  return (
    <div className={cn("w-full", className)} style={{ width }}>
      <div className="relative w-full">
        <ParentSize>
          {({ width }) => (
            <div style={{ width }} className="h-3 pt-[5px]">
              <OneSideRange
                disabled={disabled}
                trackStyle={{
                  marginTop: 0,
                }}
                hideValues
                max={max}
                min={min}
                trackColors={[
                  theme.extend.colors.gray[50],
                  theme.extend.colors.gray[50],
                ]}
                hoverTrackColors={[
                  theme.extend.colors.gray[40],
                  theme.extend.colors.gray[40],
                ]}
                thumbColor="!bg-gray-950 !ring-0 !ring-offset-0 !w-3 !h-3"
                barStyle={{
                  height: "3px",
                  borderRadius: "6px",
                }}
                direction={arrow === "right" ? Direction.Right : Direction.Left}
                onChange={setValue}
                value={value}
              />
            </div>
          )}
        </ParentSize>
        <div
          className={cn(
            "absolute h-[3px] top-[5.5px] rounded-md",
            disabled ? "bg-gray-200" : "bg-gray-950"
          )}
          style={{
            width: `${((value - min) / (max - min)) * 100}%`,
          }}
        />
      </div>
      {leftTitle && rightTitle && (
        <div className="w-full flex justify-between mt-2.5">
          <p className="text-sm leading normal font-normal text-body">
            {leftTitle}
          </p>
          <p className="text-sm leading normal font-normal text-body">
            {rightTitle}
          </p>
        </div>
      )}
    </div>
  );
};

export default RangeBar;
