import ShopifyIntegrationIcon from "public/icons/shopify_integration_icon.svg";
import Icon from "../Icon";
import useTranslation from "next-translate/useTranslation";
import { useTeam } from "contexts/TeamContext";
import CloseIcon from "public/icons/close_16.svg";
import { useEffect, useState } from "react";
import { useLocalStorage } from "hooks/useLocalStorage";
import { isNil } from "lodash";
import CtaButton from "../CtaButton";

const SyncShopifyConnect: React.FC = () => {
  const { t } = useTranslation("common");
  const { push } = useTeam();
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const { getItemFromLocalStorage, setItemToLocalStorage } = useLocalStorage();

  useEffect(() => {
    const isClosed = getItemFromLocalStorage(
      "async-shopify-integration-banner-closed"
    );
    setIsVisible(isNil(isClosed) ? true : !isClosed);
  }, [setIsVisible]);

  const handleClose = () => {
    setIsVisible(false);
    setItemToLocalStorage("async-shopify-integration-banner-closed", true);
  };

  return isVisible ? (
    <div className="xs:pl-10 xxs:pl-4 xxs:pr-4 xs:pr-14 py-6 bg-gray-20 rounded-2xl">
      <div className="flex xxs:flex-col xs:flex-row items-center justify-between relative">
        <div className="flex xs:flex-row xxs:flex-col xxs:items-start xs:items-center justify-start gap-4">
          <Icon
            component={ShopifyIntegrationIcon}
            viewBox="0 0 100 52"
            width={72}
            height={37}
            className="shrink-0"
          />
          <div className="flex flex-col items-start justify-between gap-2">
            <div className="text-sm leading-tight font-bold text-typography-headline">
              {t("sync_with_shopify")}
            </div>
            <div className="text-sm leading-tight text-typography-body-subtle">
              {t("sync_with_shopify_description")}
            </div>
          </div>
        </div>
        <CtaButton
          size="sm"
          className="xxs:mt-4 xs:mt-0 xxs:w-full xs:w-auto"
          onClick={() => push("/integrations")}
        >
          {t("connect_to_shopify")}
        </CtaButton>
        <div className="absolute xxs:right-0 xs:-right-12 -top-4 ">
          <button className="p-2" onClick={handleClose}>
            <Icon
              component={CloseIcon}
              viewBox="0 0 16 16"
              width={16}
              height={16}
              className="fill-gray-950"
            />
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default SyncShopifyConnect;
