import React, { useMemo } from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import BigSpinner from "components/atoms/BigSpinner";
import { useTeam } from "contexts/TeamContext";
import Icon from "../Icon";
import FillHeartIcon from "public/icons/filled-heart.svg";
import { TVGarment } from "models/TvGarment";
import { getGarmentImage } from "utils/getGarmentImage";
import { checkRole } from "utils/checkRole";
import { TeamRoleEnum } from "types";
import CommentIcon from "public/icons/comment.svg";
import Tooltip from "../Tooltip";
import { isDev } from "utils/checkEnv";
import DownloadIcon from "public/icons/download_16.svg";
import { useState } from "react";
import CubeIcon from "public/icons/mask_16.svg";
import BatteryIcon from "public/icons/battery.svg";
import HeartIcon from "public/icons/heart.svg";
import AIStreammingIcon from "public/icons/ai_generate_16.svg";
import ChartIcon from "public/icons/chart_16.svg";
import SmallSpinner from "../SmallSpinner";
import ImageResponsive from "../ImageResponsive";

const tooltipClass = "!w-fit !p-2";

type GarmentAction = (garment: TVGarment) => void;

export interface IProductCardProps {
  garment: TVGarment;
  detailedGarment?: TVGarment;
  previewGarment?: TVGarment | null;
  setPreviewGarment?: GarmentAction;
  inMoodBoard: boolean;
  onBenchmarkView?: (garment?: TVGarment) => void;
  onAddToMoodBoard?: GarmentAction;
  onRemoveFromMoodBoard?: GarmentAction;
  handleSetRevisionGarment?: GarmentAction;
  onCommentClick?: GarmentAction;
  onCreateOrder?: GarmentAction;
  disableVariation?: boolean;
  className?: string;
  onDownload?: GarmentAction;
  isVariating?: boolean;
  isSharedPreview?: boolean;
}

const ProductCard: React.FC<IProductCardProps> = ({
  garment,
  detailedGarment,
  setPreviewGarment,
  inMoodBoard,
  onBenchmarkView,
  onCommentClick,
  onCreateOrder,
  onAddToMoodBoard,
  onRemoveFromMoodBoard,
  handleSetRevisionGarment,
  onDownload,
  disableVariation = false,
  className,
  isVariating,
  isSharedPreview = false,
}) => {
  const { currentTeam, isMaintainer } = useTeam();
  const { t } = useTranslation("common");
  const [showMaskingArea, setShowMaskingArea] = useState<boolean>(false);

  const onShowMaskingClick = (e: any) => {
    e.stopPropagation();
    setShowMaskingArea((s) => !s);
  };

  const allowedUser = useMemo(
    () => checkRole(currentTeam?.teamRole, [TeamRoleEnum.editor]),
    [currentTeam?.teamRole]
  );

  return (
    <div
      className={cn(
        "relative aspect-square w-full flex flex-row bg-white border rounded-xl",
        !!setPreviewGarment && "cursor-pointer",
        className
      )}
      onClick={() =>
        garment.image?.status === 2 &&
        setPreviewGarment &&
        setPreviewGarment(garment)
      }
    >
      {(garment.image?.status === 0 || garment.image?.status === 1) && (
        <div className="flex flex-col items-center justify-center w-full">
          <BigSpinner className="w-full h-auto" />
        </div>
      )}
      {garment.image?.status === 3 && (
        <p
          className={cn(
            "body-100-subtle flex items-center justify-center text-center",
            isDev() && "text-red-600"
          )}
        >
          {isDev() ? garment.image?.statusReason : t("ai_something_went_wrong")}
        </p>
      )}
      {garment.image?.status === 2 && (
        <div className="w-full h-full group relative">
          <div
            className={cn(
              "p-4 w-full h-full realtive content-border border border-white rounded-xl",
              detailedGarment?.id === garment.id && "border-2 !border-black"
            )}
          >
            <ImageResponsive
              url={getGarmentImage(garment)}
              className="w-auto h-full relative mx-auto"
            />
            {showMaskingArea && (
              <div className="p-4 w-full h-full absolute top-0 left-0">
                <ImageResponsive
                  url={garment?.image?.maskUrl as string}
                  className="w-auto h-full mx-auto"
                />
              </div>
            )}
          </div>
          <div className="group-hover:block absolute hidden bottom-5 left-1/2 -translate-x-1/2 z-10 hover:group-active:!block">
            <div className="flex">
              {!disableVariation && allowedUser && (
                <Tooltip
                  content={t("generate_variations")}
                  className={tooltipClass}
                >
                  <button
                    className="flex ml-1 p-2 hover:bg-gray-30 bg-white bg-opacity-70 rounded-md active:bg-opacity-20"
                    onClick={(e) => {
                      e.stopPropagation();
                      !isVariating &&
                        handleSetRevisionGarment &&
                        handleSetRevisionGarment(garment as TVGarment);
                    }}
                  >
                    {isVariating ? (
                      <SmallSpinner className="w-[18px] h-[18px]" />
                    ) : (
                      <Icon
                        component={AIStreammingIcon}
                        viewBox="0 0 16 16"
                        width={18}
                        height={18}
                        className="fill-black"
                      />
                    )}
                  </button>
                </Tooltip>
              )}
              {allowedUser && onBenchmarkView && (
                <Tooltip content={t("benchmarks")} className={tooltipClass}>
                  <button
                    className="flex ml-1 p-2 hover:bg-gray-30 bg-white bg-opacity-70 rounded-md active:bg-opacity-20"
                    onClick={(e) => {
                      e.stopPropagation();
                      onBenchmarkView();
                    }}
                  >
                    <Icon
                      component={ChartIcon}
                      viewBox="0 0 16 16"
                      width={18}
                      height={18}
                      className="fill-black"
                    />
                  </button>
                </Tooltip>
              )}
              {onCreateOrder && !isMaintainer && (
                <Tooltip
                  content={t("create_order_with_this_image")}
                  className={tooltipClass}
                >
                  <button
                    className="flex ml-1 p-2 hover:bg-gray-30 bg-white bg-opacity-70 rounded-md active:bg-opacity-20"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onCreateOrder(garment);
                    }}
                  >
                    <Icon
                      component={BatteryIcon}
                      width={18}
                      height={18}
                      viewBox="0 0 16 16"
                      className="fill-black"
                    />
                  </button>
                </Tooltip>
              )}
              {allowedUser && onRemoveFromMoodBoard && (
                <Tooltip
                  content={
                    inMoodBoard
                      ? t("remove_from_moodboard_tip")
                      : t("add_to_moodBoard_tip")
                  }
                  className={tooltipClass}
                >
                  <button
                    className="flex ml-1 p-2 hover:bg-gray-30 bg-white bg-opacity-70 rounded-md active:bg-opacity-20"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      if (inMoodBoard) {
                        onRemoveFromMoodBoard(garment);
                      } else {
                        onAddToMoodBoard && onAddToMoodBoard(garment);
                      }
                    }}
                  >
                    <Icon
                      component={inMoodBoard ? FillHeartIcon : HeartIcon}
                      viewBox={inMoodBoard ? "0 0 18 18" : "0 0 22 22"}
                      width={18}
                      height={18}
                      className={inMoodBoard ? "fill-black" : "stroke-black"}
                    />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
          {onDownload && (
            <button
              onClick={() => onDownload(garment)}
              className={cn(
                "absolute bottom-2 hover:animate-none hidden group-hover:block left-2 p-2 hover:bg-gray-30 rounded-md"
              )}
            >
              <Icon
                width={18}
                height={18}
                component={DownloadIcon}
                className="fill-black"
                viewBox="0 0 16 16"
              />
            </button>
          )}
          {!!garment?.image?.maskUrl && !inMoodBoard && (
            <Tooltip
              content={
                showMaskingArea
                  ? t("hide_masking_area")
                  : t("show_masking_area")
              }
            >
              <button
                onClick={onShowMaskingClick}
                className={cn(
                  "absolute bottom-2 right-2 p-2 hidden group-hover:block hover:bg-gray-30 rounded-md",
                  garment?.comments?.length && "right-4"
                )}
              >
                <Icon
                  width={18}
                  height={18}
                  component={CubeIcon}
                  className="fill-black"
                  viewBox="0 0 16 16"
                />
              </button>
            </Tooltip>
          )}
          {!!garment?.comments?.length && onCommentClick && (
            <button
              onClick={() => onCommentClick(garment)}
              className={cn(
                "absolute bottom-2 hover:animate-none right-2 p-2 hover:bg-gray-30 rounded-md",
                garment.comments.some((el) => !el.isViewed) && "animate-bounce"
              )}
            >
              <Icon
                width={18}
                height={18}
                component={CommentIcon}
                className="fill-black"
                viewBox="0 0 512 512"
              />
            </button>
          )}
        </div>
      )}
      {garment?.productOrderFiles?.length > 0 && !isSharedPreview && (
        <div className="absolute bg-gray-40 px-2 py-0.5 rounded-[50px] text-xxs text-heading top-4 right-4">
          {t("used_for_order")}
        </div>
      )}
    </div>
  );
};

export default ProductCard;
