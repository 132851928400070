import React, { Fragment, useRef, useState } from "react";
import cn from "classnames";
import Icon from "components/atoms/Icon";
import useTranslation from "next-translate/useTranslation";
import { Listbox, Transition } from "@headlessui/react";
import ChevronDown from "public/icons/chevron_down_16.svg";
import CheckIcon from "public/icons/check_16.svg";
import {
  ActionSelectOptionsType,
  ISelectValue,
  SelectOptionsType,
} from "types";
import { capitalize } from "lodash";
import { useDidUpdateEffect } from "hooks/useDidUpdateEffect";
import { Colors } from "utils/constants";

type ActionOptionType = {
  name: string;
  onClick: Function;
};

export interface TrendSelectProps {
  labelIcon?: any;
  label?: any;
  options: SelectOptionsType;
  actionOptions?: ActionSelectOptionsType;
  selectedItem?: ISelectValue;
  placeholder?: string;
  onSelect: Function;
  className?: string;
  buttonClassName?: string;
  optionsContainerClassName?: string;
  optionClassName?: string;
  textClassName?: string;
  disabled?: boolean;
  checkIcon?: boolean;
  noCapitalize?: boolean;
  color?: boolean;
}

const TrendSelect = ({
  label,
  labelIcon,
  options,
  actionOptions = [],
  placeholder = "Select",
  selectedItem,
  onSelect,
  className,
  buttonClassName,
  disabled = false,
  checkIcon = false,
  optionsContainerClassName,
  optionClassName,
  textClassName,
  noCapitalize,
  color,
  ...rest
}: TrendSelectProps) => {
  const { t } = useTranslation("common");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [wasHighlighted, setWasHighlighted] = useState<boolean>(false);

  const handleValueSelect = async (selected: any) => {
    onSelect(selected);
  };

  // handle preselected highlight
  useDidUpdateEffect(() => {
    if (selectedItem?.preselected) {
      buttonRef.current?.classList?.remove("bg-transparent");
      buttonRef.current?.classList?.add("bg-green-200");

      setTimeout(() => {
        buttonRef.current?.classList?.remove("bg-green-200");
        buttonRef.current?.classList?.add("bg-transparent");
      }, 3000);
    }
  }, [selectedItem]);

  return (
    <div className={`flex flex-col relative ${className}`}>
      <Listbox
        disabled={disabled}
        value={(selectedItem && selectedItem.name) || ""}
        onChange={handleValueSelect}
      >
        <div className="relative w-full">
          <Listbox.Button
            ref={buttonRef}
            as="button"
            className={cn(
              "bg-transparent duration-1000 transition-[background] ease-out p-4 pr-2 py-[9px] focus:py-2 flex items-center justify-between border borer-block group text-sm text-body rounded-md",
              "disabled:bg-gray-100 disabled:border-border-input-disable disabled:text-gray-400",
              "hover:border-gray-40 hover:bg-gray-10",
              "focus:border-border-input-focus focus-visible:!outline-border-input-focus focus:border-2",
              buttonClassName
            )}
          >
            <div className="flex items-center max-w-[90%]">
              <span
                className={cn(
                  "flex items-center justify-start gap-2 whitespace-nowrap mr-2 text-sm",
                  !selectedItem?.name && "text-gray-600",
                  textClassName
                )}
              >
                {selectedItem?.icon
                  ? selectedItem?.icon
                  : labelIcon && labelIcon}
                {selectedItem?.name
                  ? noCapitalize
                    ? t(selectedItem?.name)
                    : capitalize(t(selectedItem?.name))
                  : label
                  ? label
                  : capitalize(t(placeholder))}
              </span>
            </div>
            <Icon
              component={ChevronDown}
              width="12"
              height="12"
              viewBox="0 0 16 16"
              className={cn("fill-gray-70 group-hover:fill-gray-950")}
            />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={cn(
                "absolute z-[9999] mt-1 p-2 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-13 min-w-full",
                optionsContainerClassName
              )}
            >
              {options?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={cn(
                    "flex items-center gap-2 cursor-pointer select-none relative py-[9px] px-2 text-typography-body-normal hover:bg-gray-20 hover:text-typography-title rounded-md leading-normal text-sm",
                    item.value === selectedItem?.value
                      ? "text-typography-title bg-gray-30"
                      : "text-typography-body-normal",
                    optionClassName
                  )}
                  value={item}
                >
                  {checkIcon && item.value === selectedItem?.value && (
                    <span>
                      <Icon component={CheckIcon} viewBox="0 0 16 16" />
                    </span>
                  )}
                  {item?.icon && item.icon}
                  {color && (
                    <div
                      style={{
                        backgroundColor: Colors[item.value],
                        background:
                          item.value === "silver"
                            ? Colors.silver
                            : item.value === "multicolor"
                            ? Colors.multicolor
                            : Colors[item.value],
                      }}
                      className={cn(
                        "w-4 h-4 border border-border-normal rounded-full shrink-0",
                        !item.value && "border-0"
                      )}
                    />
                  )}
                  <span
                    className={cn(
                      "block truncate",
                      item.value !== selectedItem?.value && checkIcon && "pl-6"
                    )}
                  >
                    {noCapitalize ? t(item.name) : capitalize(t(item.name))}
                  </span>
                </Listbox.Option>
              ))}
              {actionOptions.length > 0 && options.length !== 0 && (
                <hr className="border-t-1 my-2 border-custom-color-1" />
              )}
              {actionOptions?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className="flex items-center cursor-pointer select-none relative py-[9px] px-2 text-typography-body-normal hover:bg-gray-20 hover:text-typography-title rounded-md leading-normal text-sm"
                  value={item}
                  onClick={() => item?.onClick && item.onClick()}
                >
                  <span className={cn("block truncate")}>
                    {noCapitalize ? t(item.name) : capitalize(t(item.name))}
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default TrendSelect;
