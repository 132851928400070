import cn from "classnames";
import { Menu } from "@headlessui/react";
import CustomButton from "components/atoms/CustomButton";
import QuestionIcon from "public/icons/question_circle_16.svg";
import useTranslation from "next-translate/useTranslation";
import ChangelogModal from "../ChangelogModal";
import { useState } from "react";
import Link from "next/link";
import { SUPPORT_EMAIL } from "utils/constants";
import Tooltip from "components/atoms/Tooltip";

interface SupportButtonProps {
  className?: string;
}

const SupportButton: React.FC<SupportButtonProps> = ({ className }) => {
  const { t } = useTranslation("common");

  const [isUpdatesModalOpened, setIsUpdatesModalOpened] =
    useState<boolean>(false);

  const handleOpenUpdatesModal = () => setIsUpdatesModalOpened(true);
  const handleCloseUpdatesModal = () => setIsUpdatesModalOpened(false);

  return (
    <>
      <div className="relative">
        <Menu>
          <Menu.Button className={cn("relative outline-none ring-0")}>
            <Tooltip
              content={t("support")}
              arrowHidden
              className="!bg-gray-950 !rounded-[4px]"
            >
              <CustomButton
                variant="tertiary"
                icon={QuestionIcon}
                viewBox="0 0 16 16"
                className="!p-2"
                iconClassName="!fill-none"
              />
            </Tooltip>
          </Menu.Button>
          <Menu.Items
            as={"div"}
            className="absolute bg-white top-full right-0 flex flex-col items-start p-2 w-[132px] border border-border-default rounded-xl z-[9999]"
          >
            <Menu.Item>
              <CustomButton
                label={t("updates")}
                variant="tertiary"
                className="!p-2 w-full !justify-start"
                buttonTextClassName="!font-normal"
                size="small"
                onClick={handleOpenUpdatesModal}
              />
            </Menu.Item>
            <Menu.Item>
              <button className="p-2 rounded-md hover:bg-gray-10 active:bg-gray-20 text-sm leading-tight text-typography-headline w-full text-left">
                <Link href={`mailto:${SUPPORT_EMAIL}`}>{t("contact_us")}</Link>
              </button>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
      <ChangelogModal
        isOpen={isUpdatesModalOpened}
        onClose={handleCloseUpdatesModal}
        shouldCloseOnOverlayClick
      />
    </>
  );
};

export default SupportButton;
