export const getFileNameFromUrl = (url?: string) => {
  if (!url) return "";

  const splitted = url.split("/");

  if (splitted.length) {
    return splitted[splitted.length - 1];
  }

  return "";
};
