import React from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import Icon from "components/atoms/Icon";
import Folder from "public/icons/no_search_result.svg";

type INoSearchResult = {
  className?: string;
  title?: string;
  titleClassName?: string;
  description?: string;
  descriptionClassName?: string;
  isIcon?: boolean;
};

const NoSearchResult = ({
  className,
  title,
  titleClassName,
  description,
  descriptionClassName,
  isIcon = false,
}: INoSearchResult) => {
  const { t } = useTranslation("common");

  return (
    <div className={cn("text-center w-full py-10", className)}>
      {!isIcon && (
        <div className="mt-[5px]">
          <Icon
            component={Folder}
            viewBox="0 0 60 61"
            width={60}
            height={60}
            className="mx-auto"
          />
        </div>
      )}
      <div className={cn("mt-[16.27px]", isIcon && "!mt-0")}>
        <h2
          className={cn(
            "text-2xl font-bold leading[30px] text-title",
            titleClassName
          )}
        >
          {title}
        </h2>
      </div>
      <p
        className={cn(
          "text-sm font-normal text-body mt-[12px]",
          descriptionClassName
        )}
      >
        {description}
      </p>
    </div>
  );
};

export default NoSearchResult;
