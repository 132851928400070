import React, { useRef, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Listbox } from "@headlessui/react";
import cn from "classnames";
import TooltipButton from "../TooltipButton";
import Icon from "../Icon";

export interface FolderMoreButtonProps {
  icon?: any;
  label?: string;
  options: {
    icon?: any;
    iconProps?: any;
    title?: string;
    divider?: boolean;
    onClick?: Function;
  }[];
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
  listClassName?: string;
  disabled?: boolean;
  disabledTooltip?: boolean;
}

const FolderMoreButton = ({
  icon,
  label,
  options,
  className,
  buttonClassName,
  iconClassName,
  listClassName,
  disabled,
  disabledTooltip,
  ...rest
}: FolderMoreButtonProps) => {
  const { t } = useTranslation("common");
  const [showList, setShowList] = useState<boolean>(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [position, setPosition] = useState<string>("bottom-right");

  const calculatePosition = () => {
    if (triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (viewportHeight - triggerRect.y < 258) {
        if (viewportWidth - triggerRect.x > 184) {
          setPosition("top-right");
        } else {
          setPosition("top-left");
        }
      } else {
        if (viewportWidth - triggerRect.x > 184) {
          setPosition("bottom-right");
        } else {
          setPosition("bottom-left");
        }
      }
    }
  };

  const handleInputFocus = () => {
    setShowList(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setShowList(false), 100);
  };

  return (
    <div className={cn("relative", className)}>
      <Listbox disabled={disabled}>
        <Listbox.Button ref={triggerRef}>
          <TooltipButton
            icon={
              <Icon
                component={icon}
                viewBox="0 0 18 18"
                width={18}
                height={18}
                className={cn(
                  disabled ? "fill-indigo-20" : "fill-cta-600",
                  iconClassName
                )}
              />
            }
            buttonName={label}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onClick={calculatePosition}
            content={disabledTooltip ? "" : t("More")}
            className={cn("border border-gray-40", buttonClassName)}
            tooltipClassName="top-[-30px] right-[50%] translate-x-[50%]"
            disabled={disabled}
          />
        </Listbox.Button>
        <Listbox.Options
          className={cn(
            "absolute z-10 bg-white border rounded-xl py-2 w-fit outline-none",
            position === "bottom-right" && "top-10 left-0",
            position === "bottom-left" && "top-10 right-0",
            position === "top-right" && "left-10 bottom-0",
            position === "top-left" && "right-10 bottom-0",
            listClassName
          )}
        >
          {options.map((option, index) =>
            option?.divider ? (
              <div
                key={index}
                className="w-full border-t border-border-normal my-2"
              />
            ) : (
              <Listbox.Option
                key={option.title}
                value={option.title}
                className="group flex rounded-md py-[5.5px] px-2 mx-2 text-gray-80 hover:bg-gray-20 cursor-pointer hover:!text-gray-950"
                onClick={() => option.onClick && option.onClick()}
              >
                <div className="flex items-center justify-center pr-2">
                  {option.icon && (
                    <Icon
                      component={option.icon}
                      viewBox="0 0 16 16"
                      width={16}
                      height={16}
                      className="group-hover:!fill-gray-950 fill-black"
                      {...(option.iconProps || {})}
                    />
                  )}
                </div>
                <span className="hover:text-gray-950 text-gray-80 text-sm leading-normal font-normal whitespace-nowrap">
                  {option.title}
                </span>
              </Listbox.Option>
            )
          )}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default FolderMoreButton;
