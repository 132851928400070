import {
  IGarmentPayload,
  ITvChannelPayload,
  ITvCreateRevisionPayload,
  ITvStatistics,
  ITvUpdateRevisionPayload,
  IUpscaleImagePayload,
} from "api/TvChannelsApi";
import { useApi } from "contexts/ApiContext";
import { TvChannel } from "models/TvChannel";
import { TVGarment } from "models/TvGarment";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import Stripe from "stripe";

// tv channels hooks
export const useGetTvChannels = (
  teamId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useQuery<TvChannel[]>(
    ["tvChannels", teamId, params],
    () => tvChannels.getTvChannels(params).then((res) => res?.data),
    config
  );
};

export const useGetTvChannelById = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useQuery(
    ["tvChannel", id],
    () => tvChannels.getTvChannelById(id),
    config
  );
};

export const useCreateTvChannel = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useMutation(
    (payload: ITvChannelPayload) => tvChannels.createTvChannel(payload),
    config
  );
};

export const useUpdateTvChannel = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useMutation(
    ({ id, payload }: { id: number; payload: any }) =>
      tvChannels.updateTvChannel(id, payload),
    config
  );
};

export const useDeleteTvChannel = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useMutation((id: number) => tvChannels.deleteTvChannel(id), config);
};

export const useChannelMoodboard = (
  channelId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useQuery(
    ["channelMoodboard", channelId],
    () => tvChannels.moodboard(channelId).then((res) => res?.data),
    config
  );
};

export const useChannelAddToMoodboard = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useMutation(
    (payload: { channelId: number; garmentId: number }) =>
      tvChannels.addToMoodboard(payload.channelId, payload.garmentId),
    config
  );
};

export const useChannelRemoveFromMoodboard = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useMutation(
    (payload: { channelId: number; garmentId: number }) =>
      tvChannels.removeFromMoodboard(payload.channelId, payload.garmentId),
    config
  );
};

export const useChannelRevisions = (
  channelId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useQuery(
    ["tvChannelRevisions", channelId],
    () => tvChannels.revisions(channelId),
    config
  );
};

export const useCreateGarment = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useMutation(
    (payload: IGarmentPayload) => tvChannels.createGarment(payload),
    config
  );
};

export const useGetInitialGarments = (
  params: { channelId: number; revisionGarmentId?: number },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useQuery(
    ["tvGarmentsPages", params],
    () => tvChannels.getGarments(params).then((res) => res),
    config
  );
};

export const useGetGarments = (
  params: { channelId: number; revisionGarmentId?: number },
  page: number,
  config?: UseInfiniteQueryOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();
  return useInfiniteQuery<TVGarment[]>(
    ["tvGarments", params],
    ({ pageParam = page }) => {
      return tvChannels.getGarments({ ...params, page: pageParam });
    },
    {
      ...config,
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.meta?.currentPage === lastPage?.meta?.totalPages) {
          return;
        }

        return lastPage?.meta?.currentPage + 1;
      },
      getPreviousPageParam: (firstPage: any) => {
        if (firstPage?.meta?.currentPage === 1) {
          return;
        }

        return firstPage?.meta?.currentPage - 1;
      },
      // refetchInterval(data: any) {
      //   const preparedData = last(data?.pages)?.data;

      //   if (checkIsAllImagesReady(preparedData)) {
      //     return false;
      //   }

      //   return 2000;
      // },
    }
  );
};

export const useUploadImage = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { tvChannels } = useApi();

  return useMutation(
    ({ payload }: { payload: FormData; params?: any }) =>
      tvChannels.uploadImage(payload),
    config
  );
};

export const useGetTvLimits = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<ITvStatistics>(
    ["tv-limits"],
    () => api.tvChannels.getTvLimits(),
    config
  );
};

export const useGetGarmentParents = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<TVGarment[]>(
    ["tv-garment-parents", id],
    () => api.tvChannels.getGarmentParents(id),
    config
  );
};

export const useUpdateGarment = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (data: { id: number; payload: Partial<TVGarment> }) =>
      api.tvChannels.updateGarment(data),
    config
  );
};

export const useCreateRevision = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ITvCreateRevisionPayload) =>
      api.tvChannels.createRevision(payload).then((res) => res?.data),
    config
  );
};

export const useUpdateRevision = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    ({ id, payload }: { id: number; payload: ITvUpdateRevisionPayload }) =>
      api.tvChannels.updateRevision(id, payload).then((res) => res?.data),
    config
  );
};

export const useGetRevisionParents = (
  revisionId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["revision-parents", revisionId],
    () => api.tvChannels.getRevisionParents(revisionId),
    config
  );
};

export const useGetGarment = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<TVGarment>(
    ["tv-garment", id],
    () => api.tvChannels.getGarment(id),
    config
  );
};

export const useUpscaleGarmentImage = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IUpscaleImagePayload) =>
      api.tvChannels.upscaleGarmentImage(payload),
    config
  );
};

export const useGetTvLimitsByUser = ({
  subscription,
  config,
}: {
  subscription?: Stripe.Subscription;
  config?: UseQueryOptions<any, any, any, any>;
}) => {
  const api = useApi();
  return useQuery<ITvStatistics>(
    ["tv-limits-user", subscription],
    () => api.tvChannels.getTvLimitsByUser(),
    config
  );
};

export const useInviteMembers = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignProject } = useApi();

  return useMutation(
    (payload: { teamId: number; projectId: number; inviteEmails: string[] }) =>
      aiDesignProject.InviteMember(payload.projectId, {
        teamId: payload.teamId,
        inviteEmails: payload.inviteEmails,
      }),
    config
  );
};
