import cn from "classnames";
import FilterLabelButton from "components/atoms/FilterLabelButton";

interface IFilterLabelButtonGroupProps {
  className?: string;
  filterLabels: {
    label: string;
    onClose: (() => void) | null;
  }[];
}

const FilterLabelButtonGroup: React.FC<IFilterLabelButtonGroupProps> = ({
  className,
  filterLabels,
}) => {
  return (
    <div
      className={cn(
        "w-full flex items-center justify-start gap-2 flex-wrap",
        className
      )}
    >
      {filterLabels.map((item, index) => (
        <FilterLabelButton
          key={index}
          label={item.label}
          onClose={item.onClose}
        />
      ))}
    </div>
  );
};

export default FilterLabelButtonGroup;
