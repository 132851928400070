import { useEffect, useState, useMemo } from "react";
import ReactModal from "react-modal";
import cn from "classnames";
import Close from "public/icons/close.svg";
import useTranslation from "next-translate/useTranslation";
import { useTeam } from "contexts/TeamContext";
import CustomButton from "components/atoms/CustomButton";
import { capitalize } from "lodash";
import TeamSearchInput from "components/atoms/MediaLibrary/TeamSearchInput";
import { useGetTeams } from "hooks/teams";
import { usePrepareInfiniteData } from "hooks/usePrepareInfiniteData";
import Team from "models/Team";
import { success } from "utils/toast";
import { AssetFolderType } from "api/AssetFoldersApi";
import {
  useShareAssetFolder,
  useUndoSharingAssetFolder,
} from "hooks/useAssetFolders";

interface FolderCopyModalProps {
  folder: AssetFolderType | null;
  isOpen: boolean;
  onClose: () => void;
  onCopy: () => void;
  refetchFolders: () => void;
}

const FolderCopyModal: React.FC<FolderCopyModalProps> = ({
  folder,
  isOpen,
  onCopy,
  onClose,
  refetchFolders,
}) => {
  const { teamId } = useTeam();
  const { t } = useTranslation("common");

  const [targetTeam, setTargetTeam] = useState<Team | null>(null);
  const [sharedTeams, setSharedTeams] = useState<Team[]>([]);
  const [deletedIds, setDeletedIds] = useState<number[]>([]);

  useEffect(() => {
    if (folder) {
      setSharedTeams(folder?.copies?.map((folder) => folder.team) || []);
    }
  }, [folder]);

  const { data: paginationTeams } = useGetTeams({ teamId });
  const teams = usePrepareInfiniteData<Team>(paginationTeams);

  const { mutate: shareAssetFolder, isLoading: isSharingAssetFolder } =
    useShareAssetFolder({
      onSuccess() {
        success({
          message: t("shared_teams_updated"),
        });
        refetchFolders();
        onCopy();
        onClose();
      },
    });

  const { mutate: undoAssetFolder, isLoading: isUndoingAssetFolder } =
    useUndoSharingAssetFolder({
      onSuccess() {
        success({
          message: t("shared_teams_updated"),
        });
        refetchFolders();
        onCopy();
        onClose();
      },
    });

  const handleRemoveAccess = (id: number) => {
    setSharedTeams((prevTeams) => prevTeams.filter((team) => team.id !== id));
    setDeletedIds([...deletedIds, id]);
  };

  const filteredTeams = useMemo(() => {
    const sharedTeamIds = sharedTeams.map((team) => team.id);
    return teams?.filter(
      (team) => !sharedTeamIds.includes(team.id) && team.id !== teamId
    );
  }, [sharedTeams, teams, teamId]);

  const handleShareSelectedTeam = (id: number) => {
    const selectedTeam = filteredTeams?.find(
      (team) => team.id === Number(targetTeam?.id)
    );
    if (selectedTeam) {
      setDeletedIds(deletedIds.filter((deletedId) => deletedId !== id));
      setSharedTeams((prevTeams) => [...prevTeams, selectedTeam]);
      setTargetTeam(null);
    }
  };

  const handleCopyAsset = () => {
    if (folder) {
      const payload = {
        id: folder.id,
        payload: {
          teamIds: sharedTeams.map((item) => item.id) || [],
        },
      };
      if (deletedIds.length > 0) {
        undoAssetFolder({
          id: folder.id,
          payload: {
            teamIds: deletedIds,
          },
        });
      } else {
        if (folder?.copies?.length === 0) {
          shareAssetFolder(payload);
        } else {
          shareAssetFolder(payload);
        }
      }
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`relative bg-white rounded-2xl w-[480px] max-h-[600px] border-custom-color-1 border`}
      overlayClassName="fixed bg-gray-400/80 h-full overflow-y-auto inset-0 z-[60] flex items-center lg:items-center justify-center"
    >
      {onClose && (
        <div className={"absolute top-4 right-4"}>
          <button
            onClick={onClose}
            className="flex items-center justify-center hover:scale-110"
          >
            <Close />
          </button>
        </div>
      )}
      <div className="p-8">
        <div className="text-2xl text-typography-headline leading-normal font-medium line-clamp-2">
          {t("share_with_other_team")}
        </div>
        <div className="flex items-center justify-between gap-2 mt-8">
          <div className="relative w-full">
            <TeamSearchInput
              teams={filteredTeams}
              value={targetTeam}
              onChange={setTargetTeam}
            />
          </div>
          <CustomButton
            label={t("Share")}
            className="flex-none"
            onClick={handleShareSelectedTeam}
          />
        </div>
        <div className="mt-6">
          <div className="text-sm leading-nromal font-bold text-typography-body-normal">
            {t("team_with_access")}
          </div>
          <div className="mt-2 overflow-y-scroll max-h-[280px]">
            {sharedTeams.length > 0 ? (
              sharedTeams?.map((item, index) => (
                <TeamItem
                  key={`${item.name}-${index}`}
                  name={item.name}
                  onRemoveAccess={() => handleRemoveAccess(item.id)}
                />
              ))
            ) : (
              <div className="w-full text-center text-xs">No shared teams</div>
            )}
          </div>
        </div>
        <div className="mt-10 flex items-center justify-end gap-2 ">
          <CustomButton
            isLoading={isSharingAssetFolder || isUndoingAssetFolder}
            label={capitalize(t("save"))}
            onClick={handleCopyAsset}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default FolderCopyModal;

const TeamItem = (props: { name: string; onRemoveAccess: () => void }) => (
  <div
    className={cn(
      "py-2 px-3 flex items-center justify-between rounded-md hover:bg-gray-20 cursor-pointer"
    )}
  >
    <div className="flex items-center justify-start gap-2 h-[28px]">
      <div className="text-sm text-typography-headline">{props.name}</div>
    </div>
    <button
      onClick={props.onRemoveAccess}
      className="flex items-center justify-center hover:scale-110"
    >
      <Close />
    </button>
  </div>
);
