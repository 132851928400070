import { ICreateRoomPayload } from "api/RoomsApi";
import { useApi } from "contexts/ApiContext";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { IFile } from "types";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";

export const useGetUsersForRoom = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["users-for-room", params],
    ({ pageParam = 1 }) =>
      api.rooms.getUsersForRoom({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useCreateRoom = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ICreateRoomPayload) => api.rooms.createRoom(payload),
    config
  );
};

export const useUpdateRoom = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (data: { id: number; payload: { title: string } }) =>
      api.rooms.updateRoom(data.id, data.payload),
    config
  );
};

export const useDeleteRoom = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.rooms.deleteRoom(id), config);
};

export const useLeaveRoom = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.rooms.leaveRoom(id), config);
};

export const useGetRooms = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["rooms", params],
    ({ pageParam = 1 }) =>
      api.rooms.getRooms({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetRoomById = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(["room", id], () => api.rooms.getRoomById(id), config);
};

export const useGetRoomUsers = (
  id: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["room-users", id, params],
    ({ pageParam = 1 }) =>
      api.rooms.getRoomUsers(id, { page: pageParam, ...params }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useUpdateRoomUsers = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { id: number; userIds: number[] }) =>
      api.rooms.updateRoomUsers(payload),
    config
  );
};

export const useCreateMessage = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: {
      id: number;
      text: string;
      files?: IFile[];
      productOrderId?: number;
      repliedToId?: number;
    }) => api.rooms.createMessage(payload),
    config
  );
};

export const useEditMessage = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: {
      roomId: number;
      messageId: number;
      text: string;
      files?: IFile[];
    }) => api.rooms.editMessage(payload),
    config
  );
};

export const useRemoveMessage = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { roomId: number; messageId: number }) =>
      api.rooms.removeMessage(payload),
    config
  );
};

export const useGetMessages = (
  roomId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["room-messages", roomId, params],
    ({ pageParam = 1 }) =>
      api.rooms.getMessages(roomId, { page: pageParam, ...params }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useAddRoomToFavorites = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.rooms.addRoomToFavorites(id), config);
};

export const useRemoveRoomFromFavorites = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (id: number) => api.rooms.removeRoomFromFavorites(id),
    config
  );
};

export const useViewMessages = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.rooms.viewMessages(id), config);
};

export const useGetMessagesViewStatus = (
  portalId: number,
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["messages-view-status", portalId, teamId],
    () => api.rooms.getMessagesViewStatus(),
    config
  );
};
