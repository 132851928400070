import CtaButton from "components/atoms/CtaButton";
import DropZone from "components/atoms/DropZone";
import { capitalize } from "lodash";
import useTranslation from "next-translate/useTranslation";
import React from "react";

interface IAddImageViewProps {
  onGenerate: () => void;
  generateDisabled?: boolean;
  uploadLayerImage: (data: FormData) => void;
  loading?: boolean;
}

const AddImageView: React.FC<IAddImageViewProps> = ({
  onGenerate,
  generateDisabled,
  uploadLayerImage,
  loading,
}) => {
  const { t } = useTranslation("common");

  const uploadFile = (files: File[]) => {
    const data = new FormData();
    data.append("file", files[0] as File);

    uploadLayerImage(data);
  };

  return (
    <div className="w-full">
      <DropZone
        accept={{
          "image/*": [],
        }}
        onChange={uploadFile}
        zoneComponent={(props) => (
          <CtaButton
            {...props}
            disabled={loading}
            className="w-full"
            variant="secondary"
            size="sm"
          >
            {t("upload_image")}
          </CtaButton>
        )}
      />
      <CtaButton
        disabled={generateDisabled}
        className="!mt-4 w-full"
        size="sm"
        onClick={onGenerate}
      >
        {capitalize(t("generate"))}
      </CtaButton>
    </div>
  );
};

export default AddImageView;
