import React, { RefObject, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import cn from "classnames";
import MainHeader, { IMainHeaderProps } from "components/organisms/MainHeader";
import {
  HEADER_HEIGHT,
  PORTAL_SELECTION_AND_NAV_WIDTH_EXPANDED,
  PORTAL_SELECTION_WIDTH,
} from "utils/constants";
import { useLocalStorage } from "hooks/useLocalStorage";
import isNil from "lodash/isNil";
import { useBreakpoint } from "contexts/BreakpointContext";

const Layout = ({
  children,
  className,
  headerProps,
  onSelectMenu,
  sidebarHidden,
  contentClassName,
  headerComponent: HeaderComponent,
  containerRef,
}: {
  children: any;
  className?: string;
  sidebarHidden?: boolean;
  contentClassName?: string;
  headerProps?: IMainHeaderProps;
  headerComponent?: React.ReactNode;
  onSelectMenu?: (menu: string) => void;
  containerRef?: RefObject<HTMLDivElement>;
}) => {
  const { setItemToLocalStorage, getItemFromLocalStorage } = useLocalStorage();
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(() => {
    const isExpanded = getItemFromLocalStorage("ui-sidebar-expanded");
    if (isNil(isExpanded)) {
      return true;
    }

    return isExpanded;
  });

  const { xxs } = useBreakpoint();

  useEffect(() => {
    setItemToLocalStorage("ui-sidebar-expanded", sidebarExpanded);
  }, [sidebarExpanded]);

  useEffect(() => {
    if (xxs) {
      setSidebarExpanded(false);
    }
  }, [xxs]);

  // changing layout css ENSURE it's not broken all over the app
  return (
    <main
      className={cn(
        "relative overflow-y-auto h-screen w-screen flex",
        className
      )}
    >
      <div className="flex flex-1 w-full overflow-hidden">
        {!sidebarHidden && (
          <aside className="relative z-50">
            <Sidebar
              disableExpand={xxs}
              sidebarExpanded={sidebarExpanded}
              onSelectMenu={onSelectMenu}
              setSidebarExpanded={setSidebarExpanded}
              showCheckoutActions={headerProps?.showCheckoutActions}
            />
          </aside>
        )}
        <div
          style={{
            width: sidebarHidden
              ? "100vw"
              : `calc(100vw - ${
                  sidebarExpanded
                    ? PORTAL_SELECTION_AND_NAV_WIDTH_EXPANDED
                    : PORTAL_SELECTION_WIDTH
                }px)`,
          }}
        >
          {HeaderComponent || (
            <MainHeader isExpanded={sidebarExpanded} {...headerProps} />
          )}
          <section
            ref={containerRef}
            id="layout"
            style={{ height: `calc(100dvh - ${HEADER_HEIGHT}px)` }}
            className="overflow-y-auto w-full"
          >
            <div
              className={cn(
                "w-full h-full mx-auto xxs:py-6 xxs:px-4 sm:!py-8 sm:!px-8",
                sidebarExpanded ? "max-w-[1230px]" : "max-w-[1360px]",
                contentClassName,
                sidebarHidden && "!pl-0"
              )}
            >
              {children}
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default Layout;
