import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import cn from "classnames";
import { AssetFolderType } from "api/AssetFoldersApi";
import DropdownText from "components/atoms/Benchmark/DropdownText";
import BigSpinner from "components/atoms/BigSpinner";
import Icon from "components/atoms/Icon";
import FolderButton from "components/atoms/MediaLibrary/FolderButton";
import { useTeam } from "contexts/TeamContext";
import { Translate } from "next-translate";
import useTranslation from "next-translate/useTranslation";
import MediaLibraryIcon from "public/icons/media_library_16.svg";
import { ISelectValue, TeamRoleEnum } from "types";
import { checkRole } from "utils/checkRole";
import AlphaSortAscIcon from "public/icons/alpha-asc-sort_16.svg";
import AlphaSortDescIcon from "public/icons/alpha-desc-sort_16.svg";
import CustomButton from "components/atoms/CustomButton";
import { isGuestUser } from "utils/isGuestUser";
import { isViewerUser } from "utils/isViewerUser";
import PlusIcon from "public/icons/plus_18.svg";
import { useUiStateStore } from "stores/UIStateStore";
import MadiaLibrarySearchFilter from "components/molecules/MediaLibrary/MadiaLibrarySearchFilter";
import DragableMediaAssetCard from "components/atoms/MediaLibrary/DragableMediaAssetCard";
import { usePortal } from "contexts/PortalContext";
import { getImageAvailabilityFilterOptions } from "pages/[portalName]/[teamId]/media-lib";
import { useCallback, useEffect, useState } from "react";
import CaretRightIcon from "public/icons/caret-right_16.svg";
import DropableLibraryButton from "components/atoms/MediaLibrary/DropableLibraryButton";
import AssetMoveModal from "components/molecules/MediaLibrary/AssetMoveModal";
import RenameFolderDialog from "./RenameFolderDialog";
import AssetCopyModal from "components/molecules/MediaLibrary/AssetCopyModal";
import { useCreateAsset } from "hooks/useAIDesignAssets";
import { success } from "utils/toast";
import { useInView } from "react-intersection-observer";
import { useGetTeams } from "hooks/teams";
import { usePrepareInfiniteData } from "hooks/usePrepareInfiniteData";
import Team from "models/Team";
import ConfirmDialog from "../ConfirmDialog";
import FolderCopyModal from "components/molecules/MediaLibrary/FolderCopyModal";

const getSortOptions = (t: Translate) => [
  { name: t("modified_date_desc"), value: "updatedat:DESC" },
  { name: t("modified_date_asc"), value: "updatedat:ASC" },
];

interface AssetsMediaLibProps {
  filter: any;
  assetFolders?: AssetFolderType[];
  folderAssets?: AIDesignAssetType[];
  filteredAssets: AIDesignAssetType[];

  isFolders?: boolean;
  isDefaultAssets?: boolean;
  isAssetsInFolder?: boolean;
  isLoadingAssetFolders: boolean;
  isLoadingFolderAssets: boolean;
  isDeletingAssetFolder: boolean;

  currentFolder: number | null;
  currentFolderName?: string;

  setCurrentFolder: Function;
  setIsFormOpened: Function;
  setEditCandidate: (asset: AIDesignAssetType) => void;

  editAssetFolder: Function;
  deleteAssetFolder: Function;

  onOpenImage: (image: string) => void;
  onMoveAsset: (assetId: number, folderId: number) => void;

  handleDeleteAsset: (id: number) => void;
  handleOpenBenchmark: (asset: AIDesignAssetType) => void;
  onCreateAiProject: (asset: AIDesignAssetType) => void;
  onSelectFilter: (field: string) => (val: ISelectValue) => void;
  selectedImageAvailabilityFilter?: ISelectValue;
  setImageAvailabilityFilter?: Function;
  sortKeyword: ISelectValue;
  setSortKeyword: Function;
  setIsNewFolderTxtDlg: Function;

  fullFolderPath?: { id: number; name: string }[];
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  refetchAssets?: () => void;
  refetchFolders?: () => void;
}

const AssetsMediaLib = ({
  onMoveAsset,
  assetFolders,
  onOpenImage,
  folderAssets,
  currentFolder,
  currentFolderName,
  editAssetFolder,
  deleteAssetFolder,
  filteredAssets,
  setIsFormOpened,
  isAssetsInFolder,
  isFolders,
  isDefaultAssets,
  setEditCandidate,
  handleDeleteAsset,
  handleOpenBenchmark,
  onCreateAiProject,
  filter,
  onSelectFilter,
  selectedImageAvailabilityFilter,
  setImageAvailabilityFilter,
  sortKeyword,
  setSortKeyword,
  setCurrentFolder,
  setIsNewFolderTxtDlg,
  isLoadingAssetFolders,
  isLoadingFolderAssets,
  isDeletingAssetFolder,
  hasNextPage,
  fullFolderPath,
  fetchNextPage = () => {},
  refetchAssets = () => {},
  refetchFolders = () => {},
}: AssetsMediaLibProps) => {
  const { t } = useTranslation("common");
  const {
    teamId,
    currentTeam,
    isMaintainer,
    push,
    teamMembers,
    refetchMembers,
  } = useTeam();

  const { data: paginationTeams } = useGetTeams({ teamId });

  const teams = usePrepareInfiniteData<Team>(paginationTeams);

  const { isPortal } = usePortal();
  const isAllowedToInteract = checkRole(currentTeam?.teamRole, [
    TeamRoleEnum.editor,
  ]);
  const isGuest = isGuestUser(currentTeam?.teamRole);
  const isViewer = isViewerUser(currentTeam?.teamRole);

  const [isOpenRenameFolderDlg, setIsOpenRenameFolderDlg] =
    useState<boolean>(false);
  const [isOpenAssetMoveModal, setIsOpenAssetMoveModal] =
    useState<boolean>(false);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState<boolean>(false);

  const [isOpenFolderCopyModal, setIsOpenFolderCopyModal] =
    useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<AIDesignAssetType | null>(
    null
  );
  const [selectedFolder, setSelectedFolder] = useState<AssetFolderType | null>(
    null
  );
  const [isOpenFolderDeletionConfirm, setIsOpenFolderDeletionConfirm] =
    useState<boolean>(false);
  const [selectedFolderId, setSelectedFolderId] = useState<number | null>(null);

  const { ref: listEndRef, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, inView]);

  const { rightCommentbarOpen, sidebarExpanded } = useUiStateStore((state) => ({
    rightCommentbarOpen: state.rightCommentbarOpen,
    sidebarExpanded: state.sidebarExpanded,
  }));

  const moveToFolder = useCallback(
    (folderId: number) => {
      push(`/media-lib/${folderId}`);
    },
    [push]
  );

  const imageAvailabilityFilterOptions = getImageAvailabilityFilterOptions(
    t,
    !!isMaintainer
  );

  const { mutate: createAsset, isLoading: isCreating } = useCreateAsset({
    onSuccess: (data) => {
      const targetTeam = teams?.find((item) => item.id === data.teamId);
      success({
        message: `Asset has been copied to ${
          targetTeam?.name || "other team"
        }!`,
      });
      handleCloseCopyAssetModal();
    },
  });

  const handleCloseRenameFolderDialog = () => {
    setIsOpenRenameFolderDlg(false);
    setSelectedFolder(null);
  };

  const handleCloseCopyAssetModal = () => {
    setIsOpenCopyModal(false);
    setSelectedFolder(null);
  };

  const handleCloseCopyFolderModal = () => {
    setIsOpenFolderCopyModal(false);
    setSelectedFolder(null);
  };

  const handleCopyAssetModal = () => {
    refetchAssets();
  };

  const handleCopyFolderModal = () => {
    refetchFolders();
  };

  const handleMoveFolderToFolder = (
    selectedFolder: AssetFolderType,
    targetFolder?: AssetFolderType
  ) => {
    editAssetFolder(
      {
        id: selectedFolder.id,
        parentFolderId: targetFolder?.id || null,
        teamId: teamId,
      },
      {
        onSuccess() {
          success({
            message: `The '${selectedFolder.name}' folder has been moved to '${targetFolder?.name}'.`,
          });
        },
      }
    );
  };

  return isLoadingAssetFolders ? (
    <div className="w-full h-[400px] flex items-center justify-center">
      <BigSpinner />
    </div>
  ) : (
    <>
      <div className="mb-6">
        {currentFolder && (
          <div className="flex items-center justify-start flex-wrap gap-2 mb-4 -ml-[9px]">
            <DropableLibraryButton />
            {fullFolderPath?.map((item, index) => (
              <>
                <Icon
                  component={CaretRightIcon}
                  viewBox="0 0 16 16"
                  width={16}
                  height={16}
                  className="fill-gray-70"
                />
                {index === fullFolderPath.length - 1 ? (
                  <div className="font-bold text-gray-950 text-lg leading-tight">
                    {item.name}
                  </div>
                ) : (
                  <DropableLibraryButton {...item} />
                )}
              </>
            ))}
          </div>
        )}
        <div className="mb-4 flex items-start justify-end">
          <div className="flex items-center justify-end flex-wrap gap-4">
            <div className="md:w-auto w-full">
              <MadiaLibrarySearchFilter
                onSelect={onSelectFilter}
                basecat={filter.basecat}
                gender={filter.gender}
                color={filter.color}
              />
            </div>
            <div className="flex items-center xxs:justify-start xs:justify-end md:w-auto w-full">
              {isPortal && (
                <DropdownText
                  onChange={setImageAvailabilityFilter}
                  label={imageAvailabilityFilterOptions[0].name}
                  labelClassName="!text-xs"
                  className="mr-4"
                  items={imageAvailabilityFilterOptions}
                  selectedItems={selectedImageAvailabilityFilter}
                />
              )}
              <div className="flex items-center justify-end">
                <DropdownText
                  labelIcon={
                    <Icon
                      component={
                        sortKeyword?.value === "updatedat:DESC"
                          ? AlphaSortAscIcon
                          : AlphaSortDescIcon
                      }
                      viewBox="0 0 16 16"
                      width={16}
                      height={16}
                    />
                  }
                  items={getSortOptions(t)}
                  selectedItems={{
                    name: t("modified_date"),
                    value: sortKeyword.value,
                  }}
                  onChange={setSortKeyword}
                  menuSubTitle={t("SORT BY")}
                  checkIcon={true}
                  menuClassName="w-max xs:right-0 xs:left-auto xxs:left-0"
                  labelClassName="!text-xs"
                  hideLabel
                />
              </div>
            </div>
            <CustomButton
              variant="secondary"
              icon={PlusIcon}
              disabled={isGuest || isViewer}
              onClick={() => setIsNewFolderTxtDlg(true)}
              className="!py-[7px] px-2.5"
              buttonTextClassName="capitalize"
              label={t("folder")}
            />
          </div>
        </div>
        {assetFolders && assetFolders?.length > 0 && (
          <div className="text-title mb-4 text-sm font-medium leading-tight capitalize">
            {t("folder")}
          </div>
        )}

        {assetFolders && assetFolders?.length > 0 && (
          <div className="grid xxs:grid-cols-1 xs:grid-cols-5 gap-4 pb-2">
            {assetFolders?.map((item: any) => (
              <FolderButton
                key={`${item.name}-${item.id}`}
                id={item.id}
                text={item.name}
                folder={item}
                active={item.id === currentFolder}
                isShared={item?.copyFromFolderId || item?.copies?.length > 0}
                onClick={() => {
                  moveToFolder(item.id);
                }}
                onShareFolder={
                  item.teamId === teamId
                    ? () => {
                        setSelectedFolder(item);
                        setIsOpenFolderCopyModal(true);
                      }
                    : null
                }
                onEditFolderName={() => {
                  setSelectedFolder(item);
                  setIsOpenRenameFolderDlg(true);
                }}
                onDeleteFolder={(id: number) => {
                  setSelectedFolderId(id);
                  setIsOpenFolderDeletionConfirm(true);
                }}
                onMoveFolder={handleMoveFolderToFolder}
              />
            ))}
          </div>
        )}
      </div>
      {isLoadingFolderAssets ? (
        <div className="w-full h-[400px] flex items-center justify-center">
          <BigSpinner />
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <div className="text-title text-sm font-medium leading-tight capitalize">
              {t("asset")}
            </div>
          </div>
          <ul
            className={cn(
              "grid xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 xxs:grid-cols-1 gap-4",
              rightCommentbarOpen &&
                sidebarExpanded &&
                "xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 xxs:grid-cols-1 ",
              !(rightCommentbarOpen && sidebarExpanded) &&
                "2xl:grid-cols-6 2lg:grid-cols-5 2md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 xxs:grid-cols-1"
            )}
          >
            {(!(folderAssets && folderAssets.length > 0) ||
              filteredAssets.length > 0) &&
              isAllowedToInteract && (
                <li className="w-full h-[240px]">
                  <div
                    className="group flex flex-col items-center justify-center gap-6 border border-border-normal rounded-2xl h-full hover:bg-gray-10 active:bg-gray-30 cursor-pointer"
                    onClick={() => {
                      setIsFormOpened(true);
                    }}
                  >
                    <div className="w-14 h-14 rounded-full bg-gray-20 flex items-center justify-center group-hover:bg-gray-30 group-active:bg-gray-40">
                      <Icon
                        component={MediaLibraryIcon}
                        viewBox="0 0 16 16"
                        width={24}
                        height={24}
                        className="fill-cta-600"
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-typography-body-normal text-base font-medium leading-tight text-center w-full select-none">
                        {t("upload_images")}
                      </div>
                      <div className="text-typography-body-subtle text-xs leading-tight text-center">
                        {t("upload_images_from_your_computer")}
                      </div>
                    </div>
                  </div>
                </li>
              )}
            {(isAssetsInFolder ||
              (!isFolders && isDefaultAssets) ||
              (!currentFolder && filteredAssets.length > 0)) &&
              filteredAssets?.map((el, idx) => (
                <DragableMediaAssetCard
                  asset={el}
                  key={el?.id}
                  onOpenImage={onOpenImage}
                  onSelect={setEditCandidate}
                  onDelete={handleDeleteAsset}
                  onOpenBenchmark={handleOpenBenchmark}
                  onCreateAiProject={onCreateAiProject}
                  onMoveAsset={onMoveAsset}
                  currentFolder={currentFolder}
                  onMove={(asset: AIDesignAssetType) => {
                    setSelectedAsset(asset);
                    setIsOpenAssetMoveModal(true);
                  }}
                  onCopy={(asset: AIDesignAssetType) => {
                    setSelectedAsset(asset);
                    setIsOpenCopyModal(true);
                  }}
                />
              ))}
          </ul>
          <div
            ref={listEndRef}
            className="w-full flex items-center justify-center !h-10 mt-4 mb-10"
          >
            {hasNextPage && <BigSpinner className="w-8 h-8" />}
          </div>
          {selectedAsset && (
            <AssetMoveModal
              isOpen={isOpenAssetMoveModal}
              asset={selectedAsset}
              currentFolderId={Number(currentFolder)}
              onClose={() => {
                setSelectedAsset(null);
                setIsOpenAssetMoveModal(false);
              }}
              onMove={onMoveAsset}
              onOpenNewFolderDlg={() => setIsNewFolderTxtDlg(true)}
            />
          )}
          {selectedFolder && isOpenRenameFolderDlg && (
            <RenameFolderDialog
              isOpen={isOpenRenameFolderDlg}
              onClose={handleCloseRenameFolderDialog}
              onCancel={handleCloseRenameFolderDialog}
              onConfirm={(folderName: string) => {
                editAssetFolder(
                  {
                    id: selectedFolder?.id,
                    name: folderName,
                    teamId: teamId,
                  },
                  {
                    onSuccess() {
                      success({ message: "The folder has been edited." });
                    },
                  }
                );
                handleCloseRenameFolderDialog();
              }}
              assetId={selectedFolder.id}
              foldreName={selectedFolder.name}
            />
          )}

          {selectedAsset && isOpenCopyModal && (
            <AssetCopyModal
              isOpen={isOpenCopyModal}
              onClose={handleCloseCopyAssetModal}
              asset={
                filteredAssets?.find((item) => item.id === selectedAsset.id) ||
                null
              }
              onCopy={handleCopyAssetModal}
            />
          )}

          {selectedFolder && isOpenFolderCopyModal && (
            <FolderCopyModal
              isOpen={isOpenFolderCopyModal}
              folder={selectedFolder}
              onClose={handleCloseCopyFolderModal}
              onCopy={handleCopyAssetModal}
              refetchFolders={refetchFolders}
            />
          )}

          <ConfirmDialog
            isOpen={isOpenFolderDeletionConfirm && !!selectedFolderId}
            loading={isDeletingAssetFolder}
            title="Confirm Folder Deletion"
            subtitle="Are you sure you want to delete this folder? Deleting this folder will permanently remove all its subfolders and assets. This action cannot be undone."
            onConfirm={async () => {
              if (selectedFolderId) {
                await deleteAssetFolder(
                  {
                    id: selectedFolderId,
                    teamId: teamId,
                  },
                  {
                    onSuccess() {
                      setSelectedFolderId(null);
                    },
                  }
                );
              }
            }}
            onClose={() => {
              setIsOpenFolderDeletionConfirm(false);
              setSelectedFolderId(null);
            }}
          />
        </div>
      )}
    </>
  );
};

export default AssetsMediaLib;
