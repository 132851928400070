import Icon from "components/atoms/Icon";
import { useUser } from "contexts/UserContext";
import React, { useState } from "react";
import MoreActionsButton from "./MoreActionsButton";
import SignOutIcon from "public/icons/sign_out_16.svg";
import {
  useCancelSubscription,
  useChangeSubscription,
  useGetProducts,
} from "hooks/billing";
import LightningIcon from "public/icons/lightning_16.svg";
import { PlanNames } from "types";
import { useRouter } from "next/router";
import { useErrorHandler } from "hooks/useErrorHandler";
import { usePortal } from "contexts/PortalContext";
import { useTeam } from "contexts/TeamContext";
import { success } from "utils/toast";
import { DEFAULT_PORTAL_NAME, HOME_ROUT } from "utils/constants";
import { useMemo } from "react";
import ConfirmDialog from "components/organisms/ConfirmDialog";
import useTranslation from "next-translate/useTranslation";
import { isPlan } from "utils/isPlan";
import capitalize from "lodash/capitalize";
import { isOwnerUser } from "utils/isOwnerUser";
import cn from "classnames";
import { useQueryClient } from "react-query";

const CheckoutHeaderActions: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { signOut, user, refetchSubscription, refetch, subscription } =
    useUser();
  const { t } = useTranslation("common");
  const { currentTeam, refetchTeam } = useTeam();
  const router = useRouter();
  const { portalName, isPortal } = usePortal();
  const { handleError } = useErrorHandler();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const isTeamOwner = isOwnerUser(currentTeam?.teamRole);
  const queryClient = useQueryClient();

  const openCancelModal = () => setShowCancelModal(true);
  const closeCancelModal = () => setShowCancelModal(false);

  const { data: products } = useGetProducts({
    enabled: !!user,
  });

  const { mutate: changeSubscription } = useChangeSubscription({
    onSuccess() {
      refetchSubscription();
      refetch();
      router.push(`/${portalName}/${currentTeam?.id}${HOME_ROUT}`);
      success({ message: "Basic plan has been applied" });
    },
    onError(err) {
      handleError({ err });
    },
  });

  const { mutate: cancelSubscription, isLoading: cancelingLoading } =
    useCancelSubscription({
      onSuccess() {
        refetchSubscription();
        refetchTeam();
        refetch();
        setShowCancelModal(false);
        if (isPortal) {
          queryClient.invalidateQueries("portal-limits");
        }
        success({
          message: t(
            "plan_is_canceled_you_won’t_be_charged_after_current_payed_period_ends"
          ),
        });
        success({ message: "Basic plan has been applied" });
        router.push(`/${portalName}/${currentTeam?.id}${HOME_ROUT}`);
      },
      onError(err) {
        handleError({ err });
      },
    });

  const basicProduct = products?.find(
    (el) => el?.metadata?.name === PlanNames.BASIC
  );

  const currentPlanName = useMemo(() => {
    if (subscription) {
      return capitalize(
        (
          subscription?.items?.data?.find((el: any) => isPlan(el))?.price
            ?.product as any
        )?.metadata?.name || ""
      );
    }
  }, [subscription]);

  const actions = useMemo(() => {
    const items = [
      {
        label: "Apply Basic plan",
        icon: LightningIcon,
        onClick: () => {
          if (basicProduct) {
            changeSubscription({ productId: basicProduct?.id });
          }
        },
      },
      {
        label: "Log out",
        icon: SignOutIcon,
        onClick: () => signOut(DEFAULT_PORTAL_NAME),
      },
    ];

    if (subscription) {
      isTeamOwner
        ? items.splice(0, 1, {
            label: "Cancel plan",
            icon: LightningIcon,
            onClick: openCancelModal,
          })
        : items.shift();
    }

    return items;
  }, [subscription, basicProduct, isTeamOwner]);

  return (
    <>
      <MoreActionsButton
        dropdownClassname="w-[200px]"
        triggerClassName={cn("hover:bg-gray-300 !p-2", className)}
        iconProps={{
          className: "fill-black",
        }}
        itemComponent={({ item }) => (
          <div className="">
            <div
              onClick={item.onClick}
              className="group w-full cursor-pointer rounded-md flex hover:text-typography-headline hover:bg-gray-20 items-center px-2 py-2"
            >
              <Icon
                component={item.icon}
                viewBox="0 0 16 16"
                className="mr-2 fill-gray-80 group-hover:fill-gray-950"
                width={16}
                height={16}
              />
              <span className="text-sm leading-normal">{item.label}</span>
            </div>
          </div>
        )}
        items={actions}
      />
      {!!subscription && (
        <ConfirmDialog
          isOpen={showCancelModal}
          onClose={closeCancelModal}
          onCancel={closeCancelModal}
          onConfirm={cancelSubscription as () => void}
          loading={cancelingLoading}
          subtitle={t("cancel_plan_description")}
          title={t("cancel_particular_plan", { name: currentPlanName })}
          cancelText={t("keep_particular_plan", { name: currentPlanName })}
          confirmText={t("cancel_current_plan", { name: currentPlanName })}
        />
      )}
    </>
  );
};

export default CheckoutHeaderActions;
