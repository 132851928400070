import React, { useState, useMemo, useRef } from "react";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";
import { capitalize } from "lodash";
import Icon from "./Icon";
import { ISelectValue, SelectOptionsType } from "types";
import ChevronDown from "public/icons/chevron_down_16.svg";
import ColorTile from "./ColorTile";
import Tooltip from "./Tooltip";
import { useDidUpdateEffect } from "hooks/useDidUpdateEffect";

export interface SearchDropdownProps {
  options: SelectOptionsType;
  selectedItem: ISelectValue;
  placeholder?: string;
  onSelect: Function;
  className?: string;
  buttonClassName?: string;
  buttonWrapperClassName?: string;
  containerClassName?: string;
  isColorOption?: boolean;
  disabled?: boolean;
}

const SearchDropdown = ({
  options,
  placeholder,
  selectedItem,
  onSelect,
  className,
  buttonClassName,
  buttonWrapperClassName,
  containerClassName,
  isColorOption,
  disabled,
  ...rest
}: SearchDropdownProps) => {
  const { t } = useTranslation("common");
  const [showList, setShowList] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const searchInputRef = useRef<any>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleInputFocus = () => {
    setShowList(!disabled && true);
    setTimeout(() => searchInputRef.current?.focus(), 100);
  };

  const handleInputBlur = () => {
    setTimeout(() => setShowList(false), 200);
  };

  const handleValueSelect = (seleted: any) => {
    setShowList(false);
    onSelect(seleted);
  };

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );
  }, [searchText, options]);

  // for preselected values
  useDidUpdateEffect(() => {
    if (selectedItem.preselected) {
      buttonRef.current?.classList?.remove("bg-transparent");
      buttonRef.current?.classList?.add("bg-green-200");

      setTimeout(() => {
        buttonRef.current?.classList?.remove("bg-green-200");
        buttonRef.current?.classList?.add("bg-transparent");
      }, 3000);
    }
  }, [selectedItem]);

  return (
    <div
      className={cn("flex flex-col relative w-fit min-w-[180px]", className)}
    >
      <div className="relative z-0 w-full">
        <div
          className={cn(
            "flex justify-between items-center min-w-[180px] group",
            buttonWrapperClassName
          )}
        >
          {!showList ? (
            <div className="w-full">
              {selectedItem.icon && (
                <span className="absolute top-2 left-4">
                  <Icon
                    component={selectedItem.icon}
                    viewBox="0 0 20 20"
                    className="w-4 h-4 stroke-gray-70"
                  />
                </span>
              )}
              {isColorOption && selectedItem.value !== "" && (
                <span className="absolute top-[9px] left-4 !rounded-[4px] overflow-hidden">
                  <ColorTile
                    color={selectedItem.name}
                    className="w-4 h-4 block !rounded-[4px]"
                  />
                </span>
              )}
              <button
                ref={buttonRef}
                className={cn(
                  "block text-sm font-normal bg-transparent duration-1000 transition-[background] ease-out text-left text-heading cursor-pointer py-2 px-4 pr-8 hover:border-gray-40 hover:bg-[#FAFAFA] focus:border-gray-950 focus-visible:!outline-gray-950 focus:border-2 min-w-[190px] w-fit z-20 rounded-md border line-clamp-1 whitespace-nowrap",
                  selectedItem.icon && "pl-10",
                  isColorOption && selectedItem.value !== "" && "pl-11",
                  disabled &&
                    "focus:!border-[1px] focus:!border-gray-40 bg-gray-20",
                  buttonClassName
                )}
                onClick={handleInputFocus}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              >
                {capitalize(t(selectedItem.name.toLowerCase()))}
              </button>
            </div>
          ) : (
            <div className="w-full">
              <input
                ref={searchInputRef}
                type={"text"}
                className={cn(
                  "block text-sm font-normal text-heading cursor-pointer py-2 px-4 hover:border-gray-40 hover:bg-[#FAFAFA] focus:border-gray-950 focus-visible:!outline-gray-950 focus:border-[2px] z-20 rounded-md border",
                  buttonClassName
                )}
                placeholder={`${t("search")}...`}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
            </div>
          )}
          {!disabled && (
            <span
              className="absolute right-2.5 cursor-pointer"
              onClick={handleInputFocus}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            >
              <Icon
                component={ChevronDown}
                width="12"
                height="12"
                viewBox="0 0 16 16"
                className={cn("fill-gray-70 group-hover:fill-gray-950")}
              />
            </span>
          )}
        </div>
      </div>
      {showList && (
        <div
          className={cn(
            "absolute z-[100] bg-white border rounded-md p-2 shadow top-11 max-h-[220px] !min-w-full overflow-y-scroll",
            containerClassName
          )}
        >
          <ul>
            {filteredOptions.length === 0 && (
              <li
                className={cn(
                  "relative flex items-center rounded-md py-[9px] px-2 pl-2 text-gray-900 hover:bg-[#EBE8FE] cursor-pointer hover:text-gray-950"
                )}
              >
                <span className="pl-2 hover:text-gray-950 text-sm leading-[21px] font-normal">
                  {t("no_items")}
                </span>
              </li>
            )}
            {filteredOptions.map((option, index) => (
              <Tooltip
                key={index}
                content={option.tip || ""}
                className="!w-fit"
                disabled={!option.tip}
              >
                <li
                  onClick={() => !option.disabled && handleValueSelect(option)}
                  className={cn(
                    "h-10 relative flex items-center rounded-md py-[9px] px-2 pl-2 text-gray-900 hover:bg-gray-20 cursor-pointer stroke-gray-70 hover:text-gray-950 hover:stroke-gray-950",
                    option.value === selectedItem.value &&
                      "bg-gray-30 text-gray-950 stroke-gray-950",
                    option.disabled &&
                      "cursor-auto text-gray-50 hover:bg-gray-30 hover:text-gray-50 hover:stroke-gray-50 stroke-gray-50"
                  )}
                >
                  {option.icon && option.value !== "" && (
                    <span>
                      <Icon
                        component={option.icon}
                        viewBox="0 0 20 20"
                        className="mr-2 w-4 h-4"
                      />
                    </span>
                  )}
                  {isColorOption && option.value !== "" && (
                    <ColorTile color={option.name} className="mr-2 w-4 h-4" />
                  )}
                  <span
                    className={cn(
                      "hover:text-gray-950 text-sm leading-[21px] font-normal",
                      option.disabled && "hover:text-gray-50"
                    )}
                  >
                    {capitalize(t(option.name.toLowerCase()))}
                  </span>
                </li>
              </Tooltip>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
