import { useGetFlatFacets } from "hooks/facets";
import React from "react";
import cn from "classnames";
import { useMemo } from "react";
import { ColorNames, Gender } from "utils/constants";
import uniq from "lodash/uniq";
import capitalize from "lodash/capitalize";
import useTranslation from "next-translate/useTranslation";
import Select from "components/atoms/Select";
import { ISelectValue } from "types";
import { useUser } from "contexts/UserContext";
import isEmpty from "lodash/isEmpty";
import { usePortal } from "contexts/PortalContext";
import { useTeam } from "contexts/TeamContext";

interface ISamplesFilterProps {
  className?: string;
  basecat: ISelectValue;
  gender: ISelectValue;
  color: ISelectValue;
  onSelect: (field: string) => (val: ISelectValue) => void;
  disabled?: boolean;
}

const SamplesFilter: React.FC<ISamplesFilterProps> = ({
  className,
  basecat,
  gender,
  color,
  disabled,
  onSelect,
}) => {
  const { t } = useTranslation("common");
  const { user } = useUser();
  const { userPortal } = usePortal();
  const { currentTeam } = useTeam();

  const { data: facets = {} } = useGetFlatFacets({
    enabled:
      !!user &&
      !!currentTeam &&
      (!!currentTeam?.portal && !!userPortal
        ? currentTeam?.portal?.id === userPortal?.id
        : true),
  });

  const genderOptions = [{ name: t("all_genders") }].concat(
    Object.entries(Gender)
      .filter(([_, value]) => !!value && value !== Gender.Y)
      .map(([key, value]: string[]) => ({
        name: value,
        value: key,
      }))
  );

  const colorOptions = [{ name: t("all_colors") }].concat(ColorNames);

  const basecatOptions = useMemo(() => {
    if (facets && !isEmpty(facets)) {
      return [{ name: "all_categories" }].concat(
        uniq(facets?.map((el: any) => el.basecat) as string[])
          .sort((a, b) => a.localeCompare(b))
          .filter((el) => el !== "baby")
          .map((el) => ({ name: capitalize(t(el)), value: el }))
      );
    }

    return [];
  }, [facets]);

  return (
    <div
      className={cn(
        "flex items-center xxs:flex-wrap xs:flex-nowrap gap-4 sm:justify-end justify-start w-full",
        className
      )}
    >
      {!!basecatOptions?.length && (
        <Select
          onSelect={onSelect("basecat")}
          disabled={disabled}
          options={basecatOptions}
          value={basecat}
          buttonClassName="!p-0 !border-0 !bg-transparent !border-transparent"
          textClassName="!text-xs"
          optionsContainerClassName="w-fit"
        />
      )}
      <Select
        onSelect={onSelect("gender")}
        options={genderOptions}
        disabled={disabled}
        value={gender}
        buttonClassName="!p-0 !border-0 !bg-transparent !border-red-600"
        textClassName="!text-xs"
        optionsContainerClassName="w-fit"
      />
      <Select
        onSelect={onSelect("color")}
        options={colorOptions}
        disabled={disabled}
        value={color}
        buttonClassName="!p-0 !border-0 !bg-transparent !border-transparent"
        textClassName="!text-xs"
        color
        optionsContainerClassName="w-fit -left-3"
      />
    </div>
  );
};

export default SamplesFilter;
