import React, { useState } from "react";
import ConfirmDialog from "../ConfirmDialog";
import useTranslation from "next-translate/useTranslation";
import Input from "components/atoms/Input";

interface RenameFolderDialogProps {
  isOpen: boolean;
  assetId?: number;
  foldreName?: string;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: (folderName: string) => void;
}

const RenameFolderDialog: React.FC<RenameFolderDialogProps> = ({
  isOpen,
  assetId,
  foldreName,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation("common");
  const [folderNameText, setFolderNameText] = useState<string>(
    foldreName || ""
  );
  const [error, setError] = useState<boolean>(false);

  return (
    <ConfirmDialog
      className="rounded-2xl"
      title={t("rename")}
      titleClassName="!font-medium"
      cancelText={t("cancel")}
      cancelVariant="secondary"
      confirmText={t("OK")}
      confirmVariant="dark"
      buttonClassName="!font-medium"
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={() => {
        if (folderNameText.length > 0 && assetId) {
          onConfirm(folderNameText);
        } else {
          setError(true);
        }
      }}
    >
      <Input
        type="text"
        value={folderNameText}
        className="xxs:w-full xs:w-[416px]"
        containerClassName={error ? "!pb-0" : ""}
        onChange={(e) => {
          setError(false);
          setFolderNameText(e.target.value);
        }}
      />
      {error && (
        <div className="text-red-50 text-xm pb-3">
          Please enter a folder name.
        </div>
      )}
    </ConfirmDialog>
  );
};

export default RenameFolderDialog;
