import React from "react";
import cn from "classnames";
import dynamic from "next/dynamic";
import useChart from "../../../hooks/useChart";
import { getCurrencySymbol } from "utils/getCurrencySymbols";
import { uniq } from "lodash";
import { convertDate } from "utils/dates";
import { convertUSLocalString } from "utils/beautifyNumbers";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

type TApexLineChart = {
  categories: any[];
  chartData: {
    name: string;
    type: string;
    data: any[];
    legend: {
      show: boolean;
    };
  }[];
  className?: string;
  width?: number;
  height?: number;
  currency?: string;
  colors?: string[];
};

const ProductPriceAreaChart = ({
  categories,
  chartData,
  className,
  width,
  height,
  currency,
  colors,
}: TApexLineChart) => {
  const chartOptions = useChart({
    stroke: {
      curve: "smooth",
      lineCap: "butt",
      width: 2,
      dashArray: 0,
    },
    fill: {
      type: chartData[0].type === "line" ? "solid" : "gradient",
      colors: ["#341BF9"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.3,
        opacityTo: 0.5,
        stops: [0, 90, 100],
      },
    },
    colors,
    series: chartData,
    xaxis: {
      categories: uniq(categories),
      tooltip: {
        enabled: false,
      },
      type: "datetime",
      labels: {
        formatter: function (val) {
          return convertDate(val, "MMM YYYY");
        },
      },
      axisBorder: {
        show: true,
        color: "#42474D",
        strokeWidth: 1.5,
      },
      offsetX: 20,
      tickAmount: 3,
      tickPlacement: "on",
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        formatter(val, opts) {
          return `${convertUSLocalString(Math.floor(val))} USD`;
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      horizontalAlign: "right",
      position: "top",
      onItemHover: { highlightDataSeries: false },
      markers: {
        strokeColor: "#fff",
        fillColors: ["#fff", "#fff"],
        customHTML: function () {
          return `<svg style="margin-top: 2px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2,12h20" style="fill:none;stroke:#4B35F5;stroke-width:2px;"/></svg>`;
        },
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    tooltip: {
      custom: (data: any) => {
        let title = chartData[0].name;
        let value = chartData[0].data[data.dataPointIndex];
        var date = new Date(categories[data.dataPointIndex]);
        var year = date.getFullYear();
        var month = ("0" + (date.getMonth() + 1)).slice(-2);

        return `<div class="p-3">
        <div class="text-xs text-gray-60 font-normal">${convertDate(
          year + "/" + month + "/" + (date.getDate() + 1),
          "MMM DD, YYYY"
        )}</div>
        <div class="text-sm text-body"><span class="mr-1">${title}:</span><span class="font-medium">${convertUSLocalString(
          value
        )} ${currency || ""}</span></div>
        </div>`;
      },
      style: {
        fontSize: "14px",
      },
      marker: {
        show: false,
      },
    },
    chart: {
      offsetX: -15,
    },
  });

  return (
    <div className={cn(`w-full font-medium`, className)}>
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type="area"
        width={width}
        height={height}
      />
    </div>
  );
};

export default ProductPriceAreaChart;
