import ImageResponsive from "components/atoms/ImageResponsive";
import ProgressList from "components/molecules/ProgressList";
import { AIImageSearchResultType } from "hooks/useAiImageSearch";
import useSourceMap from "hooks/useSourceMap";
import { capitalize, isArray } from "lodash";
import isEmpty from "lodash/isEmpty";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { useMemo } from "react";
import { beautifyBasecat } from "utils/beautify";
import { PriceRangeTitle } from "utils/constants";
import { convertDate } from "utils/dates";
import {
  normalizeProductsDateData,
  normalizeProductsPriceData,
} from "utils/normalizeProductsData";

interface AnalyticsPDFProps {
  filterParams: any;
  benchmarkData: AIImageSearchResultType;
}

const AnalyticsPDF: React.FC<AnalyticsPDFProps> = ({
  filterParams,
  benchmarkData,
}) => {
  const { t } = useTranslation("common");
  const allSourceMapData = useSourceMap();

  const { brand, retailer, fabric, colormain } = useMemo(() => {
    return (
      benchmarkData?.statistics?.categorical || {
        brand: {},
        retailer: {},
        fabric: {},
        colormain: {},
      }
    );
  }, [benchmarkData]);
  const price_usd = benchmarkData?.statistics?.numerical?.price_usd || {};

  const lists: { [key: string]: any[] } = useMemo(
    () => ({
      brand: brand
        ? Object.entries(brand)
            .map(([name, value]: any) => ({
              name,
              rate: Number((value * 100).toFixed(2)),
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
      retailer: retailer
        ? Object.entries(retailer)
            .map(([key, value]) => ({
              name: allSourceMapData[key]?.name || key,
              rate: Number((value * 100).toFixed(2)),
              img: `/icons/retailer/${key}.svg`,
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
      price_range: price_usd
        ? Object.entries(price_usd)
            .filter(([key, value]) => value.c !== 0)
            .map(([name, value]) => ({
              name: t(PriceRangeTitle[name]),
              value: value.c,
              rate: Number((value.p * 100).toFixed(2)),
            }))
        : [],
      material: fabric
        ? Object.entries(fabric)
            .filter(([name]) => name !== "no_value")
            .map(([name, value]) => ({
              name: t(name.toLocaleLowerCase()),
              rate: Number((value * 100).toFixed(2)),
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
      color: colormain
        ? Object.entries(colormain)
            .filter((color) => color[0].split(",").length === 1)
            .map(([name, value]) => ({
              name: t(name.toLocaleLowerCase()),
              color: name.toLocaleLowerCase(),
              rate: Number((value * 100).toFixed(2)),
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
    }),
    [benchmarkData]
  );

  const priceChartData = useMemo(
    () =>
      normalizeProductsPriceData(
        benchmarkData?.metadata?.filter((item) => !!item.price_usd) || []
      ),
    [benchmarkData?.metadata]
  );

  const dateChartData = useMemo(
    () =>
      normalizeProductsDateData(
        benchmarkData?.metadata?.filter((item) => !!item.first_seen) || []
      ),
    [benchmarkData?.metadata]
  );

  if (!benchmarkData || isEmpty(benchmarkData)) return null;

  return (
    <div className="pb-20 px-4 mt-10">
      <div className="flex items-center justify-center">
        <h1 className="w-fit text-center text-3xl font-bold ">
          Benchmark Analytics
        </h1>
      </div>
      <div className="flex items-start justify-start gap-4 border rounded-xl overflow-hidden mt-5 p-5">
        <ImageResponsive
          url={filterParams.data_path}
          width={150}
          height={150}
        />

        <div className="flex flex-col gap-1">
          {Object.entries(filterParams?.categorical_catalog_metadata || []).map(
            ([key, values]: any) => (
              <div className="text-sm">{`${capitalize(
                beautifyBasecat(key || "#")
              )}: ${
                isArray(values)
                  ? values
                      ?.map((item: string) =>
                        capitalize(beautifyBasecat(item || "#"))
                      )
                      ?.join(",")
                  : values
              }`}</div>
            )
          )}
        </div>
      </div>
      {lists &&
        Object.entries(lists).map(([key, list]) => (
          <div
            key={key}
            className="w-full p-5 my-5 border rounded-xl border-border-normal"
          >
            <h3 className="uppercase text-lg font-bold border-b">{t(key)}</h3>
            <ProgressList
              list={list}
              className="!mt-0"
              colorTileClassName="rounded-full w-3 h-3"
              listTitleClassName="!text-heading !font-medium !leading-tight !text-sm !border-none !py-0 !pb-3"
            />
          </div>
        ))}
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full p-5 border rounded-xl border-border-normal">
          <h3 className="uppercase text-lg font-bold mb-2">{t("price")}</h3>
          <BenchmarkChart chartData={priceChartData} type={"price"} />
        </div>
        <div className="w-full p-5 border rounded-xl border-border-normal">
          <h3 className="uppercase text-lg font-bold mb-2">{t("date")}</h3>
          <BenchmarkChart chartData={dateChartData} type={"date"} />
        </div>
      </div>
      {<div id="loaded" />}
    </div>
  );
};

export default AnalyticsPDF;

interface BenchmarkChartProps {
  chartData: {
    x: number;
    y: number;
  }[];
  type: "date" | "price";
}

const BenchmarkChart: React.FC<BenchmarkChartProps> = React.memo(
  ({ chartData, type }) => {
    const { max, min } = useMemo(() => {
      const data = chartData.map((item) => item.y);
      return { max: Math.max(...data), min: Math.min(...data) };
    }, [chartData]);

    const minXValue = chartData[0].x;
    const maxXValue = chartData[chartData.length - 1].x;

    return (
      <div className="w-full">
        <div className="w-full h-[100px] flex items-end justify-start border-b border-gray-50">
          {chartData.map((item, index) => {
            const height = (item.y / (max - min)) * 100;
            return (
              <div
                key={index}
                className="px-[1px]"
                style={{
                  height: `${height}%`,
                  width: `${100 / chartData.length}%`,
                }}
              >
                <div
                  className={"rounded-t-[1px] h-full bg-gray-950"}
                  style={{
                    borderRadius: height <= 5 ? `0` : "",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="w-full flex items-center justify-between mt-1">
          <div className="text-xs text-typography-body-normal">
            {type === "date"
              ? convertDate(minXValue, "YYYY/MM")
              : `${minXValue}$`}
          </div>
          <div className="text-xs text-typography-body-normal">
            {type === "date"
              ? convertDate(maxXValue, "YYYY/MM")
              : `${maxXValue}$`}
          </div>
        </div>
      </div>
    );
  }
);
