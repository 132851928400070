import CtaButton, { ButtonType } from "components/atoms/CtaButton";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import ChevronLeft from "public/icons/chevron_left_bold_16.svg";
import cn from "classnames";
import { useRouter } from "next/router";

interface IBackButtonProps extends ButtonType {
  alt?: boolean;
}

const BackButton: React.FC<IBackButtonProps> = ({
  className,
  onClick,
  alt,
  ...props
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const onBack = (e: any) => {
    if (onClick) {
      return onClick(e);
    }

    router.back();
  };

  return (
    <CtaButton
      variant={alt ? "tetriary" : "link"}
      iconLeft={ChevronLeft}
      iconLeftProps={{
        viewBox: "0 0 16 16",
        className: alt ? "fill-black" : "fill-indigo-70",
      }}
      className={cn("capitalize !font-bold", alt && "!p-2", className)}
      onClick={onBack}
      {...props}
    >
      {alt ? "" : t("back")}
    </CtaButton>
  );
};

export default BackButton;
