import { Disclosure } from "@headlessui/react";
import Icon from "components/atoms/Icon";
import DownTriangle from "public/icons/down_triangle_12.svg";
import cn from "classnames";
import Tooltip from "components/atoms/Tooltip";
import { useState } from "react";

interface SidebarItemGroupProps {
  icon: any;
  label: string;
  children: any;
  expanded?: boolean;
  defaultOpen?: boolean;
}

export default function SidebarItemGroup({
  icon,
  label,
  children,
  expanded,
  defaultOpen,
}: SidebarItemGroupProps) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-2 w-full">
            <Tooltip
              oneline
              arrowHidden
              direction="right"
              folowCursor={false}
              className="!p-1.5"
              disabled={expanded}
              content={label}
              offset={[0, -5]}
            >
              <div
                className={cn(
                  "flex items-center justify-start gap-2.5 bg-whtie rounded-xl w-full py-[9px] pr-4",
                  open && "bg-gray-20",
                  !expanded && "!justify-end"
                )}
              >
                <div
                  className={cn(
                    "flex items-center justify-start",
                    !expanded && "!justify-end w-full"
                  )}
                >
                  <Icon
                    component={DownTriangle}
                    viewBox="0 0 12 12"
                    width={12}
                    height={12}
                    className={cn("rotate-0", !open && "!-rotate-90")}
                  />
                  {icon}
                </div>
                {expanded && (
                  <span
                    className={cn(
                      "font-bold text-gray-70 leading-tight text-sm",
                      open && "bg-gray-20 !text-typography-title"
                    )}
                  >
                    {label}
                  </span>
                )}
              </div>
            </Tooltip>
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className={cn("pl-[26px]", !expanded && "!pl-0")}>
              {children}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
