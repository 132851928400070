import { AIDesignProjectMoodboardType } from "api/AIDesignProjectApi";
import CtaButton from "components/atoms/CtaButton";
import Select from "components/atoms/Select";
import { usePortal } from "contexts/PortalContext";
import { useUser } from "contexts/UserContext";
import { useGetProjects } from "hooks/aiDesignProjects";
import { TVGarment } from "models/TvGarment";
import useTranslation from "next-translate/useTranslation";
import React, { useMemo, useState } from "react";
import { ISelectValue } from "types";
import ShareLinkIcon from "public/icons/share_link.svg";
import BigSpinner from "components/atoms/BigSpinner";
import { TVChannelMoodboard } from "models/TVChannelMoodboard";
import ProductCard, {
  IProductCardProps,
} from "components/atoms/AIStreaming/ProductCard";
import { useEffect } from "react";
import { useCreateRevision, useUpdateTvChannel } from "hooks/tvChannels";
import { useQueryClient } from "react-query";
import { useApi } from "contexts/ApiContext";
import { isNull } from "lodash";
import { useTeam } from "contexts/TeamContext";

export interface ISavedImagesViewProps extends Partial<IProductCardProps> {
  projectName?: string;
  targetProjectId?: number | null;
  previewMode?: boolean;
  teamId?: number;
  onExploreClick?: () => void;
  enabled?: boolean;
  onSelectProject?: (val: number) => void;
  moodboardData?: AIDesignProjectMoodboardType[];
  loading?: boolean;
}

const SavedImagesEmptyState: React.FC<{
  isExploreButtonVisible?: boolean;
  onExploreClick?: () => void;
}> = ({ isExploreButtonVisible, onExploreClick }) => {
  const { t } = useTranslation("common");

  return (
    <div className="w-full h-full flex items-center">
      <div className="flex justify-center items-center w-full flex-col">
        <h3 className="headline-300 mb-2">{t("no_saves")}</h3>
        <p className="body-100 text-center mb-6">
          {isExploreButtonVisible
            ? t("no_saves_desc")
            : t("no_saves_projects_desc")}
        </p>
        {isExploreButtonVisible && onExploreClick && (
          <CtaButton onClick={onExploreClick}>{t("start_exploring")}</CtaButton>
        )}
      </div>
    </div>
  );
};

const SavedImagesView: React.FC<ISavedImagesViewProps> = ({
  projectName,
  previewMode,
  targetProjectId,
  onExploreClick,
  onSelectProject,
  moodboardData,
  teamId,
  enabled,
  loading,
  ...props
}) => {
  const { t } = useTranslation("common");
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { portalName } = usePortal();
  const { user } = useUser();
  const { tvChannels } = useApi();
  const queryClient = useQueryClient();
  const { push } = useTeam();
  const [selectedGarment, setSelectedGarment] = useState<TVGarment>();

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 5000);
    }
  }, [isCopied]);

  const { data: projects } = useGetProjects(teamId!, {
    enabled: !!teamId && !!user && !projectName,
  });

  const projectOptions = useMemo(() => {
    if (projects?.length) {
      return [
        { name: t("all_projects"), value: null },
        ...projects
          .filter((el) => !!el.moodboardImagesCount)
          .map((el) => ({ name: el.name, value: el.id })),
      ];
    }

    return [];
  }, [projects]);

  const getChannelData = async (id: number) => {
    const result = await tvChannels.getTvChannelById(id);
    return result;
  };

  const { mutate: updateChannel, isLoading: updatingChannel } =
    useUpdateTvChannel({
      onMutate: async ({ id, payload }) => {
        const queryKey = ["tvChannel", id];
        await queryClient.cancelQueries({ queryKey });

        const previousState = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (old: any) => {
          let imageKeywords: any = isNull(payload.imageSampleKeywordSet)
            ? payload.imageSampleKeywordSet
            : old.imageSampleKeywordSet;

          if (payload.imageSampleKeywordSet?.length) {
            imageKeywords = {
              keywordSet: payload.imageSampleKeywordSet,
            };
          }

          const data = {
            ...(old || {}),
            keywordSetId: payload?.keywordSetId || old?.keywordSetId,
            keywordSet: {
              ...(old?.keywordSet || payload?.keywordSet),
              id: payload.keywordSetId || old.keywordSetId,
              keywordSet: payload?.keywordSet || old?.keywordSet?.keywordSet,
            },
            focusedGarmentRevision:
              payload.focusedGarmentRevision ||
              isNull(payload.focusedGarmentRevision)
                ? payload.focusedGarmentRevision
                : old.focusedGarmentRevision,
            imageSample:
              isNull(payload.imageSample) || payload.imageSample
                ? payload.imageSample
                : old.imageSample,
            imageSampleKeywordSet: imageKeywords,
          };

          return data;
        });

        return { previousState };
      },

      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ["tvChannel", context?.id],
          context.previousState
        );
      },
      onSettled: (payload) => {
        queryClient.invalidateQueries({ queryKey: ["tvChannel", payload?.id] });
      },
    });

  const { mutate: createRevision, isLoading: createRevisionLoading } =
    useCreateRevision({
      async onSuccess(data) {
        const selectedChannelData = await getChannelData(data?.channelId);
        updateChannel(
          {
            id: data?.channelId,
            payload: {
              ...selectedChannelData,
              keywordSet: selectedChannelData?.keywordSet?.keywordSet,
              focusedGarmentRevision: data,
            },
          },
          {
            async onSuccess() {
              const selectedChannelData = await getChannelData(data?.channelId);
              push(
                `/ai-design/${selectedChannelData.tvProjectId}/${data?.channelId}`
              );
            },
          }
        );
      },
    });

  const onGenerateSelected = (garment: TVGarment) => {
    setSelectedGarment(garment);
    createRevision({
      channelId: garment?.channelId,
      rootGarmentId: garment.id,
      keywordSet: garment?.keywordSet?.keywordSet,
      parentId: garment?.tvGarmentRevisionId || undefined,
    });
  };

  const onSelect = (val: ISelectValue) => {
    setIsCopied(false);
    onSelectProject && onSelectProject(val.value);
  };

  const onShareLink = async () => {
    await window.navigator.clipboard.writeText(
      `${window.location.origin}/${portalName}/moodboard?teamId=${teamId}${
        targetProjectId ? `&projectIds=${targetProjectId}` : ""
      }`
    );
    setIsCopied(true);
  };

  return (
    <div className="w-full h-full">
      <div className="flex xxs:flex-col md:!flex-row xxs:items-start md:!items-center justify-between mb-[18px] gap-3">
        <div className="flex items-center">
          <h2 className="headline-200 mr-1">{t("saved_images")}</h2>
          {projectName ? (
            <h2 className="headline-200">
              {t("in_tv_project", { name: projectName })}
            </h2>
          ) : (
            <>
              {!!projects?.length && (
                <Select
                  options={projectOptions}
                  value={projectOptions.find(
                    (el) => el.value === targetProjectId
                  )}
                  onSelect={onSelect}
                  buttonClassName="max-h-[32px] ml-1 xxs:max-w-[180px] md:!max-w-auto"
                  optionsContainerClassName="xxs:w-fit xxs:right-0 md:!right-auto xxs:max-w-[200px] md:!max-w-auto md:!w-[250px]"
                />
              )}
            </>
          )}
        </div>
        {!!moodboardData?.length && !previewMode && (
          <CtaButton
            size="sm"
            iconLeft={isCopied ? null : ShareLinkIcon}
            iconLeftProps={{ viewBox: "0 0 16 16" }}
            onClick={onShareLink}
            className="xxs:w-full md:!w-auto"
          >
            {isCopied ? t("share_link_copied") : t("copy_share_link")}
          </CtaButton>
        )}
      </div>
      {loading ? (
        <div className="grid place-items-center w-full h-full">
          <BigSpinner />
        </div>
      ) : (
        <>
          {moodboardData?.length ? (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {moodboardData?.map((item: TVChannelMoodboard) => (
                <div key={item?.id}>
                  <ProductCard
                    inMoodBoard
                    isSharedPreview={previewMode}
                    garment={item.tvGarment! as TVGarment}
                    {...props}
                    onBenchmarkView={() => {
                      props?.onBenchmarkView &&
                        props?.onBenchmarkView(item.tvGarment);
                    }}
                    handleSetRevisionGarment={onGenerateSelected}
                    isVariating={
                      item?.tvGarment?.id === selectedGarment?.id &&
                      (createRevisionLoading || updatingChannel)
                    }
                  />
                  {(!targetProjectId || previewMode) && (
                    <div className="pt-2">
                      <p className="text-xs body-100-bold">
                        {item.tvProject?.name}
                      </p>
                      <p className="text-xs body-100-subtle">
                        {item.channel?.name}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col h-full items-center justify-center">
              <SavedImagesEmptyState
                isExploreButtonVisible={!!projectName}
                onExploreClick={onExploreClick}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SavedImagesView;
