import React, { useMemo, useRef, useState } from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
// components
import Icon from "components/atoms/Icon";
import TooltipButton from "components/atoms/TooltipButton";
// contexts
import { useUser } from "contexts/UserContext";
import { useTeam } from "contexts/TeamContext";
import { usePortal } from "contexts/PortalContext";
// hooks
import { useGetMessagesViewStatus } from "hooks/rooms";
// utils
import { isRestrictedCustomDomain } from "utils/checkPortal";
import { isProduction } from "utils/checkEnv";
import { HEADER_HEIGHT, PageNames } from "utils/constants";
// icons
import AIStreammingIcon from "public/icons/ai_generate_16.svg";
import TrendForecastIcon from "public/icons/trend_16.svg";
import MarketAnalyticsIcon from "public/icons/market_analytics_24.svg";
import MediaLibraryIcon from "public/icons/media_library_16.svg";
import ApplicationsIcon from "public/icons/apps_16.svg";
import HomeIcon from "public/icons/home_16.svg";
import CaretDown from "public/icons/caret-down_16.svg";
import CaretUp from "public/icons/caret-up_16.svg";
import BatteryIcon from "public/icons/battery.svg";
import ReportIcon from "public/icons/reports_16.svg";
import ChatIcon from "public/icons/chat_16.svg";
import { isGuestUser } from "utils/isGuestUser";
import { PlanNames } from "types";
import PortalSelection from "components/molecules/PortalSelection";
import OpenIcon from "public/icons/open_panel_24.svg";
import CloseIcon from "public/icons/close_panel_24.svg";
import TeamDropdown from "components/molecules/TeamDropdown";
import Navigation from "components/molecules/Navigation";

const TV_FEATURE_FLAG = process.env.NEXT_PUBLIC_TV_FEATURE;
const TREND_FEATURE_FLAG = process.env.NEXT_PUBLIC_TREND_FEATURE;

const BOTTOM_BUTTON_BLOCK_HEIGHT = 60;

interface SidebarProps {
  sidebarExpanded: boolean;
  disableExpand?: boolean;
  setSidebarExpanded: Function;
  showCheckoutActions?: boolean;
  onSelectMenu?: (page: string) => void;
}

function Sidebar({
  sidebarExpanded,
  onSelectMenu,
  setSidebarExpanded,
  disableExpand = false,
  showCheckoutActions = false,
}: SidebarProps) {
  const { user, isAnalyticsEnabled, isAdmin } = useUser();
  const { t } = useTranslation("common");
  const [displayHiddenFeature, setDisplayHiddenFeature] =
    useState<boolean>(false);

  const { currentTeam, path: pathWithTeam, isFetched } = useTeam();
  const { isPortal, portalName, userPortal, urlPortal, ownerPortal } =
    usePortal();

  const isGuest = isGuestUser(currentTeam?.teamRole);

  const sidebar = useRef<HTMLHeadingElement>(null);

  const { data: messagesViewStatus } = useGetMessagesViewStatus(
    userPortal?.id!,
    currentTeam?.id!,
    {
      enabled: !!user && !!currentTeam && isFetched,
    }
  );

  const expandCollapse = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const userPages = useMemo(() => {
    return [
      {
        className: "menu-benchmark",
        name: PageNames.HOME,
        href: isPortal ? `/${portalName}` : pathWithTeam("/home"),
        icon: ({ color }: { color: number }) => (
          <Icon
            component={HomeIcon}
            fill={color}
            viewBox="0 0 16 16"
            className="group-hover:fill-gray-950 w-4 h-4"
          />
        ),
        title: t("Home"),
      },
      {
        isHidden: !TREND_FEATURE_FLAG,
        className: "menu-forecasts",
        name: PageNames.FORECASTS,
        href: pathWithTeam("/forecast"),
        icon: ({ color }: { color: number }) => (
          <Icon
            component={TrendForecastIcon}
            fill={color}
            viewBox="0 0 16 16"
            className="group-hover:fill-gray-950 w-4 h-4"
          />
        ),
        title: t("Forecast"),
      },
      {
        className: "menu-reports",
        name: PageNames.REPORTS,
        href: pathWithTeam("/report/all"),
        icon: ({ color }: { color: number }) => (
          <Icon
            component={ReportIcon}
            fill={color}
            viewBox="0 0 16 16"
            className="group-hover:fill-gray-950 w-4 h-4"
          />
        ),
        title: t("report"),
      },
      {
        isHidden: !TV_FEATURE_FLAG,
        className: "menu-tv",
        name: PageNames.AI_DESIGN,
        href: pathWithTeam("/ai-design"),
        title: t("ai_design"),
        icon: ({ color }: { color: number }) => (
          <Icon
            component={AIStreammingIcon}
            fill={color}
            viewBox="0 0 16 16"
            className="group-hover:fill-gray-950 w-4 h-4"
          />
        ),
      },
      {
        isHidden: !user?.isOrdersEnabled,
        name: PageNames.ORDERS,
        className: "menu-orders",
        href: pathWithTeam("/orders"),
        icon: ({ color }: { color: number }) => (
          <Icon
            component={BatteryIcon}
            width={18}
            height={18}
            fill={color}
            viewBox="0 0 16 16"
            className="group-hover:fill-gray-950 w-4 h-4"
          />
        ),
        title: t("order"),
      },
      {
        href: pathWithTeam("/media-lib"),
        name: PageNames.MEDIA_LIB,
        className: "border-t  border-gray-200",
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MediaLibraryIcon}
            viewBox="0 0 16 16"
            width={16}
            height={16}
            fill={color}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("library"),
        onClick: () => onSelectMenu && onSelectMenu(PageNames.MEDIA_LIB),
      },
      {
        isHidden: isRestrictedCustomDomain(urlPortal),
        href: pathWithTeam("/integrations"),
        name: PageNames.INTEGRATIONS,
        className: "border-gray-200",
        icon: ({ color }: { color: number }) => (
          <Icon
            component={ApplicationsIcon}
            viewBox="0 0 16 16"
            width={16}
            height={16}
            fill={color}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("integrations"),
      },
      {
        className: "menu-messenger",
        name: PageNames.MESSENGER,
        href: `/${portalName}/messenger`,
        showBadge: messagesViewStatus,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={ChatIcon}
            viewBox="0 0 16 16"
            width={16}
            height={16}
            fill={color}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("chat"),
      },
      {
        isHidden: isProduction() || !isAdmin || !isAnalyticsEnabled,
        name: "#",
        href: "#",
        icon: ({ color }: { color: number }) => (
          <Icon
            component={displayHiddenFeature ? CaretUp : CaretDown}
            fill={color}
            viewBox="0 0 16 16"
            className="fill-gray-950 group-hover:fill-gray-950 w-4 h-4"
          />
        ),
        title: displayHiddenFeature ? t("Less") : t("More"),
        onClick: (e: any) => {
          e.stopPropagation();
          setDisplayHiddenFeature(!displayHiddenFeature);
        },
      },
      {
        isHidden: !isAnalyticsEnabled,
        isMoreGroup: true,
        className: "menu-projects",
        name: PageNames.ANALYTICS,
        href: pathWithTeam("/analytics"),
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("market_analytics"),
      },
    ];
  }, [
    portalName,
    isPortal,
    urlPortal,
    messagesViewStatus,
    displayHiddenFeature,
    isAdmin,
    isAnalyticsEnabled,
    user,
  ]);

  const planName = useMemo(
    () => (isPortal ? "Enterprise" : user?.stripePlanName || ""),
    [isPortal, user?.stripePlanName]
  );

  const adminPages = useMemo(() => {
    return [
      {
        href: `/${portalName}/admin/support/joined`,
        name: PageNames.SUPPORT,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("support"),
      },
      {
        href: `/${portalName}/admin/articles`,
        name: PageNames.ARTICLES,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("tutorial_short"),
      },
      {
        href: `/${portalName}/admin/add-email`,
        name: PageNames.ADD_EMAIL,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("whitelist"),
        isHidden: isProduction(),
      },
      {
        href: `/${portalName}/admin/generation-settings`,
        name: PageNames.GENERATION_SETTINGS,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("Generation settings"),
      },
      {
        name: PageNames.PROMO_CODES,
        href: `/${portalName}/admin/promo-codes`,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("Promocodes"),
      },
      {
        name: PageNames.TRIAL_OFFERS,
        href: `/${portalName}/admin/trial-offers`,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("plans_management"),
      },
      {
        name: PageNames.USER_STATISTICS,
        href: `/${portalName}/admin/users-statistics`,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("users_statistics"),
      },
      {
        name: PageNames.ADMIN_ORDERS,
        href: `/${portalName}/admin/order-statistics`,
        icon: ({ color }: { color: number }) => (
          <Icon
            component={MarketAnalyticsIcon}
            fill={color}
            width={16}
            height={16}
            className="group-hover:fill-gray-950"
          />
        ),
        title: t("orders"),
      },
    ];
  }, [portalName]);

  return (
    <div className="flex h-screen">
      <div
        className={cn(
          "bg-gray-10 pb-3 pt-[18px] w-[52px] px-3",
          !sidebarExpanded && "!bg-white"
        )}
      >
        <div
          style={{
            height: `calc(100vh - ${BOTTOM_BUTTON_BLOCK_HEIGHT + 24}px)`,
          }}
          className="w-full flex flex-col items-center"
        >
          <PortalSelection isExpanded={sidebarExpanded} />
          {!sidebarExpanded && (
            <>
              <TeamDropdown
                isExpanded={false}
                createTeamDisabled={
                  (isPortal && isGuest) || showCheckoutActions
                }
              />
              <Navigation
                className="mt-2"
                isExpanded={sidebarExpanded}
                style={{
                  height: `calc(100dvh - ${
                    BOTTOM_BUTTON_BLOCK_HEIGHT + HEADER_HEIGHT
                  }px)`,
                }}
                pages={userPages}
                adminPages={adminPages}
                isMoreOpened={displayHiddenFeature}
              />
            </>
          )}
        </div>
        {!disableExpand && (
          <div className="pt-3">
            <div className="flex py-2.5">
              <TooltipButton
                onClick={expandCollapse}
                wrapClassName={cn("w-10 flex justify-center items-center")}
                className="hover:bg-white p-0.5"
                icon={
                  <Icon
                    component={sidebarExpanded ? CloseIcon : OpenIcon}
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    className={"fill-gray-950"}
                  />
                }
                content={
                  sidebarExpanded ? t("hide_main_menu") : t("show_main_menu")
                }
                tooltipClassName="left-full top-1/2 -translate-y-1/2"
              />
            </div>
          </div>
        )}
      </div>

      {sidebarExpanded && (
        <div
          ref={sidebar}
          className={cn(
            "sidebar h-screen mb-4 bg-white transform transition-all duration-200 ease-in-out 2md:border-none border-r border-border-normal overflow-visible",
            sidebarExpanded ? "w-[210px]" : "hidden"
          )}
        >
          {sidebarExpanded && (
            <div style={{ height: HEADER_HEIGHT }}>
              <TeamDropdown
                isExpanded={sidebarExpanded}
                createTeamDisabled={
                  (isPortal && isGuest) || showCheckoutActions
                }
              />
            </div>
          )}
          <Navigation
            isExpanded={sidebarExpanded}
            style={{
              height: `calc(100dvh - ${
                BOTTOM_BUTTON_BLOCK_HEIGHT + HEADER_HEIGHT
              }px)`,
            }}
            pages={userPages}
            adminPages={adminPages}
            isMoreOpened={displayHiddenFeature}
          />
          {sidebarExpanded && user?.stripePlanName && (
            <div className="px-4 py-3">
              <div className="px-3 py-[9px]">
                <div className="text-xs text-typography-hint leading-normal first-letter:uppercase">
                  {planName === PlanNames.NOPLAN
                    ? planName
                    : t("current_plan_name", {
                        planName: t(planName),
                      })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
