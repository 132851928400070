import React from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import Icon from "../Icon";
import TrashIcon from "public/icons/bin_18.svg";
import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import { useImage } from "react-image";
import FilterIcon from "public/icons/filter_24.svg";
import { useEditAsset } from "hooks/useAIDesignAssets";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useQueryClient } from "react-query";
import { useTeam } from "contexts/TeamContext";
import { success } from "utils/toast";
import Tooltip from "../Tooltip";
import HandshakeIcon from "public/icons/handshake.svg";
import BatteryIcon from "public/icons/battery.svg";
import ImageResponsive from "../ImageResponsive";
interface MediaCardProps {
  asset: AIDesignAssetType;
  className?: string;
  onSelect: (asset: AIDesignAssetType) => void;
  onDelete?: (id: number) => void;
  onCreateOrder?: (asset: AIDesignAssetType) => void;
  cardClassName?: string;
  wrapperClassName?: string;
}

const MediaCard: React.FC<MediaCardProps> = ({
  asset,
  onCreateOrder,
  className,
  wrapperClassName,
  cardClassName,
  onSelect,
  onDelete,
}) => {
  const { t } = useTranslation("common");
  const { isMaintainer } = useTeam();
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();

  const { mutate: editAsset } = useEditAsset({
    onError(err) {
      handleError({ err });
    },
    onSuccess(asset: AIDesignAssetType) {
      queryClient.invalidateQueries("ai-design-project-assets");

      success({
        message: asset.isPortalSample
          ? t("share_sample_success")
          : t("unshare_sample_success"),
      });
    },
  });

  const onShare = () => {
    editAsset({
      id: asset.id,
      payload: { isPortalSample: !asset.isPortalSample },
    });
  };

  const { src, isLoading } = useImage({
    srcList: [asset.assetUrl || "", "/images/no-data.png"],
    useSuspense: false,
  });

  return (
    <div
      className={cn(
        "flex flex-row bg-white w-full xxs:max-w-full xs:max-w-fit cursor-pointer",
        className
      )}
    >
      <div className="group relative w-full">
        <div className={cn("xs:w-[216px] xxs:w-full block", wrapperClassName)}>
          <div
            className={cn(
              "flex justify-center w-full items-center p-4 border border-gray-40 rounded-md",
              "active:border-2 active:border-cta-600",
              cardClassName
            )}
            onClick={() => onSelect(asset)}
          >
            {!isLoading ? (
              <ImageResponsive
                url={src as string}
                width={184}
                className="h-full w-auto mx-auto"
              />
            ) : (
              <div className="bg-gray-20 animate-pulse w-[184px] h-[184px]" />
            )}
          </div>
        </div>
        <div
          className={cn(
            "group-hover:block absolute hidden top-2 right-2 z-5 group-active:!hidden hover:group-active:!block",
            asset?.isPortalSample && "!block"
          )}
        >
          <div className="flex">
            {isMaintainer && (
              <Tooltip
                className="!w-content !max-w-[170px]"
                content={
                  asset?.isPortalSample
                    ? t("turn_sample_to_private")
                    : t("share_sample_to_users")
                }
              >
                <button
                  className={cn(
                    "flex ml-1 p-2 hover:bg-indigo-10 bg-white bg-opacity-70 rounded-md active:bg-opacity-20",
                    asset?.isPortalSample && "!block"
                  )}
                  onClick={onShare}
                >
                  <Icon
                    component={HandshakeIcon}
                    viewBox="0 0 640 512"
                    width={18}
                    height={18}
                    className="fill-cta-600"
                  />
                </button>
              </Tooltip>
            )}
            {!!onCreateOrder && !isMaintainer && (
              <Tooltip
                content={t("create_order_with_this_image")}
                className="!w-content !max-w-[150px]"
              >
                <button
                  className="flex ml-1 p-2 hover:bg-indigo-10 bg-white bg-opacity-70 rounded-md active:bg-opacity-20 "
                  onClick={() => onCreateOrder(asset)}
                >
                  <Icon
                    component={BatteryIcon}
                    viewBox="0 0 16 16"
                    width={18}
                    height={18}
                    className="fill-cta-600"
                  />
                </button>
              </Tooltip>
            )}
          </div>
        </div>

        {!!onDelete && (
          <div className="group-hover:block absolute hidden bottom-2 right-2 z-20 hover:group-active:!block">
            <Tooltip content={t("delete_image")}>
              <button
                className="flex ml-1 p-2 hover:bg-indigo-10 bg-white bg-opacity-70 rounded-md active:bg-opacity-20 "
                onClick={() => onDelete(asset.id)}
              >
                <Icon
                  component={TrashIcon}
                  viewBox="0 0 18 18"
                  width={18}
                  height={18}
                  className="fill-cta-600"
                />
              </button>
            </Tooltip>
          </div>
        )}
        {!!asset?.keywordSet?.keywordSet?.length && (
          <Icon
            width={18}
            height={18}
            className="fill-cta-600 absolute top-4 left-4"
            component={FilterIcon}
            viewBox="0 0 24 24"
          />
        )}
      </div>
    </div>
  );
};

export default MediaCard;
