import { useMemo } from "react";
import { useGetSourceMapOne, useGetSourceMapTwo } from "./useGetSourceMap";

const useSourceMap = () => {
  const { data: sourcemapOne } = useGetSourceMapOne();
  const { data: sourcemapTwo } = useGetSourceMapTwo();
  const allSourceMapData = useMemo(
    () => ({ ...sourcemapOne, ...sourcemapTwo }),
    [sourcemapOne, sourcemapTwo]
  );

  return allSourceMapData;
};

export default useSourceMap;
