// https://backend-api-web.picdna.com/api/v1/stats/subcat/percent/daily/latest?nested=True

import { useApi } from "contexts/ApiContext";
import { useQuery, UseQueryOptions } from "react-query";
import { addInfiniteStale } from "utils/addInfiniteStale";

export const useGetFacets = (config?: UseQueryOptions<any, any, any, any>) => {
  const api = useApi();
  return useQuery(
    ["allFacetsNested"],
    () =>
      api.global.query("/stats/subcat/percent/historical/latest", {
        nested: true,
      }),
    addInfiniteStale(config)
  );
};

export const useGetFlatFacets = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["all-facets"],
    () => api.global.query("/stats/subcat/percent/historical/latest"),
    addInfiniteStale(config)
  );
};
