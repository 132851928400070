import ButtonGroup from "components/atoms/ButtonGroup";
import cn from "classnames";
import ProgressList from "components/molecules/ProgressList";
import { AIImageSearchResultType } from "hooks/useAiImageSearch";
import useSourceMap from "hooks/useSourceMap";
import { capitalize, upperCase } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { useMemo, useState } from "react";
import { PriceRangeTitle } from "utils/constants";
import BrandIcon from "public/icons/brand_14.svg";
import ShopIcon from "public/icons/shop_14.svg";
import DollarIcon from "public/icons/dollar_14.svg";
import MaterialIcon from "public/icons/material_14.svg";
import ColorIcon from "public/icons/color_14.svg";
import ExportAnalyticsButton from "components/atoms/Benchmark/ExportAnalyticsButton";

interface IBenchmarkAnalyticsProps {
  benchmarkData?: AIImageSearchResultType;
  filterParams?: any;
}

const BenchmarkAnalytics: React.FC<IBenchmarkAnalyticsProps> = ({
  benchmarkData,
  filterParams,
}) => {
  const { t } = useTranslation("common");
  const [switchValue, setSwitchValue] = useState<string>("brand");
  const allSourceMapData = useSourceMap();

  const { brand, retailer, fabric, colormain } = useMemo(() => {
    return (
      benchmarkData?.statistics?.categorical || {
        brand: {},
        retailer: {},
        fabric: {},
        colormain: {},
      }
    );
  }, [benchmarkData]);
  const price_usd = benchmarkData?.statistics?.numerical?.price_usd || {};

  const lists: { [key: string]: any[] } = useMemo(
    () => ({
      brand: brand
        ? Object.entries(brand)
            .map(([name, value]) => ({
              name,
              rate: Number((value * 100).toFixed(2)),
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
      retailer: retailer
        ? Object.entries(retailer)
            .map(([key, value]) => ({
              name: allSourceMapData[key]?.name || key,
              rate: Number((value * 100).toFixed(2)),
              img: `/icons/retailer/${key}.svg`,
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
      price_range: price_usd
        ? Object.entries(price_usd)
            .filter(([key, value]) => value.c !== 0)
            .map(([name, value]) => ({
              name: t(PriceRangeTitle[name]),
              value: value.c,
              rate: Number((value.p * 100).toFixed(2)),
            }))
        : [],
      material: fabric
        ? Object.entries(fabric)
            .filter(([name]) => name !== "no_value")
            .map(([name, value]) => ({
              name: upperCase(t(name.toLowerCase())),
              rate: Number((value * 100).toFixed(2)),
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
      color: colormain
        ? Object.entries(colormain)
            .filter((color) => color[0].split(",").length === 1)
            .map(([name, value]) => ({
              name: upperCase(t(name.toLowerCase())),
              color: name.toLowerCase(),
              rate: Number((value * 100).toFixed(2)),
            }))
            .sort((a, b) => b.rate - a.rate)
            .filter((item) => item.rate !== 0)
        : [],
    }),
    [benchmarkData]
  );

  return (
    <div className={cn("h-full xxs:pt-6 md:!pt-0")}>
      <div className="flex xxs:flex-wrap xxs:gap-2 md:!gap-0 md:!flex-nowrap items-center justify-between">
        <ButtonGroup
          className="xxs:flex-wrap md:!flex-nowrap"
          options={[
            { label: capitalize(t("brand")), value: "brand", icon: BrandIcon },
            {
              label: capitalize(t("retailer")),
              value: "retailer",
              icon: ShopIcon,
            },
            { label: t("price_range"), value: "price_range", icon: DollarIcon },
            { label: t("material"), value: "material", icon: MaterialIcon },
            { label: capitalize(t("color")), value: "color", icon: ColorIcon },
          ]}
          selected={switchValue}
          onSelect={(v: string) => {
            setSwitchValue(v);
          }}
        />
        {benchmarkData && (
          <ExportAnalyticsButton
            filterParams={filterParams}
            benchmarkData={benchmarkData}
          />
        )}
      </div>
      <div className="rounded-t-2xl h-[calc(90vh-190px)] border border-normal px-4 pt-4 mt-3">
        {lists[switchValue].length > 0 ? (
          <ProgressList
            list={lists[switchValue] || []}
            className="h-[calc(90vh-273px)] overflow-y-scroll !mt-0 pr-4"
            colorTileClassName="rounded-full w-3 h-3"
            listTitleClassName="!text-heading !font-medium !leading-tight !text-sm !border-none !py-0 !pb-3"
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default BenchmarkAnalytics;
