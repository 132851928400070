
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useCallback, useMemo } from "react";
import cn from "classnames";
import { AIDesignAssetType, CreateAssetPayload } from "api/AIDesignAssetsApi";
import Layout from "components/organisms/Layout/Layout";
import { usePortal } from "contexts/PortalContext";
import { useTeam } from "contexts/TeamContext";
import { useUser } from "contexts/UserContext";
import {
  useCreateAsset,
  useCreateManyAssets,
  useDeleteAsset,
  useEditAsset,
  useGetRemainingAssets,
} from "hooks/useAIDesignAssets";
import { Translate } from "next-translate";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { useState } from "react";
import { ISelectValue } from "types";
import Modal from "components/molecules/Modal";
import SharedUserMediaSamples from "components/molecules/Portal/SharedUserMediaSamples";
import withProtectedRoute from "HOCs/withProtectedRoute";
import { useErrorHandler } from "hooks/useErrorHandler";
import { isGuestUser } from "utils/isGuestUser";
import { isViewerUser } from "utils/isViewerUser";
import MediaAssetForm from "components/organisms/AIStreaming/MediaAssetForm";
import ConfirmDialog from "components/organisms/ConfirmDialog";
import { useCreateTvChannel } from "hooks/tvChannels";
import { useCreateProject } from "hooks/aiDesignProjects";
import {
  useAddFolderAsset,
  useCreateAssetFolder,
  useDeleteAssetFolder,
  useEditAssetFolder,
  useGetAssetFolders,
  useGetFolderAssets,
  useRemoveFolderAsset,
} from "hooks/useAssetFolders";
import { error, success } from "utils/toast";
import { ITvGarmentKeyword } from "api/TvChannelsApi";
import AssetsMediaLib from "components/organisms/MediaLib/AssetsMediaLib";
import ShopifyCatalogue from "components/organisms/MediaLib/ShopifyCatalogue";
import BenchmarkModal from "components/organisms/AIDesign/BenchmarkModal";
import { useApi } from "contexts/ApiContext";
import {
  getSegmentationKeywords,
  nomalizeKeywords,
  transformKeywords,
} from "utils/transformKeywords";
import { getAIImageSearch } from "hooks/useAiImageSearch";
import SyncShopifyConnect from "components/atoms/MediaLibrary/SyncShopifyConnect";
import ImagePreview from "components/atoms/MediaLibrary/ImagePreview";
import { MetaDataProps, getServerSideMetaData } from "utils/getServerSideProps";
import { GetServerSideProps } from "next";
import { isRestrictedCustomDomain } from "utils/checkPortal";
import { isArray } from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PageNames } from "utils/constants";
import { getFullFileName } from "utils/getFileInformation";
import isEmpty from "lodash/isEmpty";
import { usePrepareInfiniteData } from "hooks/usePrepareInfiniteData";
import NewFolderDialog from "components/organisms/MediaLib/NewFolderDialog";

export const getImageAvailabilityFilterOptions = (
  t: Translate,
  isMaintainer: boolean
) => {
  const options = [
    { name: t("all_images"), value: {} },
    { name: t("private_images"), value: { "filter.isPortalSample": false } },
    {
      name: t("shared_images"),
      value: { "filter.isPortalSample": !!isMaintainer },
    },
  ];

  return isMaintainer ? options : options.filter((el) => !isEmpty(el.value));
};

const getSortOptions = (t: Translate) => [
  { name: t("modified_date_desc"), value: "updatedat:DESC" },
  { name: t("modified_date_asc"), value: "updatedat:ASC" },
];

const MediaLib: React.FC = () => {
  const { t } = useTranslation("common");
  const api = useApi();
  const { teamId, currentTeam, push, isMaintainer } = useTeam();
  const imageAvailabilityOptions = getImageAvailabilityFilterOptions(
    t,
    !!isMaintainer
  );

  const [isFormOpened, setIsFormOpened] = useState<boolean>(false);
  const [isNewFolderTxtDlg, setIsNewFolderTxtDlg] = useState<boolean>(false);
  const [isOpenBenchmark, setIsOpenBenchmark] = useState<boolean>(false);
  const [isOpenConfirmDialog, setIsConfirmDialog] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<string>("uploaded");
  const [previewImageUrl, setPreviewImageUrl] = useState<string>("");
  const [benchmarkAsset, setBenchmarkAsset] =
    useState<AIDesignAssetType | null>(null);
  const [editCandidate, setEditCandidate] = useState<AIDesignAssetType | null>(
    null
  );
  const [filter, setFilter] = useState<Record<string, ISelectValue | null>>({
    basecat: null,
    gender: null,
    color: null,
  });
  const [sortKeyword, setSortKeyword] = useState<ISelectValue>(
    getSortOptions(t)[0]
  );
  const [imagesAvailabilityFilter, setImagesAvailabilityFilter] =
    useState<ISelectValue>(imageAvailabilityOptions[0]);
  const { user } = useUser();

  const { isPortal, userPortalFetched, urlPortal } = usePortal();
  const isGuest = isGuestUser(currentTeam?.teamRole);
  const isViewer = isViewerUser(currentTeam?.teamRole);
  const { handleError } = useErrorHandler();
  const [currentFolder, setCurrentFolder] = useState<number | null>(null);
  const [tempImages, setTempImages] = useState<File | File[] | null>(null);
  const [benchmarkImgUrl, setBenchmarImgUrl] = useState<string>();

  const onError = (err: any) => handleError({ err });

  const closeForm = () => {
    setIsFormOpened(false);
    setEditCandidate(null);
    setTempImages(null);
    setBenchmarImgUrl("");
  };

  const closeSharedSamples = () => {
    if (!isMaintainer) {
      setImagesAvailabilityFilter(imageAvailabilityOptions[0]);
    }
  };

  const resetState = () => {
    setIsFormOpened(false);
    setEditCandidate(null);
    setTempImages(null);
    setBenchmarImgUrl("");
    closeSharedSamples();
  };

  // get all asset folders
  const {
    data: assetFolders,
    isLoading: isLoadingAssetFolders,
    refetch: refetchAssetFolders,
  } = useGetAssetFolders(teamId, {
    enabled: !!teamId,
  });

  const isFolders = useMemo(
    () => assetFolders?.data && assetFolders?.data?.length > 0,
    [assetFolders]
  );

  // create new asset folder mutation
  const { mutate: createAssetFolder, isLoading: isCreatingAssetFolder } =
    useCreateAssetFolder({
      onSuccess(data: any) {
        refetchAssetFolders();
        setIsNewFolderTxtDlg(false);
        success({ message: "The folder has been created." });
      },
      onError() {
        error({ message: "Folder creation failed!" });
        setIsNewFolderTxtDlg(false);
      },
    });

  // get asset folder according to folder id
  const {
    data: folderAssets,
    isLoading: isLoadingFolderAssets,
    refetch: refetchFolderAssets,
  } = useGetFolderAssets(
    {
      id: currentFolder || 0,
      teamId: teamId,
    },
    {
      enabled: !!currentFolder && !!teamId,
    }
  );

  // add asset to folder
  const { mutate: addAssetToFolder, isLoading: isAddingAssetToFolder } =
    useAddFolderAsset({
      onSuccess() {
        refetchFolderAssets();
        setIsFormOpened(false);
        setTempImages(null);
      },
      onError() {
        error({ message: "The asset cannot be added." });
      },
    });

  // remove asset from folder
  const {
    mutate: removeAssetFromFolder,
    isLoading: isRemovingAssetFromFolder,
  } = useRemoveFolderAsset({
    onSuccess() {
      refetchFolderAssets();
      setIsFormOpened(false);
      setTempImages(null);
    },
  });

  // delete asset folder
  const { mutate: deleteAssetFolder, isLoading: isDeletingAssetFolder } =
    useDeleteAssetFolder({
      onSuccess() {
        refetchFolderAssets();
        refetchAssetFolders();
        success({ message: "The folder has been deleted." });
      },
      onError() {
        error({ message: "The folder cannot be deleted." });
      },
    });

  // edit asset folder
  const { mutate: editAssetFolder } = useEditAssetFolder({
    onSuccess() {
      refetchFolderAssets();
      refetchAssetFolders();
    },
    onError() {
      error({ message: "The folder cannot be edited." });
    },
  });

  // TODO need to refactor everything

  const {
    data: paginationAssets,
    isLoading: isLoadingAssets,
    hasNextPage,
    fetchNextPage,
    refetch: refetchAssets,
    isRefetching: isFetchingAssets,
  } = useGetRemainingAssets(
    teamId,
    {
      ...imagesAvailabilityFilter.value,
      keywordsFilter: filter,
      sortBy: sortKeyword.value,
    },
    {
      keepPreviousData: true,
      enabled:
        !!teamId &&
        (!!user && isPortal ? userPortalFetched : true) &&
        !currentFolder,
    }
  );

  const assets = usePrepareInfiniteData<AIDesignAssetType>(paginationAssets);

  const onSelectFilter = (field: string) => (val: ISelectValue) => {
    setFilter((s) => ({
      ...s,
      [field]: !val.value
        ? null
        : {
            name: val.name,
            value: val.value,
          },
    }));
  };

  const warmBenchmarking = async (payload?: {
    url: string;
    keywords: ITvGarmentKeyword[];
  }) => {
    try {
      const { url, keywords } = payload || {};
      if (url && keywords) {
        const basecat = keywords?.find((el) => el.style === "basecat")?.origin;
        if (basecat) {
          const data = await api.global.query("/stats/cstats/daily/latest", {
            basecat,
          });
          const categoryStats = data?.[0];
          if (categoryStats) {
            const searchParams = {
              categorical_catalog_metadata: {
                catalog_type: ["retailers_brands"],
                ...transformKeywords(keywords),
              },
              segmentation_params: getSegmentationKeywords(keywords),
              request_id: 182947536,
              data_path: url,
              threshold_similarity: 0,
              alpha: 1,
              warming: true,
            };

            await getAIImageSearch(searchParams);
          }
        }
      }
    } catch (err) {
      handleError({ err });
    }
  };

  const onSuccess = (asset?: AIDesignAssetType) => {
    refetchFolderAssets();
    refetchAssets();
    if (asset) {
      warmBenchmarking({
        url: asset?.assetUrl,
        keywords: asset?.keywordSet?.keywordSet,
      });
    }
    resetState();
  };

  const { mutate: deleteAsset } = useDeleteAsset({
    onSuccess,
    onError,
  });

  const { mutate: editAsset, isLoading: isEditing } = useEditAsset({
    onSuccess,
    onError,
  });

  const { mutate: createAsset, isLoading: isCreating } = useCreateAsset({
    onSuccess(data) {
      if (currentFolder && currentFolder > 0) {
        addAssetToFolder(
          {
            assetIds: [data.id],
            assetFolderId: currentFolder,
            teamId: teamId,
          },
          {
            onSuccess: () => {
              success({ message: "The asset has been added!" });
            },
          }
        );
      } else {
        onSuccess(data);
        refetchAssets();
      }
    },
    onError,
  });

  const { mutate: createManyAssets, isLoading: isManyLoading } =
    useCreateManyAssets({
      onSuccess(data) {
        if (currentFolder && currentFolder > 0) {
          addAssetToFolder(
            {
              assetIds: data.map((item: any) => item.id),
              assetFolderId: currentFolder,
              teamId: teamId,
            },
            {
              onSuccess: () => {
                success({ message: "The assets has been added!" });
              },
            }
          );
        } else {
          onSuccess();
        }
      },
      onError,
    });

  const { mutate: createChannel, isLoading: createChannelLoading } =
    useCreateTvChannel({
      onSuccess({ data }: any) {
        push(`/ai-design/${data.tvProjectId}/${data.id}`);
      },
    });

  const { mutate: createProject, isLoading: createProjectLoading } =
    useCreateProject({
      onError(err) {
        setIsConfirmDialog(false);
        handleError({ err });
      },
    });

  const onCreateAiProject = (asset: AIDesignAssetType) => {
    setBenchmarkAsset(asset);
    setIsConfirmDialog(true);
  };

  const handleDeleteAsset = (id: number) => {
    deleteAsset({ id });
  };

  const moveAssetFromFolderToFolder = (assetId: number, folderId: number) => {
    const selectedFolder = assetFolders?.data.find(
      (item) => item.id === folderId
    );

    const selectedAsset = (currentFolder ? folderAssets?.data : assets)?.find(
      (item) => item.id === assetId
    );

    addAssetToFolder(
      {
        assetFolderId: folderId,
        assetIds: [assetId],
        teamId: teamId,
      },
      {
        onSuccess: () => {
          refetchFolderAssets();

          if (currentFolder !== null) {
            const currentFolderData = assetFolders?.data.find(
              (item) => item.id === Number(currentFolder)
            );

            removeAssetFromFolder(
              {
                assetFolderId: currentFolder,
                assetIds: [assetId],
              },
              {
                onSuccess: () => {
                  refetchFolderAssets();
                  success({
                    message: `${
                      selectedAsset?.name ||
                      getFullFileName(selectedAsset?.assetUrl)
                    } has been moved from ${currentFolderData?.name} to ${
                      selectedFolder?.name
                    }!`,
                    contentClassName: "line-clamp-none",
                  });
                },
              }
            );
          } else {
            success({
              message: `${
                selectedAsset?.name || getFullFileName(selectedAsset?.assetUrl)
              } has been moved to ${selectedFolder?.name}!`,
              contentClassName: "line-clamp-none",
            });
            refetchAssets();
          }
        },
      }
    );
  };

  const onSaveAsset = (
    payload: Partial<CreateAssetPayload>,
    onSuccess?: (data: AIDesignAssetType) => void
  ) => {
    if (editCandidate && editCandidate.id) {
      editAsset(
        { id: editCandidate.id, payload },
        {
          onSuccess,
        }
      );
    } else {
      createAsset(payload as CreateAssetPayload);
    }
  };

  const onSaveManyAssets = (payload: CreateAssetPayload[]) => {
    createManyAssets(payload);
  };

  const isAssetFormDisabled = useMemo(() => {
    return (
      (isPortal && !isMaintainer && editCandidate?.isPortalSample) ||
      isViewer ||
      isGuest
    );
  }, [isPortal, editCandidate, isMaintainer, isViewer, isGuest]);

  const handleOpenBenchmark = (asset: AIDesignAssetType) => {
    setBenchmarkAsset(asset);
    setIsOpenBenchmark(true);
  };

  const handleOpenBenchmarkWithImgUrl = (imgUrl: string[0]) => {
    setBenchmarkAsset(null);
    setBenchmarImgUrl(imgUrl[0]);
    setIsOpenBenchmark(true);
  };

  const isAssetsInFolder = useMemo(
    () => folderAssets?.data && folderAssets?.data?.length > 0,
    [folderAssets]
  );

  const isDefaultAssets = useMemo(() => assets && assets?.length > 0, [assets]);

  const filteredAssets = useMemo(() => {
    const assetsData = (
      isFolders && !!currentFolder ? folderAssets?.data : assets
    )?.filter((item) => !item.shopifyId);
    assetsData?.sort((a, b) =>
      sortKeyword?.value === "updatedat:DESC"
        ? new Date(b.updatedat).getTime() - new Date(a.updatedat).getTime()
        : new Date(a.updatedat).getTime() - new Date(b.updatedat).getTime()
    );

    if (assetsData) {
      return assetsData.filter((item) => {
        const keywords: ITvGarmentKeyword[] =
          item?.keywordSet?.keywordSet || [];

        const customKeywords = nomalizeKeywords(keywords);

        const isGenderMatch =
          !filter.gender?.value ||
          filter.gender?.value === customKeywords.gender;
        const isBaseCatMatch =
          !filter.basecat?.value ||
          filter.basecat?.value.replace(" ", "") === customKeywords.basecat;
        const isColorMatch =
          !filter.color?.value || filter.color?.value === customKeywords.color;

        return isGenderMatch && isBaseCatMatch && isColorMatch;
      });
    }

    return [];
  }, [
    folderAssets,
    filter,
    assets,
    assetFolders,
    isFolders,
    sortKeyword?.value,
  ]);

  const shopifyAssets = useMemo(() => {
    const data: { [key: string]: AIDesignAssetType } = {};

    assets?.forEach((item) => {
      if (item.shopifyId) {
        data[item.shopifyId] = item;
      }
    });

    return data;
  }, [assets]);

  const onSelectDefault = useCallback(
    (pageName: string) => {
      if (pageName === PageNames.MEDIA_LIB) {
        setCurrentFolder(null);
      }
    },
    [setCurrentFolder]
  );

  const showSharedSamples =
    imagesAvailabilityFilter?.name === t("shared_images");

  return (
    <Layout onSelectMenu={onSelectDefault}>
      <DndProvider backend={HTML5Backend}>
        {!(
          currentTeam?.shopifyConnectIds &&
          currentTeam.shopifyConnectIds?.length > 0
        ) &&
          !isRestrictedCustomDomain(urlPortal) && (
            <div className="mb-6">
              <SyncShopifyConnect />
            </div>
          )}
        <h1 className="title-100 mb-2 text-lg">{t("library")}</h1>
        {currentTeam?.shopifyConnectIds &&
          currentTeam.shopifyConnectIds?.length > 0 &&
          !isRestrictedCustomDomain(urlPortal) && (
            <div className="flex items-center justify-left gap-6 mb-6">
              <button
                className={cn(
                  "flex items-center justify-start py-2 gap-1 border-b-[1.5px] border-white text-sm text-typography-tab-passive",
                  currentTab === "shopify" && "border-gray-950 text-title"
                )}
                onClick={() => setCurrentTab("shopify")}
              >
                <span>{t("shopify_catalog")}</span>
                <div className="py-0.5 px-2 text-[#F0F0A0] text-xs leading-tight bg-[#3C572E] rounded-[50px]">
                  {t("Synced")}
                </div>
              </button>

              <button
                className={cn(
                  "flex items-center justify-start py-2 border-b-[1.5px] border-white text-sm text-typography-tab-passive",
                  currentTab === "uploaded" && "border-gray-950 text-title"
                )}
                onClick={() => {
                  setCurrentTab("uploaded");
                  setCurrentFolder(null);
                }}
              >
                {t("uploaded")}
              </button>
            </div>
          )}
        {showSharedSamples ? (
          <SharedUserMediaSamples
            setImageAvailabilityFilter={setImagesAvailabilityFilter}
            selectedImageAvailabilityFilter={imagesAvailabilityFilter}
            onSelect={setEditCandidate}
          />
        ) : (
          <div>
            {currentTab === "uploaded" && (
              <AssetsMediaLib
                isLoadingAssetFolders={isLoadingAssetFolders}
                isLoadingFolderAssets={isLoadingFolderAssets || isLoadingAssets}
                assetFolders={assetFolders?.data?.filter(
                  (item) => !item.parentFolderId
                )}
                folderAssets={folderAssets?.data}
                currentFolder={currentFolder}
                setCurrentFolder={setCurrentFolder}
                editAssetFolder={editAssetFolder}
                deleteAssetFolder={deleteAssetFolder}
                filteredAssets={filteredAssets}
                setIsFormOpened={setIsFormOpened}
                isAssetsInFolder={isAssetsInFolder}
                isFolders={isFolders}
                isDefaultAssets={isDefaultAssets}
                setEditCandidate={setEditCandidate}
                handleDeleteAsset={handleDeleteAsset}
                handleOpenBenchmark={handleOpenBenchmark}
                onCreateAiProject={onCreateAiProject}
                filter={filter}
                onSelectFilter={onSelectFilter}
                selectedImageAvailabilityFilter={imagesAvailabilityFilter}
                setImageAvailabilityFilter={setImagesAvailabilityFilter}
                sortKeyword={sortKeyword}
                setSortKeyword={setSortKeyword}
                setIsNewFolderTxtDlg={setIsNewFolderTxtDlg}
                onMoveAsset={moveAssetFromFolderToFolder}
                onOpenImage={(imgUrl: string) => {
                  setPreviewImageUrl(imgUrl);
                  setIsOpenImage(true);
                }}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                refetchAssets={refetchAssets}
                isDeletingAssetFolder={isDeletingAssetFolder}
                refetchFolders={refetchAssetFolders}
              />
            )}
            {currentTab === "shopify" && (
              <ShopifyCatalogue
                handleOpenBenchmark={handleOpenBenchmark}
                handleOpenBenchmarkWithImgUrl={handleOpenBenchmarkWithImgUrl}
                onCreateAiProject={onCreateAiProject}
                onOpenDetails={setEditCandidate}
                shopifyAssets={shopifyAssets}
              />
            )}
          </div>
        )}
      </DndProvider>

      <Modal
        className={cn("xs:max-w-5/6 xxs:max-w-full")}
        contentClassName={
          isArray(tempImages) ? "overflow-y-scroll" : "!overflow-visible"
        }
        onClose={closeForm}
        isOpen={!!editCandidate || isFormOpened}
      >
        <MediaAssetForm
          onSaveMany={onSaveManyAssets}
          onSaveAsset={onSaveAsset}
          onDelete={handleDeleteAsset}
          onBack={closeForm}
          asset={editCandidate}
          saveLoading={isCreating || isEditing || isManyLoading}
          formDisabled={isAssetFormDisabled}
          tempImages={tempImages}
          setTempImages={setTempImages}
          open={!editCandidate && isFormOpened}
          className="max-h-[calc(90vh-96px)]"
        />
      </Modal>
      <BenchmarkModal
        isOpen={isOpenBenchmark}
        onClose={() => setIsOpenBenchmark(false)}
        keywordSet={benchmarkAsset?.keywordSet?.keywordSet}
        image={benchmarkAsset?.assetUrl || benchmarkImgUrl || ""}
        id={
          benchmarkAsset?.id?.toString() ||
          Math.floor(Math.random() * 10000).toString()
        }
      />
      <ConfirmDialog
        className="rounded-2xl"
        title={t("generate_ai_design")}
        subtitle={t("generate_ai_design_description")}
        cancelText={t("stay_on_this_page")}
        cancelVariant="secondary"
        confirmText={t("continue_to_generate")}
        confirmVariant="dark"
        isOpen={isOpenConfirmDialog}
        loading={createChannelLoading || createProjectLoading}
        onClose={() => setIsConfirmDialog(false)}
        onCancel={() => setIsConfirmDialog(false)}
        onConfirm={() => {
          createProject(
            {
              teamId,
              name: benchmarkAsset?.name || "New project",
            },
            {
              onSuccess(project: any) {
                createChannel({
                  tvProjectId: project.id,
                  keywordSet: benchmarkAsset?.keywordSet?.keywordSet || [],
                  imageSample: benchmarkAsset?.assetUrl,
                  name: benchmarkAsset?.name || "New channel",
                });
              },
            }
          );
        }}
      />
      <NewFolderDialog
        isNewFolderTxtDlg={isNewFolderTxtDlg}
        isCreatingAssetFolder={isCreatingAssetFolder}
        setIsNewFolderTxtDlg={setIsNewFolderTxtDlg}
        createAssetFolder={createAssetFolder}
      />
      <ImagePreview
        isOpen={isOpenImage}
        onClose={() => setIsOpenImage(false)}
        imageUrl={previewImageUrl}
      />
    </Layout>
  );
};

 const _getServerSideProps: GetServerSideProps<MetaDataProps> =
  getServerSideMetaData;

export default withProtectedRoute(MediaLib);


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[portalName]/[teamId]/media-lib/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  