import React, { useMemo } from "react";
import cn from "classnames";
import { useQueryClient } from "react-query";
import useTranslation from "next-translate/useTranslation";
// components
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import CustomButton from "../CustomButton";
import SelectButton from "../SelectButton";
// contexts
import { useTeam } from "contexts/TeamContext";
import { usePortal } from "contexts/PortalContext";
// hooks
import { useEditAsset } from "hooks/useAIDesignAssets";
import { useErrorHandler } from "hooks/useErrorHandler";
// utils
import { success } from "utils/toast";
import { isGuestUser } from "utils/isGuestUser";
import { getFileExtension, getFileName } from "utils/getFileInformation";
// apis
import { AIDesignAssetType } from "api/AIDesignAssetsApi";
// icons
import MonitoringIcon from "public/icons/monitoring.svg";
import HandshakeIcon from "public/icons/handshake.svg";
import MoreIcon from "public/icons/more-vertical.svg";
import BenchmarkIcon from "public/icons/benchmark_18.svg";
import PenIcon from "public/icons/pencil_16.svg";
import AIStreammingIcon from "public/icons/ai_generate_16.svg";
import TrashIcon from "public/icons/bin_16.svg";
import MoveToFolder from "public/icons/move_folder_16.svg";
import ShareIcon from "public/icons/share_16.svg";
import GroupUserIcon from "public/icons/group-user_16.svg";
import { useUser } from "contexts/UserContext";
import ImageResponsive from "../ImageResponsive";

type ShopifyAssetsType = {
  id: string;
  assetUrl: string;
  name: string;
};

type MoreButtonType = {
  icon?: any;
  title?: string;
  onClick?: () => void;
  iconProps?: any;
  divider?: boolean;
};

export interface MediaAssetCardProps {
  isActive?: boolean;
  className?: string;
  cardClassName?: string;
  isSelectCard?: boolean;
  disableOrder?: boolean;
  asset?: AIDesignAssetType;
  wrapperClassName?: string;
  onOpenBenchmark?: Function;
  onCreateAiProject?: Function;
  onDelete?: (id: number) => void;
  shopifyAsset?: ShopifyAssetsType;
  onOpenImage?: (url: string) => void;
  onSelect?: (asset: AIDesignAssetType) => void;
  onCreateOrder?: (asset: AIDesignAssetType) => void;
  onMove?: (asset: AIDesignAssetType) => void;
  onCopy?: (asset: AIDesignAssetType) => void;
  onRename?: (asset: AIDesignAssetType) => void;
}

const MediaAssetCard: React.FC<MediaAssetCardProps> = ({
  asset,
  onMove,
  onCopy,
  isActive,
  onSelect,
  onDelete,
  className,
  onOpenImage,
  shopifyAsset,
  cardClassName,
  onCreateOrder,
  onOpenBenchmark,
  wrapperClassName,
  onCreateAiProject,
  isSelectCard = false,
  disableOrder = false,
}) => {
  const { t, lang } = useTranslation("common");
  const { user, isAdmin } = useUser();
  const { currentTeam, isMaintainer, teamId } = useTeam();
  const { handleError } = useErrorHandler();
  const { userPortal } = usePortal();
  const isGuest = isGuestUser(currentTeam?.teamRole);
  const queryClient = useQueryClient();

  const isPortalOwner = useMemo(() => {
    if (userPortal && userPortal.portalOwnerId && user && user.id)
      return user.id !== userPortal.portalOwnerId;
    else return isAdmin;
  }, [user, userPortal, isAdmin]);

  const { mutate: editAsset } = useEditAsset({
    onError(err) {
      handleError({ err });
    },
    onSuccess(asset: AIDesignAssetType) {
      queryClient.invalidateQueries("ai-design-project-assets");
      queryClient.invalidateQueries("ai-design-project-remaining-assets");

      success({
        message: asset.isPortalSample
          ? t("share_sample_success")
          : t("unshare_sample_success"),
      });
    },
  });

  const onShare = () => {
    if (asset) {
      editAsset({
        id: asset?.id,
        payload: { isPortalSample: !asset.isPortalSample },
      });
    }
  };

  const src = asset?.assetUrl || shopifyAsset?.assetUrl;

  const moreButtons = useMemo(() => {
    const items: MoreButtonType[] = [
      {
        icon: BenchmarkIcon,
        title: t("view_benchmark"),
        onClick: () => {
          onOpenBenchmark && onOpenBenchmark(asset || shopifyAsset?.assetUrl);
        },
        iconProps: {
          viewBox: "0 0 18 18",
        },
      },
    ];

    if (asset) {
      items.push({
        icon: PenIcon,
        title: t("edit_details"),
        onClick: () => {
          onSelect && onSelect(asset);
        },
        iconProps: {
          viewBox: "0 0 16 16",
        },
      });
    }
    if (!isGuest) {
      items.push({
        icon: AIStreammingIcon,
        iconProps: {
          viewBox: "0 0 16 16",
        },
        onClick: () => {
          onCreateAiProject && onCreateAiProject(asset || shopifyAsset);
        },
        title: t("generate_ai_design"),
      });
    }

    if (!asset?.copyFromAssetId) {
      items.push({
        divider: true,
      });
    }

    if (asset?.team?.id === teamId && onMove && asset) {
      items.push({
        icon: MoveToFolder,
        iconProps: {
          viewBox: "0 0 16 16",
        },
        onClick: () => {
          onMove(asset);
        },
        title: t("move_file"),
      });
    }

    if (
      asset &&
      asset?.team?.id === teamId &&
      onCopy &&
      !asset?.copyFromAssetId &&
      (isMaintainer || isPortalOwner)
    ) {
      items.push({
        icon: ShareIcon,
        iconProps: {
          viewBox: "0 0 16 16",
          className: "fill-none",
        },
        onClick: () => {
          onCopy(asset);
        },
        title: t("Share"),
      });
    }

    items.push({
      divider: true,
    });

    if (asset && !isGuest) {
      items.push({
        icon: TrashIcon,
        title: t("Delete"),
        onClick: () => {
          onDelete && onDelete(asset.id);
        },
        iconProps: {
          viewBox: "0 0 18 18",
        },
      });
    }

    return items;
  }, [
    onSelect,
    onDelete,
    onMove,
    onCreateAiProject,
    onOpenBenchmark,
    isMaintainer,
    isPortalOwner,
    asset?.copyFromAssetId,
  ]);

  const isSharedWithMe = useMemo(() => {
    if (asset?.team?.id !== teamId) {
      const findAssets = asset?.copies?.find((item) => item.team.id === teamId);
      return !!findAssets;
    }
    return false;
  }, [asset, teamId]);

  return (
    <div
      className={cn(
        "flex flex-row bg-white !w-full cursor-pointer group",
        className
      )}
      onClick={() => {
        if (isSelectCard && onSelect && asset) onSelect(asset);
      }}
    >
      <div className="group relative w-full">
        <div
          className={cn(
            "w-full block rounded-2xl border border-border-normal group-hover:bg-gray-20",
            isActive && "!border-gray-950",
            wrapperClassName
          )}
        >
          <div
            className={cn(
              "flex justify-center items-center p-4 rounded-xl",
              "w-full h-[180px]",
              cardClassName
            )}
            onClick={() => {
              if (src && onOpenImage) {
                onOpenImage(src);
              }
            }}
          >
            <ImageResponsive
              url={src}
              className="h-full w-full max-w-[184px]"
            />
          </div>
          <div className="pt-3 p-4 flex items-center justify-between">
            <div className="max-w-[70%]">
              <div className="text-sm font-normal w-full truncate leading-tight text-title h-[18px]">
                {asset?.name ||
                  shopifyAsset?.name ||
                  getFileName(asset?.assetUrl || shopifyAsset?.assetUrl)}
              </div>
              <div className="text-xxs leading-tight text-typography-tab-subtle">
                {getFileExtension(
                  asset?.assetUrl || shopifyAsset?.assetUrl
                ).toUpperCase()}{" "}
                •{" "}
                {lang !== "cn"
                  ? formatDate(asset?.updatedat || "")
                  : t("year_month_day", {
                      year: new Date(asset?.updatedat || "").getFullYear(),
                      month: new Date(asset?.updatedat || "").getMonth() + 1,
                      day: new Date(asset?.updatedat || "").getDate(),
                    })}
              </div>
            </div>
            <div>
              {isSelectCard ? (
                <div className="w-[34px] h-[34px]">
                  <CustomButton
                    icon={TrashIcon}
                    viewBox="0 0 16 16"
                    iconClassName="!fill-gray-950"
                    className=" group-hover:!block xxs:block xs:hidden border-0 hover:border-0 active:border-0 !bg-gray-30 active:!bg-gray-40 hover:!bg-gray-40 !p-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (asset && onDelete) {
                        onDelete(asset.id);
                      }
                    }}
                  />
                </div>
              ) : (
                <SelectButton
                  icon={MoreIcon}
                  options={moreButtons}
                  iconClassName="rotate-90"
                  buttonClassName="border-0 hover:border-0 active:border-0 bg-gray-30 active:bg-gray-40 hover:bg-gray-40 xxs:visible xs:invisible group-hover:!visible"
                  listClassName="min-w-[165px] gap-0"
                  disabledTooltip
                />
              )}
            </div>
          </div>
        </div>
        <div className={cn("absolute !block top-2 right-2 z-5")}>
          <div className="flex">
            {isMaintainer && (
              <div
                className={cn(
                  "hidden group-hover:!block",
                  asset?.isPortalSample && "!block"
                )}
              >
                <Tooltip
                  className={cn("!w-content !max-w-fit ")}
                  content={
                    asset?.isPortalSample
                      ? t("turn_sample_to_private")
                      : t("share_sample_to_users")
                  }
                >
                  <button
                    className={cn(
                      "flex ml-1 p-2 hover:bg-gray-10 bg-white bg-opacity-70 rounded-md active:bg-opacity-20",
                      asset?.isPortalSample && "!block"
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      onShare();
                    }}
                  >
                    <Icon
                      component={HandshakeIcon}
                      viewBox="0 0 640 512"
                      width={18}
                      height={18}
                      className="fill-gray-950"
                    />
                  </button>
                </Tooltip>
              </div>
            )}
            {disableOrder && asset && !!onCreateOrder && !isMaintainer && (
              <Tooltip
                content={t("create_order_with_this_image")}
                className="!w-content !max-w-[150px]"
              >
                <button
                  className="flex ml-1 p-2 hover:bg-gray-10 bg-white bg-opacity-70 rounded-md active:bg-opacity-20 "
                  onClick={(e) => {
                    e.stopPropagation();
                    onCreateOrder(asset);
                  }}
                >
                  <Icon
                    component={MonitoringIcon}
                    width={18}
                    height={18}
                    className="fill-gray-950"
                  />
                </button>
              </Tooltip>
            )}
            {(!!asset?.copies?.length || !!asset?.copyFromAssetId) && (
              <Tooltip
                arrowHidden
                content={
                  !!asset?.copyFromAssetId
                    ? t("shared_with_me")
                    : t("shared_with_others")
                }
                className="!bg-gray-90"
              >
                <button className="flex ml-1 p-2 hover:bg-gray-10 bg-white bg-opacity-70 rounded-md active:bg-opacity-20 ">
                  <Icon
                    component={GroupUserIcon}
                    viewBox="0 0 16 16"
                    width={16}
                    height={16}
                  />
                </button>
              </Tooltip>
            )}
          </div>
        </div>

        {/* {!!onDelete && (
          <div className="group-hover:block absolute hidden bottom-2 right-2 z-20 hover:group-active:!block">
            <Tooltip content={t("delete_image")}>
              <button
                className="flex ml-1 p-2 hover:bg-indigo-10 bg-white bg-opacity-70 rounded-md active:bg-opacity-20 "
                onClick={() => onDelete(asset.id)}
              >
                <Icon
                  component={TrashIcon}
                  viewBox="0 0 18 18"
                  width={18}
                  height={18}
                  className="fill-cta-600"
                />
              </button>
            </Tooltip>
          </div>
        )} */}
        {/* {!!asset?.keywordSet?.keywordSet?.length && (
          <Icon
            width={18}
            height={18}
            className="fill-cta-600 absolute top-4 left-4"
            component={FilterIcon}
            viewBox="0 0 24 24"
          />
        )} */}
      </div>
    </div>
  );
};

export default MediaAssetCard;

function formatDate(dateString: string) {
  const options: any = { month: "short", day: "numeric", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}
