import React from "react";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";
// models
import TooltipButton from "components/atoms/TooltipButton";
import Icon from "components/atoms/Icon";
// icons
import ChevronLeftIcon from "public/icons/chevron_left_18.svg";
import CubeIcon from "public/icons/cube_18.svg";
import BrushIcon from "public/icons/brush_18.svg";
import AddMask from "components/molecules/AIStreaming/AddMask";
import AddInPainting from "components/molecules/AIStreaming/AddInPainting";
import { PaintDefaultColor } from "utils/constants";
import { useUser } from "contexts/UserContext";
import Tooltip from "components/atoms/Tooltip";
import AddImageView from "./AddImageView";
import { UseMutationOptions } from "react-query";
interface ToolButtonProps {
  active?: boolean;
  onClick: () => void;
  buttonName: string;
  className?: string;
  icon: any;
  disabled?: boolean;
  tip?: string;
}

const ToolButton = ({
  active = false,
  onClick,
  buttonName,
  className,
  icon,
  disabled,
  tip,
}: ToolButtonProps) => {
  return (
    <Tooltip className="!p-2 !w-fit" disabled={!tip} content={tip || ""}>
      <button
        disabled={disabled}
        className={cn(
          "w-full flex gap-1 justify-center items-center whitespace-nowrap border border-gray-40 hover:border-cta-500 py-[11px] disabled:bg-gray-20 disabled:hover:border-gray-40",
          className,
          active && "border-cta-600 bg-indigo-10"
        )}
        onClick={onClick}
      >
        <Icon
          component={icon}
          viewBox="0 0 18 18"
          className={cn("fill-gray-70", active && "!fill-cta-600")}
        />
        <h6
          className={cn(
            "text-sm leading-tight font-medium text-subtitle1",
            active && "!text-cta-600"
          )}
        >
          {buttonName}
        </h6>
      </button>
    </Tooltip>
  );
};

export enum PaintingToolValues {
  AddMask = 1,
  AddInPainting = 2,
  AddLayer = 3,
  ChangeTextilePattern = 4,
  ChangeWAPattern = 5,
}

interface PaintingToolsType {
  onBack: () => void;
  lineColor: string;
  lineWidth: number;
  prompts: string[];
  selectedMode: number;
  canvasDirty: boolean;
  generating: boolean;
  onChangeLineColor: (value: string) => void;
  onChangeLineWidth: (value: number) => void;
  onChangePrompts: (value: string[]) => void;
  onChangeSelectedMode: (value: number) => void;
  onResetSettings: (color: string) => void;
  onUploadImage: Function;
  onGenerate: () => void;
  layerGenerateDisabled?: boolean;
  uploadLayerImage: (
    data: FormData,
    config?: UseMutationOptions<any, any, any>
  ) => void;
  uploading?: boolean;
}

const PaintingTools = ({
  onBack,
  lineColor,
  lineWidth,
  prompts,
  selectedMode,
  canvasDirty,
  generating,
  onChangeLineColor,
  onChangeLineWidth,
  onChangePrompts,
  onChangeSelectedMode,
  onResetSettings,
  onUploadImage,
  onGenerate,
  layerGenerateDisabled,
  uploadLayerImage,
  uploading,
}: PaintingToolsType) => {
  const { user } = useUser();
  const { t } = useTranslation("common");

  return (
    <div className="relative h-auto pb-8">
      <div className="flex items-center gap-2">
        <div>
          <TooltipButton
            icon={
              <Icon
                component={ChevronLeftIcon}
                width={18}
                height={18}
                viewBox="0 0 18 18"
                className="fill-cta-600"
              />
            }
            tooltipClassName="top-[42px] !hidden"
            onClick={onBack}
            content={""}
          />
        </div>
        <h1 className="text-lg leading-tight font-bold text-title">
          {t("edit_image")}
        </h1>
      </div>
      <div className="mt-6">
        <div className="flex w-full rounded-md">
          <div className="w-1/3">
            <ToolButton
              icon={CubeIcon}
              tip={!user?.isAIMaskingEnabled ? t("ai_masking_tip") : ""}
              buttonName={t("add_mask")}
              onClick={() => {
                onChangeSelectedMode(PaintingToolValues.AddMask);
                onResetSettings(PaintDefaultColor.MaskColor);
              }}
              active={selectedMode === PaintingToolValues.AddMask}
              className={"rounded-l-md"}
              disabled={!user?.isAIMaskingEnabled}
            />
          </div>
          <div className="w-1/3">
            <ToolButton
              icon={BrushIcon}
              buttonName={t("add_inpainting")}
              tip={!user?.isAIInPaintingEnabled ? t("ai_inpainting_tip") : ""}
              onClick={() => {
                onChangeSelectedMode(PaintingToolValues.AddInPainting);
                onResetSettings(PaintDefaultColor.BrushColor);
              }}
              active={selectedMode === PaintingToolValues.AddInPainting}
              disabled={!user?.isAIInPaintingEnabled}
            />
          </div>
          <div className="w-1/3">
            <ToolButton
              disabled
              tip={t("under_dev")}
              icon={BrushIcon}
              buttonName={t("layers")}
              className={"rounded-r-md"}
              onClick={() => {
                onChangeSelectedMode(PaintingToolValues.AddLayer);
                onResetSettings(PaintDefaultColor.MaskColor);
              }}
              active={selectedMode === PaintingToolValues.AddLayer}
            />
          </div>
        </div>
      </div>
      <div className="mt-6">
        {selectedMode === PaintingToolValues.AddMask && (
          <AddMask
            lineWidth={lineWidth}
            prompts={prompts}
            canvasDirty={canvasDirty}
            generating={generating}
            onChangeLineWidth={onChangeLineWidth}
            onResetMask={onResetSettings}
            onChangePrompts={onChangePrompts}
            onUploadImage={onUploadImage}
            onGenerate={onGenerate}
          />
        )}
        {selectedMode === PaintingToolValues.AddInPainting && (
          <AddInPainting
            lineColor={lineColor}
            lineWidth={lineWidth}
            generating={generating}
            onChangeLineColor={onChangeLineColor}
            onChangeLineWidth={onChangeLineWidth}
            onGenerate={onGenerate}
          />
        )}
        {selectedMode === PaintingToolValues.AddLayer && (
          <AddImageView
            generateDisabled={layerGenerateDisabled}
            onGenerate={onGenerate}
            uploadLayerImage={uploadLayerImage}
            loading={uploading}
          />
        )}
      </div>
    </div>
  );
};

export default PaintingTools;
