import cn from "classnames";
import Icon from "./Icon";

type ButtonGroupType = {
  options: {
    label: string;
    value: string;
    icon?: any;
    viewBox?: string;
  }[];
  selected: string;
  onSelect: (v: string) => void;
  className?: string;
};

const ButtonGroup = ({
  options,
  selected,
  onSelect,
  className,
}: ButtonGroupType) => {
  return (
    <div className={cn("flex items-center justify-start gap-2", className)}>
      {options.map((item, i) => (
        <button
          key={i}
          type="button"
          className={cn(
            "px-3 py-1.5 text-xs font-medium text-subtitle1 bg-transparent border border-gray-40 hover:bg-gray-10 hover:text-typography-headline rounded-[100px]",
            selected === item.value &&
              "text-xs font-medium border-black !bg-gray-950 !text-white"
          )}
          onClick={() => onSelect(item.value)}
        >
          <div className="flex items-center justify-start gap-1">
            {item.icon && (
              <Icon
                component={item.icon}
                viewBox={item.viewBox || "0 0 14 14"}
                width={14}
                height={14}
                className={cn(
                  "!fill-gray-950",
                  selected === item.value && "!fill-white"
                )}
              />
            )}
            <span>{item.label}</span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
