import { usePortal } from "contexts/PortalContext";
import { useTeam } from "contexts/TeamContext";
import { useUser } from "contexts/UserContext";
import { useGetUserPortals } from "hooks/portal";
import { usePrepareInfiniteData } from "hooks/usePrepareInfiniteData";
import Portal from "models/Portal";
import React, { useEffect, useMemo } from "react";
import cn from "classnames";
import { DEFAULT_PORTAL_NAME, JELLI_NAME } from "utils/constants";
import { useRouter } from "next/router";
import { useQueryClient } from "react-query";
import Tooltip from "components/atoms/Tooltip";
import BigSpinner from "components/atoms/BigSpinner";
import { isOwnerUser } from "utils/isOwnerUser";
import { isTeamAdminUser } from "utils/isTeamAdmin";
import useTranslation from "next-translate/useTranslation";
import Icon from "components/atoms/Icon";
import PlusIcon from "public/icons/plus_18.svg";
import JelliStudioIcon from "public/icons/jellistudio_28x28.svg";
import Dropdown from "components/atoms/DropDown";
import Avatar from "components/atoms/Avatar";
import CheckIcon from "public/icons/check_16.svg";
import { useBreakpoint } from "contexts/BreakpointContext";
import ImageResponsive from "components/atoms/ImageResponsive";

interface IPortalSelectionProps {
  createDisabled?: boolean;
  isExpanded: boolean;
}

const PortalView: React.FC<{
  logo?: string | null;
  onClick: () => void;
  name: string;
  active: boolean;
}> = ({ logo, onClick, name, active }) => {
  return (
    <Tooltip content={name} className="!w-fit !p-2">
      <div
        onClick={onClick}
        className={cn("w-7 h-7 cursor-pointer rounded-md")}
      >
        {name === JELLI_NAME ? (
          <div className={cn(active && "ring-4 ring-gray-45 rounded-md")}>
            <Icon
              component={JelliStudioIcon}
              viewBox="0 0 28 28"
              width={28}
              height={28}
            />
          </div>
        ) : logo ? (
          <ImageResponsive
            url={logo}
            className={cn(
              "w-full h-full rounded-md object-cover",
              active && "ring-4 ring-gray-45"
            )}
          />
        ) : (
          <div
            className={cn(
              "bg-gray-70 rounded-md w-full h-full p-1.5 flex items-center justify-center",
              active && "ring-4 ring-gray-45"
            )}
          >
            <span className="text-sm text-white font-bold uppercase">
              {name?.charAt(0)}
            </span>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

const PortalSelection: React.FC<IPortalSelectionProps> = ({
  createDisabled,
  isExpanded,
}) => {
  const { t } = useTranslation("common");
  const { user, hasAbilityToOwnPortal } = useUser();
  const { currentTeam, isMaintainer, refetchTeam } = useTeam();
  const { isPortal, userPortal, isLoading, portalName } = usePortal();
  const router = useRouter();
  const queryClient = useQueryClient();
  const isOwner = isOwnerUser(currentTeam?.teamRole);
  const isTeamAdmin = isTeamAdminUser(currentTeam?.teamRole);
  const canCreatePortal = currentTeam?.limits?.ownPortal;
  const { xxs } = useBreakpoint();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: portalsLoading,
  } = useGetUserPortals(
    {
      "filter.isPortalSwitcherEnabled":
        isPortal && isMaintainer ? undefined : true,
    },
    {
      enabled: !!user,
      keepPreviousData: true,
    }
  );

  const portals = usePrepareInfiniteData<Portal>(data);

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage]);

  const onSelect = async (portalUrl: string) => {
    await refetchTeam();
    router.push(`/${portalUrl}`);
    queryClient.invalidateQueries("currentTeam");
    queryClient.removeQueries("user-portal");
  };

  const sortedOptions: Array<{
    name: string;
    onClick: () => void;
    logo?: string | null;
    id?: number;
  }> = useMemo(() => {
    const mainItem = {
      name: JELLI_NAME,
      onClick: () => onSelect(DEFAULT_PORTAL_NAME),
      logo: "/images/jellistudio_28x28.svg" as string,
    };
    if (isPortal) {
      if (userPortal) {
        const currentPortalItem = {
          logo: userPortal.logo,
          onClick: () =>
            onSelect((userPortal.portalUrlSlug || userPortal.domain) as string),
          name: userPortal.supplier?.name,
          id: userPortal.id,
        };

        if (!userPortal.isPortalSwitcherEnabled) {
          return [currentPortalItem];
        }

        if (portals?.length) {
          return [
            currentPortalItem,
            mainItem,
            ...portals
              .filter((el) => el.id !== userPortal.id)
              .map((el) => ({
                logo: el.logo,
                name: el.supplier.name,
                id: el.id,
                onClick: () =>
                  onSelect((el.portalUrlSlug || el.domain) as string),
              })),
          ];
        }

        return [];
      }
    } else {
      let items = [mainItem];

      if (portals?.length) {
        items = items.concat(
          ...portals.map(
            (el) =>
              ({
                logo: el.logo,
                name: el.supplier.name,
                id: el.id,
                onClick: () =>
                  onSelect((el.portalUrlSlug || el.domain) as string),
              } as any)
          )
        );
      }

      return items;
    }

    return [];
  }, [isPortal, userPortal, portals]);

  const onCreatePortal = () => {
    if (
      (hasAbilityToOwnPortal && isOwner) ||
      (canCreatePortal && isTeamAdmin)
    ) {
      router.push(`/${portalName}/portal/details`);
    } else {
      router.push(`/${portalName}/profile/subscription`);
    }
  };

  const showCreateButton =
    ((hasAbilityToOwnPortal && isOwner) || (canCreatePortal && isTeamAdmin)) &&
    !createDisabled &&
    !xxs;

  return (
    <div className="relative ">
      <div className="gap-5 flex flex-col">
        {portalsLoading || isLoading ? (
          <BigSpinner className="w-7 h-7 mb-5" />
        ) : (
          <>
            {isExpanded ? (
              <>
                {sortedOptions?.map((el, index) => (
                  <PortalView
                    active={
                      userPortal
                        ? el.id === userPortal.id
                        : el.name === JELLI_NAME
                    }
                    key={el.name + index.toString()}
                    {...el}
                  />
                ))}
                {showCreateButton && (
                  <button onClick={onCreatePortal} className="flex self-center">
                    <Tooltip
                      className="!w-fit !p-2"
                      content={t("create_workspace")}
                    >
                      <Icon
                        component={PlusIcon}
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        className="fill-gray-950"
                      />
                    </Tooltip>
                  </button>
                )}
              </>
            ) : (
              <Dropdown
                dropdownClassname="left-0 w-fit"
                trigger={
                  <div className="mb-5">
                    <PortalView
                      name={
                        (isPortal
                          ? userPortal?.supplier?.name
                          : JELLI_NAME) as string
                      }
                      logo={userPortal?.logo}
                      onClick={() => {}}
                      active
                    />
                  </div>
                }
                items={sortedOptions}
                itemComponent={({ item }) => (
                  <div
                    onClick={item.onClick}
                    className="flex items-center cursor-pointer p-2 hover:bg-gray-20 rounded-md"
                  >
                    <Avatar
                      fullName={item.name}
                      image={item.logo}
                      className="!w-7 !h-7 shrink-0 mr-2 bg-gray-50 text-gray-950"
                    />
                    <div className="w-full">
                      <Tooltip className="!w-fit !p-2" content={item.name}>
                        <p className="whitespace-nowrap overflow-hidden text-ellipsis body-100">
                          {item.name}
                        </p>
                      </Tooltip>
                    </div>
                    <div className="shrink-0 w-4 ml-2">
                      {isPortal
                        ? userPortal?.id === item.id
                        : item.name === JELLI_NAME && (
                            <Icon
                              viewBox="0 0 16 17"
                              width={16}
                              height={16}
                              className="!stroke-gray-950"
                              component={CheckIcon}
                            />
                          )}
                    </div>
                  </div>
                )}
                footer={
                  showCreateButton ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onCreatePortal();
                      }}
                      className={cn(
                        "border-t mt-1 py-1 group cursor-pointer border-border-normal sticky -bottom-[1px] bg-white z-50"
                      )}
                    >
                      <div
                        className={cn(
                          "group-hover:bg-gray-20 whitespace-nowrap px-3 py-2 group-1 body-100 flex justify-start items-center rounded-md gap-3"
                        )}
                      >
                        <Icon
                          component={PlusIcon}
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          className="fill-gray-950"
                        />
                        {t("create_workspace")}
                      </div>
                    </div>
                  ) : null
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PortalSelection;
