import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import BrandOptionCard from "components/atoms/Benchmark/AddNewBrand/BrandOptionCard";
import BigSpinner from "components/atoms/BigSpinner";
import CustomButton from "components/atoms/CustomButton";
import Icon from "components/atoms/Icon";
import Input from "components/atoms/Input";
import NoTableData from "components/atoms/NoTableData";
import ShopifyCatalogueTable from "components/molecules/MediaLibrary/ShopifyCatalogueTable";
import { useApi } from "contexts/ApiContext";
import { useTeam } from "contexts/TeamContext";
import {
  useGetShopifyAvailalbeFilterDates,
  useGetShopifyProducts,
} from "hooks/useShopify";
import useTranslation from "next-translate/useTranslation";
import ShopifyIcon from "public/icons/shopify-logo_35.svg";
import { useEffect, useMemo, useState } from "react";
import { isOwnerUser } from "utils/isOwnerUser";
import { isTeamAdminUser } from "utils/isTeamAdmin";

interface ShopifyCatalogueProps {
  handleOpenBenchmark: Function;
  handleOpenBenchmarkWithImgUrl: Function;
  onCreateAiProject: Function;
  onOpenDetails: Function;
  shopifyAssets: { [key: string]: AIDesignAssetType };
}

const ShopifyCatalogue = ({
  handleOpenBenchmark,
  handleOpenBenchmarkWithImgUrl,
  onCreateAiProject,
  onOpenDetails,
  shopifyAssets,
}: ShopifyCatalogueProps) => {
  const { t } = useTranslation("common");
  const [inputValue, setInputValue] = useState("");
  const [step, setStep] = useState<number>(0);
  const { currentTeam, refetchTeam } = useTeam();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");

  const { teams } = useApi();

  const shopifyConnectId =
    (currentTeam?.shopifyConnectIds && currentTeam?.shopifyConnectIds[0]) || "";

  const { data: date, isLoading: isLoadingDate } =
    useGetShopifyAvailalbeFilterDates(shopifyConnectId, {
      enabled: !!shopifyConnectId,
    });

  const { data, isLoading: isLoadingProducts } = useGetShopifyProducts(
    { id: shopifyConnectId, date: date?.[date.length - 1] },
    { enabled: !!shopifyConnectId && !!date && date.length > 0 }
  );

  const filteredTableData = useMemo(() => {
    if (data?.products) {
      return data?.products?.filter(
        (item: any) =>
          !keyword ||
          item?.vendor?.toLowerCase()?.includes(keyword.toLowerCase()) ||
          item?.title?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
          item?.createdAt?.toLowerCase()?.includes(keyword?.toLowerCase())
        // !!(item?.tags as string[])?.find((item: string) =>
        //   item?.toLowerCase()?.includes(keyword?.toLowerCase())
        // )
      );
    } else {
      return [];
    }
  }, [data?.products, keyword]);

  useEffect(() => {
    setStep(0);
  }, [filteredTableData]);

  if (isLoadingDate || isLoadingProducts)
    return (
      <div className="w-full h-[300px] flex items-center justify-center">
        <BigSpinner />
      </div>
    );

  const onRegisterShopifyId = () => {
    if (currentTeam?.id && inputValue.length > 0) {
      setIsSaving(true);
      teams
        .update({
          teamId: currentTeam?.id,
          teamPart: {
            shopifyConnectIds: [inputValue],
          },
        })
        .then(() => {
          setIsSaving(false);
          refetchTeam();
        });
    }
  };

  return shopifyConnectId ? (
    data?.products && data?.products?.length > 0 ? (
      <div className="pb-10">
        <div className="flex items-center justify-between">
          <div className="text-sm leading-tight font-medium text-typography-headline">
            {t("shopify_catalog")}
          </div>
          <Input
            placeholder="Search keyword..."
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            containerClassName="!pb-0"
          />
        </div>
        <ShopifyCatalogueTable
          tableData={filteredTableData
            ?.slice(step * 10, (step + 1) * 10)
            ?.map((item: any) => ({
              id: getShopifyId(item.id),
              image: [
                item?.featuredImage?.src,
                ...(item?.images?.nodes
                  ?.filter((item: any) => !!item.originalSrc)
                  ?.map((item: any) => item.originalSrc) || []),
              ],
              brandName: item?.vendor,
              productName: item?.title,
              details: shopifyAssets[Number(getShopifyId(item.id))],
              uploadedDate: item?.createdAt,
            }))}
          handleOpenBenchmark={handleOpenBenchmark}
          handleOpenBenchmarkWithImgUrl={handleOpenBenchmarkWithImgUrl}
          onCreateAiProject={onCreateAiProject}
          onOpenDetails={onOpenDetails}
          step={step}
          setStep={setStep}
          totalDataLength={filteredTableData?.length || 0}
        />
      </div>
    ) : (
      <div className="flex items-center justify-center h-[400px]">
        <NoTableData />
      </div>
    )
  ) : isTeamAdminUser(currentTeam?.teamRole) ||
    isOwnerUser(currentTeam?.teamRole) ? (
    <div className="w-full flex items-center justify-center h-[330px]">
      <BrandOptionCard
        icon={
          <Icon
            component={ShopifyIcon}
            viewBox="0 0 35 35"
            width={35}
            height={35}
          />
        }
        title={t("install_jellistudio_shopify_plugin")}
        subTitle={t("sign_in_to_your_shopify_and_install_jellistudio_plugin")}
        className="max-w-[530px]"
        onClick={() =>
          window.open("https://apps.shopify.com/jellibeans-connect", "_blank")
        }
      >
        <div className="flex items-center justify-between gap-4 h-10 my-10">
          <input
            type="text"
            className="w-full rounded-md text-center border-border-normal border h-full"
            placeholder={t("Input your Shopify ID")}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <CustomButton
            label={t("register")}
            className="flex-none"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              onRegisterShopifyId();
            }}
            isLoading={isSaving}
          />
        </div>
      </BrandOptionCard>
    </div>
  ) : (
    <div className="w-full h-[300px] flex items-center justify-center">
      <p className="max-w-[600px]">
        Shopify ID registration for this team has not been completed by the
        owner. Please reach out to the team owner or administrator to proceed
        with the registration.
      </p>
    </div>
  );
};

export default ShopifyCatalogue;

export const getShopifyId = (id: string) =>
  id?.split("/")[id?.split("/").length - 1] || "";
