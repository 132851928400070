import Input from "components/atoms/Input";
import ConfirmDialog from "components/organisms/ConfirmDialog";
import { useTeam } from "contexts/TeamContext";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";

interface NewFolderDialogProps {
  isNewFolderTxtDlg: boolean;
  isCreatingAssetFolder: boolean;
  setIsNewFolderTxtDlg: (value: boolean) => void;
  createAssetFolder: (data: { name: string; teamId: number }) => void;
}

const NewFolderDialog: React.FC<NewFolderDialogProps> = ({
  isCreatingAssetFolder,
  isNewFolderTxtDlg,
  setIsNewFolderTxtDlg,
  createAssetFolder,
}) => {
  const { t } = useTranslation("common");
  const { teamId } = useTeam();
  const [newFolderText, setNewFolderText] = useState<string>("");
  const [addNewFolderTextError, setAddNewFolderTextError] =
    useState<boolean>(false);

  return (
    <ConfirmDialog
      className="rounded-2xl !max-w-[500px] !w-full"
      overlayClassName="z-[100]"
      title={t("new_folder")}
      cancelText={t("cancel")}
      cancelVariant="secondary"
      confirmText={t("Create")}
      confirmVariant="dark"
      isOpen={isNewFolderTxtDlg}
      loading={isCreatingAssetFolder}
      onClose={() => setIsNewFolderTxtDlg(false)}
      onCancel={() => setIsNewFolderTxtDlg(false)}
      onConfirm={() => {
        if (newFolderText.length > 0) {
          createAssetFolder({
            name: newFolderText,
            teamId: teamId,
          });
        } else {
          setAddNewFolderTextError(true);
        }
      }}
    >
      <Input
        type="text"
        value={newFolderText}
        className="w-full"
        containerClassName={addNewFolderTextError ? "!pb-1" : ""}
        onChange={(e) => {
          setAddNewFolderTextError(false);
          setNewFolderText(e.target.value);
        }}
      />
      {addNewFolderTextError && (
        <div className="text-red-50 text-xm pb-3">
          Please enter a new folder name.
        </div>
      )}
    </ConfirmDialog>
  );
};

export default NewFolderDialog;
