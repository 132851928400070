import React from "react";
import Modal, { IModalProps } from "./Modal";
import { useQuery } from "react-query";
import { getChangelog } from "getChangelog";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import CtaButton from "components/atoms/CtaButton";
import cn from "classnames";

const MAX_ROWS = 5;

const isUpdatesHasMoreThanFiveRows = (
  updates: Array<{
    title: string;
    items: string[];
  }>
) => {
  let counter = 0;

  updates.forEach((el) => {
    // + 1 is title row
    counter += el.items.length + 1;
  });

  return counter > MAX_ROWS;
};

interface IChangelogItemProps {
  item: {
    version: string;
    updates: Array<{
      title: string;
      items: string[];
    }>;
  };
}

const ChangelogItem: React.FC<IChangelogItemProps> = ({ item }) => {
  const { t } = useTranslation("common");
  const [isExpanded, setIsExpanded] = useState<boolean>(
    () => !isUpdatesHasMoreThanFiveRows(item.updates)
  );

  const { version, updates } = item;

  const [varsionNumber, versionDate] = version.split(", ");

  return (
    <div className="py-4 px-6 border border-border-normal rounded-2xl mb-4">
      <div className="flex justify-between items-center mb-2 w-full">
        <h4 className="headline-400">{varsionNumber}</h4>
        <p className="body-100-subtle text-xs">{versionDate}</p>
      </div>
      {!!updates.length &&
        updates.slice(0, isExpanded ? undefined : 1).map((el, i) => {
          return (
            <div
              className={cn("mb-0", isExpanded && "!mb-6 last:!mb-0")}
              key={el.title + i}
            >
              <p className="body-100">{el.title}</p>
              <ul className="list-disc list-inside pl-2">
                {el.items
                  .slice(0, isExpanded ? undefined : 4)
                  .map((item, i) => (
                    <li key={item + i} className="body-100-subtle">
                      <span>{item}</span>
                    </li>
                  ))}
              </ul>
            </div>
          );
        })}
      {!isExpanded && (
        <CtaButton
          className="!mt-2 text-xs"
          onClick={() => setIsExpanded(true)}
          variant="link"
        >
          {t("More")}
        </CtaButton>
      )}
    </div>
  );
};

const ChangelogModal: React.FC<IModalProps> = (props) => {
  const { t } = useTranslation("common");
  const { data } = useQuery("changelog", () => getChangelog(), {
    enabled: props.isOpen,
  });

  return (
    <Modal
      className="max-w-[90vw] !rounded-2xl"
      contentClassName="!pr-1"
      {...props}
    >
      <h1 className="headline-100 mb-6">{t("recent_updates")}</h1>
      <div className="h-[calc(90dvh-120px)] overflow-y-scroll pr-7">
        {!!data &&
          data.map((el: any, i: number) => (
            <ChangelogItem key={el.version + i} item={el} />
          ))}
      </div>
    </Modal>
  );
};

export default ChangelogModal;
