import useTranslation from "next-translate/useTranslation";
import CustomButton from "../CustomButton";
import { useRef, useState } from "react";
import dayjs from "dayjs";
import AnalyticsPDF from "components/molecules/AIDesign/Benchmark/AnalyticsPDF";
import { AIImageSearchResultType } from "hooks/useAiImageSearch";
import { usePDF } from "react-to-pdf";

interface ExportAnalyticsButtonProps {
  filterParams: any;
  benchmarkData: AIImageSearchResultType;
}

const ExportAnalyticsButton: React.FC<ExportAnalyticsButtonProps> = ({
  filterParams,
  benchmarkData,
}) => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { toPDF, targetRef } = usePDF();

  const downloadPDF = async () => {
    setIsLoading(true);

    const formattedDate = dayjs(new Date()).format("MMM DD, YYYY_HH:mm:ss");

    await toPDF({
      filename: `BenchmarkAnalytics_${formattedDate}.pdf`,
    });

    setIsLoading(false);
  };

  return (
    <>
      <CustomButton
        variant="tertiary"
        label={t("export_as_pdf")}
        size="small"
        onClick={downloadPDF}
        isLoading={isLoading}
      />
      <div className="absolute w-full -z-[9999] h-[90vh] overflow-scroll">
        <div ref={targetRef}>
          <AnalyticsPDF
            filterParams={filterParams}
            benchmarkData={benchmarkData}
          />
        </div>
      </div>
    </>
  );
};

export default ExportAnalyticsButton;
