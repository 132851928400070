import { useGetFlatFacets } from "hooks/facets";
import React from "react";
import cn from "classnames";
import { useMemo } from "react";
import { ColorNames, Gender } from "utils/constants";
import uniq from "lodash/uniq";
import capitalize from "lodash/capitalize";
import useTranslation from "next-translate/useTranslation";
import { ISelectValue } from "types";
import { useUser } from "contexts/UserContext";
import isEmpty from "lodash/isEmpty";
import { usePortal } from "contexts/PortalContext";
import { useTeam } from "contexts/TeamContext";
import DropdownText from "components/atoms/Benchmark/DropdownText";
import ColorTile from "components/atoms/ColorTile";

interface IMadiaLibrarySearchFilterProps {
  className?: string;
  basecat: ISelectValue;
  gender: ISelectValue;
  color: ISelectValue;
  onSelect: (field: string) => (val: ISelectValue) => void;
  disabled?: boolean;
}

const MadiaLibrarySearchFilter: React.FC<IMadiaLibrarySearchFilterProps> = ({
  className,
  basecat,
  gender,
  color,
  disabled,
  onSelect,
}) => {
  const { t } = useTranslation("common");
  const { user } = useUser();
  const { userPortal } = usePortal();
  const { currentTeam } = useTeam();

  const { data: facets = {} } = useGetFlatFacets({
    enabled:
      !!user &&
      !!currentTeam &&
      (!!currentTeam?.portal && !!userPortal
        ? currentTeam?.portal?.id === userPortal?.id
        : true),
  });

  const genderOptions = [{ name: capitalize(t("simple_gender")) }].concat(
    Object.entries(Gender)
      .filter(([_, value]) => !!value && value !== Gender.Y)
      .map(([key, value]: string[]) => ({
        name: t(value),
        value: key,
      }))
  );

  const colorOptions = [{ name: capitalize(t("color")) }]
    .concat(ColorNames)
    .map((item: any) => ({
      ...item,
      name: t(item.value || item.name),
      icon: <ColorTile color={item.name} className="rounded-full !h-3 !w-3" />,
    }));

  const basecatOptions = useMemo(() => {
    if (facets && !isEmpty(facets)) {
      return [{ name: capitalize(t("basecat")) }].concat(
        uniq(facets?.map((el: any) => el.basecat) as string[])
          .sort((a, b) => a.localeCompare(b))
          .filter((el) => el !== "baby")
          .map((el) => ({ name: capitalize(t(el)), value: el }))
      );
    }

    return [];
  }, [facets]);

  return (
    <div className={cn("flex items-center w-full gap-4", className)}>
      {!!basecatOptions?.length && (
        <DropdownText
          onChange={onSelect("basecat")}
          label={capitalize(t("basecat"))}
          labelClassName="!text-xs"
          disabled={disabled}
          items={basecatOptions}
          selectedItems={basecat}
          menuClassName="!right-0 w-[120px]"
        />
      )}
      <DropdownText
        onChange={onSelect("gender")}
        label={capitalize(t("simple_gender"))}
        labelClassName="!text-xs"
        items={genderOptions}
        disabled={disabled}
        selectedItems={gender}
        menuClassName="!right-0 w-[120px]"
      />
      <DropdownText
        onChange={onSelect("color")}
        label={capitalize(t("color"))}
        labelClassName="!text-xs"
        items={colorOptions}
        disabled={disabled}
        selectedItems={color}
        menuClassName="!right-0 w-[120px]"
      />
    </div>
  );
};

export default MadiaLibrarySearchFilter;
