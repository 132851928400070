import { ITvGarmentKeyword } from "api/TvChannelsApi";
import { ITrendRankingData } from "hooks/useGetTrendData";
import { Catgroup, Gender } from "./constants";
import { beautifyBasecat } from "./beautify";
import { isDev } from "./checkEnv";
import { capitalize, uniqBy } from "lodash";
import sanitizeName from "./sanitizeName";

export const transformKeywords = (
  keywords?: ITvGarmentKeyword[],
  selectedData?: {
    color?: string[];
    gender?: string[];
    brand?: string;
    fabric?: string[];
    basecat?: string[];
  }
) => {
  let result: { [key: string]: any[] } = {};
  if (keywords) {
    const gender = keywords.find((el) => el.style === "gender")?.origin;
    const color = keywords.find((el) => el.style === "color")?.origin;

    if (gender) {
      result["gender"] = [gender];
    }

    if (color) {
      result["colormain"] = [(color as string).toLowerCase()];
    }
  }

  if (selectedData?.gender) {
    result["gender"] = selectedData.gender;
  }
  if (selectedData?.color) {
    result["colormain"] = selectedData.color;
  }

  if (selectedData?.brand) {
    result["brand"] = [selectedData.brand];
  }
  if (selectedData?.fabric) {
    result["fabric"] = selectedData.fabric;
  }

  if (selectedData?.basecat) {
    result["basecat"] = selectedData.basecat;
  }

  return result;
};

export const getSegmentationKeywords = (
  keywords?: ITvGarmentKeyword[],
  data?: {
    gender?: string;
    basecat?: string;
  }
) => {
  let result: { [key: string]: any } = {};
  if (keywords) {
    const gender = keywords.find((el) => el.style === "gender")?.origin;
    const catgroup = keywords.find((el) => el.style === "catgroup")?.origin;
    const basecat = keywords.find((el) => el.style === "basecat")?.origin;
    const subcat = keywords.find((el) => el.style === "subcat")?.origin;

    if (gender || data?.gender) {
      result["gender"] = data?.gender || gender;
    }
    if (catgroup) {
      result["catgroup"] = catgroup;
    }
    if (basecat) {
      result["basecat"] = basecat.replace(" ", "");
    }
    if (subcat) {
      result["subcat"] = subcat;
    }
  }

  if (data?.gender) {
    result["gender"] = data?.gender;
  }
  if (data?.basecat) {
    result["basecat"] = data?.basecat?.replace(" ", "");
  }

  return result;
};

export const nomalizeKeywords = (
  keywords?: ITvGarmentKeyword[],
  data?: {
    gender?: string;
    basecat?: string;
  }
) => {
  let result: { [key: string]: any } = {};
  if (keywords) {
    const gender = keywords.find((el) => el.style === "gender")?.origin;
    const catgroup = keywords.find((el) => el.style === "catgroup")?.origin;
    const basecat = keywords.find((el) => el.style === "basecat")?.origin;
    const subcat = keywords.find((el) => el.style === "subcat")?.origin;
    const color = keywords.find((el) => el.style === "color")?.origin;

    if (gender || data?.gender) {
      result["gender"] = data?.gender || gender;
    }
    if (catgroup) {
      result["catgroup"] = catgroup;
    }
    if (basecat) {
      result["basecat"] = basecat.replace(" ", "");
    }
    if (subcat) {
      result["subcat"] = subcat;
    }

    if (color) {
      result["color"] = color;
    }
  }

  return result;
};

const parseAIDesignMetadata = (metadata: any[], keyword: string) => {
  return metadata.map((item) => item[keyword]).filter((value) => !!value);
};

export const tarnsformStyleDetails = (styleDetails?: ITrendRankingData) => {
  const keywordSets: { style: string; value: any; origin?: any }[] = [];

  if (styleDetails?.gender) {
    keywordSets.push({
      style: "gender",
      value: Gender[styleDetails.gender as keyof typeof Gender],
      origin: styleDetails.gender,
    });
  }
  if (styleDetails?.catgroup) {
    keywordSets.push({
      style: "catgroup",
      value: beautifyBasecat(
        Catgroup[styleDetails.catgroup as keyof typeof Catgroup]
      ),
      origin: styleDetails?.catgroup,
    });
  } else {
    keywordSets.push({
      style: "catgroup",
      value: "Clothing",
      origin: "C",
    });
  }

  if (styleDetails?.basecat) {
    keywordSets.push({
      style: "basecat",
      value: beautifyBasecat(styleDetails.basecat),
      origin: styleDetails?.basecat,
    });
  }

  if (styleDetails?.normalized_style_key && isDev()) {
    keywordSets.push({
      style: "normalized_style_key",
      value: styleDetails.normalized_style_key,
      origin: styleDetails?.normalized_style_key,
    });
  }
  if (styleDetails?.style_key && isDev()) {
    keywordSets.push({
      style: "style_key",
      value: styleDetails.style_key,
      origin: styleDetails?.style_key,
    });
  }

  if (styleDetails?.style_value && isDev()) {
    keywordSets.push({
      style: "style_value",
      value: styleDetails.style_value,
      origin: styleDetails?.style_value,
    });
  }

  if (styleDetails?.name && isDev()) {
    keywordSets.push({
      style: "style_name",
      value: styleDetails.name,
      origin: styleDetails?.name,
    });
  }

  if (styleDetails?.ai_design_metadata && isDev()) {
    const metadata = Object.values(styleDetails?.ai_design_metadata);

    parseAIDesignMetadata(metadata, "fabric").forEach((value) =>
      keywordSets.push({
        style: "fabric",
        value: value,
        origin: value,
      })
    );

    parseAIDesignMetadata(metadata, "subcat").forEach((value) =>
      keywordSets.push({
        style: "subcategory",
        value: value,
        origin: value,
      })
    );

    parseAIDesignMetadata(metadata, "pgroup").forEach((value) =>
      keywordSets.push({
        style: "pgroup",
        value: value,
        origin: value,
      })
    );

    parseAIDesignMetadata(metadata, "colormain").forEach((value) =>
      keywordSets.push({
        style: "colormain",
        value: value,
        origin: value,
      })
    );
  }

  return uniqBy(keywordSets, "value");
};

export const getKeywordsFromUrlDetails = (data: { [key: string]: string }) => {
  if (data) {
    return Object.entries(data).map(([key, value]) => {
      switch (key) {
        case "colorai":
          return {
            style: "color",
            value: capitalize(value),
            origin: value,
          };
        case "gender":
          return {
            style: key,
            value: Gender[value as keyof typeof Gender],
            origin: value,
          };
        case "catgroup":
          return {
            style: key,
            value: Catgroup[value as keyof typeof Catgroup],
            origin: value,
          };
        default:
          return {
            style: key,
            value: capitalize(sanitizeName(value)),
            origin: value,
          };
      }
    });
  }
};
