import React from "react";
import cn from "classnames";

interface ITooltipButtonProps {
  delay?: number;
  icon?: JSX.Element;
  endIcon?: JSX.Element;
  buttonName?: string;
  content: string;
  className?: string;
  wrapClassName?: string;
  tooltipClassName?: string;
  buttonTextClassName?: string;
  oneline?: boolean;
  disabled?: boolean;
  onClick?: Function;
  onFocus?: Function;
  onBlur?: Function;
}

const TooltipButton: React.FC<ITooltipButtonProps> = (props) => {
  const {
    icon,
    endIcon,
    content,
    buttonName,
    className,
    wrapClassName,
    tooltipClassName,
    buttonTextClassName,
    onClick,
    onFocus,
    onBlur,
    disabled = false,
  } = props;

  return (
    <div className={cn("group relative", wrapClassName)}>
      <div
        role="button"
        className={cn(
          "p-2 flex items-center gap-1 hover:bg-gray-10 rounded-md leading-tight active:bg-gray-20 border-border-normal",
          disabled &&
            "bg-gray-30 hover:!bg-gray-30 active:!bg-gray-30 cursor-auto",
          className
        )}
        onClick={(e) => {
          onClick && onClick(e);
        }}
        onFocus={() => onFocus && onFocus()}
        onBlur={() => onBlur && onBlur()}
      >
        {icon && icon}
        {buttonName && (
          <span
            className={cn(
              "text-sm font-bold text-typography-title leading-tight",
              buttonTextClassName,
              disabled && "text-gray-50"
            )}
          >
            {buttonName}
          </span>
        )}
        {endIcon && endIcon}
      </div>
      {content && !disabled && (
        <span
          className={cn(
            "group-hover:block hidden bg-gray-950 text-white text-xs leading-normal py-1 px-2 rounded-md absolute overlay z-[9999] whitespace-nowrap",
            tooltipClassName
          )}
        >
          {content}
        </span>
      )}
    </div>
  );
};

export default TooltipButton;
