import { AIDesignProjectType } from "api/AIDesignProjectApi";
import Modal, { IModalProps } from "components/molecules/Modal";
import { useTeam } from "contexts/TeamContext";
import {
  useGetAllMoodboards,
  useGetProjectMoodboard,
} from "hooks/aiDesignProjects";
import { useChannelRemoveFromMoodboard } from "hooks/tvChannels";
import { TVGarment } from "models/TvGarment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import SavedImagesView, { ISavedImagesViewProps } from "./SavedImagesView";

interface ISavedImagesModalProps extends IModalProps, ISavedImagesViewProps {
  tvProject?: AIDesignProjectType;
  previewMode?: boolean;
  className?: string;
}

const SavedImagesModal: React.FC<ISavedImagesModalProps> = ({
  tvProject,
  disableVariation = false,
  detailedGarment,
  setPreviewGarment,
  onBenchmarkView,
  onCreateOrder,
  handleSetRevisionGarment,
  onClose,
  ...props
}) => {
  const { currentTeam } = useTeam();
  const [targetProjectId, setTargetProjectId] = useState<number | null>(null);

  useEffect(() => {
    if (tvProject) {
      setTargetProjectId(tvProject.id);
    }

    return () => setTargetProjectId(null);
  }, [tvProject]);

  const {
    data: allMoodboards,
    isLoading: allMoodboardsLoading,
    refetch: refetchAllMoodboards,
  } = useGetAllMoodboards({
    enabled: !targetProjectId && props.isOpen,
  });

  const {
    data: projectMoodboard,
    isLoading: projectMoodboardLoading,
    refetch: refetchProjectMoodboard,
  } = useGetProjectMoodboard(
    targetProjectId as number,
    currentTeam?.id as number,
    {
      enabled: !!targetProjectId && !!currentTeam?.id && props.isOpen,
    }
  );

  const onSuccess = () => {
    if (targetProjectId) {
      refetchProjectMoodboard();
    } else {
      refetchAllMoodboards();
    }
  };

  const { mutate: removeFromMoodboard } = useChannelRemoveFromMoodboard({
    onSuccess,
  });

  const handleRemoveFromMoodBoard = (garment: TVGarment) => {
    removeFromMoodboard({
      channelId: garment?.channelId,
      garmentId: garment?.id,
    });
  };

  const moodboardData = useMemo(() => {
    if (!projectMoodboard && !allMoodboards) {
      return [];
    }

    if (projectMoodboard) {
      return projectMoodboard;
    }

    return allMoodboards;
  }, [projectMoodboard, allMoodboards]);

  const loading = projectMoodboardLoading || allMoodboardsLoading;

  const onSelectProject = (id: number) => setTargetProjectId(id);

  const onCloseModal = () => {
    onClose();
    if (!tvProject) {
      setTargetProjectId(null);
    }
  };

  return (
    <Modal
      contentClassName="h-full"
      className="w-[90dvw] h-[90dvh]"
      onClose={onCloseModal}
      {...props}
    >
      <div>
        <SavedImagesView
          onSelectProject={onSelectProject}
          teamId={currentTeam?.id}
          targetProjectId={targetProjectId}
          moodboardData={moodboardData}
          disableVariation={disableVariation}
          detailedGarment={detailedGarment}
          setPreviewGarment={setPreviewGarment}
          onBenchmarkView={onBenchmarkView}
          onCreateOrder={onCreateOrder}
          handleSetRevisionGarment={handleSetRevisionGarment}
          onExploreClick={onCloseModal}
          onRemoveFromMoodBoard={handleRemoveFromMoodBoard}
          loading={loading}
          projectName={tvProject?.name}
        />
      </div>
    </Modal>
  );
};

export default SavedImagesModal;
