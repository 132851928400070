import { useApi } from "contexts/ApiContext";
import { useQuery, UseQueryOptions } from "react-query";

export interface IProductPriceHistoryData {
  [key: string]: {
    updated: string;
    jbid: string;
    price: number;
    currency: string;
  }[];
}

export const useGetProductPriceHistory = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<IProductPriceHistoryData>(
    ["product-price-history-data", params],
    () => api.global.query(`/prices/history/`, params).then((res) => res),
    config
  );
};
