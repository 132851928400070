import { IFile } from "types";
import { getFileNameFromUrl } from "./getFileNameFromUrl";
import { truncateString } from "./truncateString";

export const getShortFileName = (file: File | IFile, limit: number = 20) => {
  return truncateString(
    (file as File)?.name || getFileNameFromUrl((file as IFile)?.url),
    limit
  );
};
