import { useState } from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import Icon from "./Icon";
import CloseIcon from "public/icons/close_16.svg";

interface TagInputProps {
  tags: string[];
  icon?: any;
  addNewTag: Function;
  removeTag: Function;
  ableToRemove?: string[];
  placeholder?: string;
  disabled?: boolean;
  disabledInput?: boolean;
  className?: string;
  value?: string;
  setValue?: Function;
  onFocus?: Function;
  onBlur?: Function;
}

function TagInput({
  tags,
  icon,
  addNewTag,
  removeTag,
  placeholder,
  disabled = false,
  disabledInput = false,
  ableToRemove,
  className,
  value,
  setValue,
  onFocus,
  onBlur,
}: TagInputProps) {
  const [inputValue, setInputValue] = useState<string>("");
  const { t } = useTranslation("common");

  const handleAddNewTag = () => {
    onBlur && onBlur();
    const newTag = inputValue.trim();
    const filteredTags = tags?.filter((el) => el === newTag);
    if (filteredTags && !(filteredTags.length > 0) && newTag) {
      addNewTag(newTag);
      setInputValue("");
    }
  };

  const handleKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      handleAddNewTag();
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    removeTag(tagToRemove);
  };

  return (
    <div
      className={cn(
        "group flex flex-wrap gap-2 border rounded-md p-2 focus-within:py-[7px] focus-within:px-[15px] focus-within:border-[2px] focus-within:border-gray-950 overflow-y-scroll max-h-[106px]",
        className
      )}
    >
      {icon && (
        <Icon
          component={icon}
          viewBox="0 0 16 16"
          className="mt-[3px] w-4 h-4"
        />
      )}
      {tags?.map((tag, i) => (
        <div
          key={i}
          className={cn(
            "flex items-center bg-gray-40 gap-1 py-[3px] px-[9px] rounded-[50px] cursor-pointer !h-6",
            disabled || (!ableToRemove?.includes(tag) && "!cursor-not-allowed")
          )}
          onClick={() =>
            !disabled && ableToRemove?.includes(tag) && handleRemoveTag(tag)
          }
        >
          <span className="text-xs font-normal text-heading leading-normal ">
            {tag}
          </span>
          {!disabled && ableToRemove?.includes(tag) && (
            <span className="py-[1px]">
              <Icon
                component={CloseIcon}
                width={16}
                height={16}
                viewBox="0 0 16 16"
                className="fill-gray-70"
              />
            </span>
          )}
        </div>
      ))}
      {!disabledInput && (
        <input
          type="text"
          value={value || inputValue}
          onKeyDown={handleKeyDown}
          onChange={(event) =>
            setValue
              ? setValue(event.target.value)
              : setInputValue(event.target.value)
          }
          placeholder={
            tags?.length === 0
              ? placeholder
                ? placeholder
                : t("purple_hues_ruffle_etc")
              : ""
          }
          onBlur={handleAddNewTag}
          className="min-w-[60px] flex-1 border-0 focus:outline-none px-2 text-sm font-normal leading-normal placeholder-gray-50"
          onFocus={() => onFocus && onFocus()}
        />
      )}
    </div>
  );
}

export default TagInput;
