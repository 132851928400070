import { useApi } from "contexts/ApiContext";
import { useQuery, UseQueryOptions } from "react-query";

export interface SourceMapDataType {
  [key: string]: {
    name: string;
  };
}

export const useGetSourceMapOne = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<SourceMapDataType>(
    ["sourcemap-one"],
    () => api.global.query(`/meta/sourcemap`).then((res) => res),
    config
  );
};

export const useGetSourceMapTwo = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<SourceMapDataType>(
    ["sourcemap-two"],
    () => api.global.query(`/meta/assets/sourcemap.json`).then((res) => res),
    config
  );
};
