import Dropdown from "components/atoms/DropDown";
import React from "react";
import cn from "classnames";
import { useUser } from "contexts/UserContext";
import Avatar from "components/atoms/Avatar";
import Icon from "components/atoms/Icon";
import { Translate } from "next-translate";
import BellIcon from "public/icons/bell_16.svg";
import { useTeam } from "contexts/TeamContext";
import { useMemo } from "react";
import useTranslation from "next-translate/useTranslation";
import SignOutIcon from "public/icons/sign_out_16.svg";
import Link from "next/link";
import UserCircle from "public/icons/user_circle_16.svg";
import LightningIcon from "public/icons/lightning_16.svg";
import Briefcase from "public/icons/briefcase_16.svg";
import PrivacyPolicyIcon from "public/icons/check-guard_16.svg";
import { usePortal } from "contexts/PortalContext";
import { isOwnerUser } from "utils/isOwnerUser";
import { isTeamAdminUser } from "utils/isTeamAdmin";
import BatteryIcon from "public/icons/battery.svg";
import { DEFAULT_PORTAL_NAME } from "utils/constants";
import Tooltip from "components/atoms/Tooltip";

const dropdownItems = (
  t: Translate,
  portalName: string,
  hasPortal: boolean,
  isUserSupplier: boolean
) => {
  let items = [
    {
      icon: UserCircle,
      title: t("account"),
      href: `/${portalName}/profile/account`,
    },
    {
      icon: UserCircle,
      title: t("workspace_settings"),
      href: `/${portalName}/portal/details`,
    },
    {
      icon: BellIcon,
      title: t("notifications"),
      href: `/${portalName}/profile/notifications`,
    },
    {
      icon: Briefcase,
      title: t("team_management"),
      href: "/team",
    },
    {
      icon: LightningIcon,
      title: t("plan_and_billing"),
      href: `/${portalName}/profile/subscription`,
    },
    {
      icon: PrivacyPolicyIcon,
      title: t("privacy_policy"),
      iconClassName: "!fill-white !stroke-gray-80 group-hover:!stroke-gray-950",
      href: `https://jellistudio.com/privacy-policy`,
    },
  ];

  if (!hasPortal) {
    items = items.filter((el) => el.title !== t("workspace_settings"));
  }

  if (isUserSupplier) {
    items.push({
      icon: BatteryIcon,
      title: t("supplier_settings"),
      href: `/${DEFAULT_PORTAL_NAME}/profile/supplier`,
    });
  }

  return items;
};

interface IProfileDropdownProps {
  dropdownClassName?: string;
  triggerClassName?: string;
}

const ProfileDropdown: React.FC<IProfileDropdownProps> = ({
  dropdownClassName,
  triggerClassName,
}) => {
  const { t } = useTranslation("common");
  const { user, signOut, hasAbilityToOwnPortal, isAdmin } = useUser();
  const { path, currentTeam, isMaintainer } = useTeam();
  const isOwner = isOwnerUser(currentTeam?.teamRole);
  const { portalName, userPortal, isPortal } = usePortal();
  const isTeamAdmin = isTeamAdminUser(currentTeam?.teamRole);
  const canCreatePortal = currentTeam?.limits?.ownPortal;
  const hasPortal = isPortal
    ? !!userPortal?.isCurrentUserOwner || (isTeamAdmin && isMaintainer)
    : (hasAbilityToOwnPortal && isOwner && !isAdmin) ||
      (canCreatePortal && isTeamAdmin);

  const preparedOptions = useMemo(() => {
    let options = dropdownItems(
      t,
      portalName,
      !!hasPortal,
      !!currentTeam?.teamOwner?.isEligibleSupplier
    );
    const hasPasswordLogin = user?.providerData.some(
      (el) => el.providerId === "password"
    );

    // if (!hasPasswordLogin) {
    //   options = options.filter(
    //     (tab) => tab.title !== options[options.length - 1].title
    //   );
    // }
    if (isAdmin || (isPortal && userPortal && !userPortal.isCurrentUserOwner)) {
      options = options.filter((tab) => tab.title !== t("plan_and_billing"));
    }

    return options;
  }, [user?.providerData, user, portalName, hasPortal, isPortal, userPortal]);

  return (
    <Dropdown
      dropdownClassname={cn(
        "max-h-[350px] w-[270px] !top-[60px] rounded-md",
        dropdownClassName
      )}
      itemAs="fragment"
      trigger={
        <Tooltip
          arrowHidden
          content={
            <div className="!text-white !text-xs px-1">
              {user?.displayName || user?.name}{" "}
              {(user?.displayName || user?.name) && <br />}
              {user?.email}
            </div>
          }
          className="!bg-gray-950 !rounded-[4px]"
        >
          <div
            className={cn(
              "group flex justify-center transition-all duration-200 ease-in-out",
              triggerClassName
            )}
          >
            <div className="flex items-center relative">
              <div className="flex justify-between items-center cursor-pointer rounded-full group-hover:border-gray-20 border-transparent !border-4">
                <Avatar
                  className={cn("text-xs")}
                  fullName={user?.name}
                  image={user?.photoURL}
                  avatarColor={user?.avatarColor}
                />
              </div>
            </div>
          </div>
        </Tooltip>
      }
      itemComponent={({ item }) => (
        <Link
          href={
            item.title === t("team_management") ? path(item.href) : item.href
          }
        >
          <div className="px-2 w-full">
            <div className="group cursor-pointer w-full rounded-md flex hover:text-typography-headline hover:bg-gray-20 items-center px-2 py-[9px]">
              <Icon
                component={item.icon}
                viewBox="0 0 16 16"
                className={cn(
                  "mr-2 fill-gray-80 group-hover:fill-gray-950",
                  item.iconClassName
                )}
                width={16}
                height={16}
              />
              <span className="text-sm leading-normal">{item.title}</span>
            </div>
          </div>
        </Link>
      )}
      items={[
        {
          customComponent: () => (
            <div className="p-2 border-b border-custom-color-1 mb-2">
              <div className="w-full flex justify-start items-center px-2 py-2 gap-3">
                <div className="w-8">
                  <Avatar
                    className={cn("text-xs group-hover:border-cta-600")}
                    fullName={user?.name}
                    image={user?.photoURL}
                    avatarColor={user?.avatarColor}
                  />
                </div>
                <div className="max-w-[80%] whitespase-nowrap">
                  <p className="font-normal text-sm leading-normal text-body overflow-hidden text-ellipsis">
                    {user?.name}
                  </p>
                  <p className="text-xs overflow-hidden text-ellipsis leading-normal font-normal text-body-subtitle">
                    {user?.email}
                  </p>
                </div>
              </div>
            </div>
          ),
        },
        ...preparedOptions,
        {
          customComponent: () => (
            <div className="p-2 border-t border-custom-color-1 mt-2">
              <button
                onClick={() => signOut()}
                className="group w-full rounded-md flex hover:text-typography-headline hover:bg-gray-20 items-center px-2 py-[9px]"
              >
                <Icon
                  component={SignOutIcon}
                  viewBox="0 0 16 16"
                  className="mr-2 fill-gray-80 group-hover:fill-typography-headline"
                  width={16}
                  height={16}
                />
                <span className="text-sm leading-normal">{t("log_out")}</span>
              </button>
            </div>
          ),
        },
      ]}
    />
  );
};

export default ProfileDropdown;
