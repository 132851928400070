export const getChangelog = async () => {
  const file = await require('CHANGELOG.md');

  const changelogString = file.default;

  const splittedByVersion = changelogString.split('## v');
  splittedByVersion.shift();

  const splittedByParagraph = splittedByVersion.map(el => {
    return el.split("\n### ")
  });

  const res = splittedByParagraph.reduce((acc, el) => {
    const version = el.shift().replace(/\n/g, '').trim();
    const splittedByItem = el.reduce((acc, el) => [...acc, el.split('\n* ')], []);

    return [...acc, {
      version: version,
      updates: splittedByItem.map(el => ({
        title: el.shift().replace(/\n/g, '').trim(),
        items: el.map(el => el.replace(/\n/g, '').trim())
      })),
    }]
  }, [])

  return res;
};
