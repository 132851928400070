import ReactModal from "react-modal";
import ImageResponsive from "../ImageResponsive";

interface ImagePreviewProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl?: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
  isOpen,
  onClose,
  imageUrl,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`bg-white rounded-md xs:w-1/2 2lg:w-[850px] xxs:w-[90vw] xxs:max-h-[450px] xs:h-full max-h-[600px] border-custom-color-1 border`}
      overlayClassName="fixed bg-gray-400/80 h-full overflow-y-auto inset-0 z-[60] flex items-center lg:items-center justify-center"
    >
      <div className="w-full h-full flex items-center justify-center p-8">
        <ImageResponsive url={imageUrl || ""} className="w-full h-full" />
      </div>
    </ReactModal>
  );
};

export default ImagePreview;
