import Portal from "models/Portal";

const restrictedDomains = ["my.infinityinnovation.design"];

export const isRestrictedCustomDomain = (urlPortal?: Portal) =>
  urlPortal &&
  urlPortal?.customDomain &&
  restrictedDomains.includes(urlPortal.customDomain.domain);

export const getSupportUrl = (urlPortal?: Portal) => {
  if (urlPortal && urlPortal?.customDomain && urlPortal.customDomain.domain) {
    if (urlPortal.customDomain.domain === "my.infinityinnovation.design") {
      return "info@infinityinnovation.co";
    }
  }
  return "support@jellistudio.com";
};
