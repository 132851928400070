import useTranslation from "next-translate/useTranslation";
import Folder from "public/icons/folder.svg";
import Icon from "./Icon";

const NoTableData = () => {
  const { t } = useTranslation("common");

  return (
    <div className="text-center w-full">
      <div>
        <Icon
          component={Folder}
          viewBox="0 0 60 61"
          width={60}
          height={60}
          className="mx-auto"
        />
      </div>
      <div className="mt-[22px]">
        <h2 className="text-2xl font-bold leading[30px] text-title">
          {t("no_table_data")}
        </h2>
      </div>
      <p className="text-sm font-normal text-body mt-[12px]">
        {t("no_table_data_description")}
      </p>
    </div>
  );
};

export default NoTableData;
