import cn from "classnames";
import { usePortal } from "contexts/PortalContext";
import { useUser } from "contexts/UserContext";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal, { IModalProps } from "components/molecules/Modal";
import BenchmarkFilters, {
  getFilteredData,
} from "components/molecules/AIDesign/Benchmark/BenchmarkFilter";
import { DropdownTextItemType } from "components/atoms/Benchmark/DropdownText";
import {
  AIImageSearchResultType,
  useAiImageSearch,
} from "hooks/useAiImageSearch";
import {
  getSegmentationKeywords,
  transformKeywords,
} from "utils/transformKeywords";
import BenchmarkOverview from "components/molecules/AIDesign/Benchmark/BenchmarkOverview";
import { ITvGarmentKeyword } from "api/TvChannelsApi";
import { IProductDetailData } from "api/ProductApi";
import ProductDetails from "components/molecules/ProductDetails";
import { useGetCategoryStats } from "hooks/category";
import { RangeType } from "components/molecules/DateRangeChartPopover";
import { convertDateToNumber } from "utils/convertDate";
import { BenchmarkHeader } from "components/molecules/AIDesign/Benchmark/BenchmarkHeader";
import FilterLabelButtonGroup from "components/molecules/FilterLabelButtonGroup";
import { convertDate } from "utils/dates";
import { useBreakpoint } from "contexts/BreakpointContext";
import { convertUSLocalString } from "utils/beautifyNumbers";
import CloseButton from "components/molecules/CloseButton";
import TabsSelection from "components/atoms/TabsSelection";

interface FilterItem {
  label: string;
  onClose: (() => void) | null;
}

interface IBenchmarkModalProps extends IModalProps {
  id: string;
  keywordSet?: ITvGarmentKeyword[];
  image: string;
}

const BenchmarkMobileView: React.FC<
  Partial<IBenchmarkModalProps> & {
    benchmarkData?: AIImageSearchResultType;
    onSimilarityChange: (val: number) => void;
    filterCount: number;
    similarity: number;
    filtersProps: any;
    overviewProps: any;
    isOpenProduct: boolean;
  }
> = ({
  onClose,
  benchmarkData,
  onSimilarityChange,
  filterCount,
  similarity,
  filtersProps,
  overviewProps,
  isOpenProduct,
}) => {
  const { t } = useTranslation("common");
  const [isFiltersOpened, setIsFiltersOpened] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("products");

  const openFilters = () => setIsFiltersOpened(true);
  const closeFilters = () => setIsFiltersOpened(false);

  useEffect(() => {
    return closeFilters;
  }, []);

  const productIds = useMemo(() => {
    return benchmarkData?.similar_jbids || [];
  }, [benchmarkData?.similar_jbids]);

  const tabs = [
    {
      label: `${t("products")} (${productIds?.length || 0})`,
      value: "products",
    },
    { label: t("analytics"), value: "analytics" },
  ];

  const onSelectTab = (val: unknown) => setSelectedTab(val as string);

  return (
    <div className={cn("relative block", isOpenProduct && "hidden")}>
      <div className="flex justify-between border-b border-border-normal p-4">
        <div className="w-5" />
        <p className="headline-200 whitespace-nowrap text-center">
          {t("benchmark")}
        </p>
        <div className="justify-end pr-2 flex">
          <CloseButton onClick={onClose} />
        </div>
      </div>
      <div className="p-4">
        <TabsSelection
          tabs={tabs}
          activeValue={selectedTab}
          className="w-full mb-4"
          tabClassName="w-full !py-2"
          alt
          onSelect={onSelectTab}
        />
        <BenchmarkHeader
          filterCount={filterCount}
          similarity={similarity}
          setSimilarity={onSimilarityChange}
          handleFilter={openFilters}
        />
        <BenchmarkOverview selectedTab={selectedTab} {...overviewProps} />
      </div>
      {isFiltersOpened && (
        <div className="absolute bg-white left-0 top-0 z-10 w-full h-full">
          <div className="flex justify-between border-b border-border-normal p-4">
            <div className="w-5" />
            <p className="headline-200 whitespace-nowrap text-center">
              {t("filters")}
            </p>
            <div className="justify-end pr-2 flex">
              <CloseButton onClick={closeFilters} />
            </div>
          </div>
          <div className="p-4">
            <BenchmarkFilters {...filtersProps} />
          </div>
        </div>
      )}
    </div>
  );
};

const BenchmarkModal: React.FC<IBenchmarkModalProps> = ({
  id,
  keywordSet,
  image,
  isOpen,
  onClose,
  ...props
}) => {
  const { t } = useTranslation("common");
  const { user } = useUser();
  const { isPortal } = usePortal();

  const [similarity, setSimilarity] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<DropdownTextItemType>({
    name: "",
    value: "desc",
  });
  const [sortKeyword, setSortKeyword] = useState<DropdownTextItemType>({
    name: "Similarity",
    value: "similarity",
  });
  const [catalogType, setCatalogType] = useState<
    DropdownTextItemType | undefined
  >({
    name: "Retailers brands",
    value: "retailers_brands",
  });
  const [colors, setColors] = useState<DropdownTextItemType[] | undefined>();
  const [genders, setGenders] = useState<DropdownTextItemType[] | undefined>();
  const [basecats, setBasecats] = useState<
    DropdownTextItemType[] | undefined
  >();
  const [materials, setMaterials] = useState<
    DropdownTextItemType[] | undefined
  >();
  const [brand, setBrand] = useState<DropdownTextItemType>();
  const [timerRef, setTimerRef] = useState<NodeJS.Timeout>();
  const [isTakeLong, setIsTakeLong] = useState<boolean>(false);

  const [isOpenProduct, setIsOpenProduct] = useState<boolean>(false);
  const [product, setProduct] = useState<IProductDetailData>();
  const [priceRange, setPriceRange] = useState<RangeType>({ min: 0, max: 0 });
  const [dateRange, setDateRange] = useState<RangeType>({ min: 0, max: 0 });
  const [pageNum, setPageNum] = useState<number>(0);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);

  const { xs, xxs, sm } = useBreakpoint();

  const isMobile = xs || xxs || sm;

  const params = {
    basecat: keywordSet?.find((el) => el.style === "basecat")?.origin,
  };

  const { data } = useGetCategoryStats(params, {
    enabled: !!params.basecat,
  });

  const categoryStats = data?.[0];

  const filterParams = {
    request_id: id,
    data_path: image,
    categorical_catalog_metadata: {
      ...transformKeywords(keywordSet, {
        gender: genders?.map((item) => item.value),
        color: colors?.map((item) => item.value),
        brand: brand?.value,
        fabric: materials?.map((item) => item.value),
        basecat: basecats?.map((item) => item.value),
      }),

      catalog_type: [catalogType?.value],
    },
    numerical_catalog_metadata: {
      ...(priceRange.max > 0
        ? { price_usd: { $gte: priceRange.min, $lt: priceRange.max } }
        : {}),
      ...(dateRange.max > 0
        ? {
            first_seen: {
              $gte: Number(convertDateToNumber(dateRange.min)),
              $lt: Number(convertDateToNumber(dateRange.max)),
            },
          }
        : {}),
    },
    segmentation_params: getSegmentationKeywords(keywordSet),
    threshold_similarity: similarity / 100,
    alpha: 1,
  };

  const { data: benchmarkData, isLoading: isLoadingAiImageData } =
    useAiImageSearch(filterParams, {
      enabled: !!image && isOpen,
    });

  const {
    data: benchmarkDataWithoutRange,
    isLoading: isLoadingAiImageDataWithoutRange,
  } = useAiImageSearch(
    {
      request_id: id + 10000,
      data_path: image,
      categorical_catalog_metadata: {
        ...transformKeywords(keywordSet),
        catalog_type: [catalogType?.value],
      },
      segmentation_params: getSegmentationKeywords(keywordSet),
      threshold_similarity: similarity / 100,
      alpha: 1,
    },
    {
      enabled: !!image && !!benchmarkData,
    }
  );

  useEffect(() => {
    if (benchmarkData) {
      timerRef && clearTimeout(timerRef);
      setIsTakeLong(false);
    } else if (isLoadingAiImageData) {
      const timeId = setTimeout(() => setIsTakeLong(true), 5000);
      setTimerRef(timeId);
    }
  }, [benchmarkData, isLoadingAiImageData]);

  useEffect(() => {
    setPageNum(0);
    setPriceRange({ min: 0, max: 0 });
    setDateRange({ min: 0, max: 0 });
    setSortKeyword({
      name: t("Similarity"),
      value: "similarity",
    });
    setColors(undefined);
    setGenders(undefined);
    setBasecats(undefined);
    setMaterials(undefined);
    setBrand(undefined);
    setMaterials(undefined);
  }, [id]);

  const onCloseBenchmarkModal = () => {
    if (!isOpenProduct) {
      onClose();
    }
  };

  const onClear = () => {
    setBrand(undefined);
    setGenders(undefined);
    setColors(undefined);
    setBasecats(undefined);
    setMaterials(undefined);
    setDateRange({ min: 0, max: 0 });
    setPriceRange({ min: 0, max: 0 });
  };

  const handleFilter = useCallback(
    (name: string, value: string, source: string) => {
      const selectedData = { name, value };

      switch (source) {
        case "source":
          setCatalogType(selectedData);
          break;
        case "gender":
          setGenders(getFilteredData(selectedData, genders));
          break;
        case "categories":
          setBasecats(getFilteredData(selectedData, basecats));
          break;
        case "color":
          setColors(getFilteredData(selectedData, colors));
          break;
        case "brand":
          setBrand(
            selectedData.value === brand?.value ? undefined : selectedData
          );
          break;
        case "material":
          setMaterials(getFilteredData(selectedData, materials));
          break;
        default:
          break;
      }
    },
    [genders, basecats, colors, materials, brand]
  );

  const generateFilterLabelList = (
    items: DropdownTextItemType[],
    source: string
  ) => {
    return items.map((item) => ({
      label: item.name,
      onClose: () => handleFilter(item.name, item.value, source),
    }));
  };

  const { filterLabelList, filterCount } = useMemo(() => {
    const list: FilterItem[] = [
      {
        label: catalogType?.name || "",
        onClose: null,
      },
    ];

    let filterCount = 1;

    if (brand) {
      filterCount = filterCount + 1;
      list.push({
        label: brand.name,
        onClose: () => setBrand(undefined),
      });
    }

    if (genders) {
      filterCount += genders.length;
      list.push(...generateFilterLabelList(genders, "gender"));
    }

    if (basecats) {
      filterCount += basecats.length;
      list.push(...generateFilterLabelList(basecats, "categories"));
    }

    if (colors) {
      filterCount += colors.length;
      list.push(...generateFilterLabelList(colors, "color"));
    }

    if (materials) {
      filterCount += materials.length;
      list.push(...generateFilterLabelList(materials, "material"));
    }

    if (priceRange.max !== priceRange.min && priceRange.max !== 1) {
      filterCount += 1;
      list.push({
        label: `${convertUSLocalString(
          priceRange.min
        )} USD - ${convertUSLocalString(priceRange.max)} USD`,
        onClose: () => setPriceRange({ min: 0, max: 0 }),
      });
    }

    if (!isNaN(dateRange.max) && dateRange.max !== 0) {
      filterCount += 1;
      list.push({
        label: `${convertDate(dateRange.min, "MMM YYYY")} - ${convertDate(
          dateRange.max,
          "MMM YYYY"
        )}`,
        onClose: () => setDateRange({ min: 0, max: 0 }),
      });
    }

    return { filterLabelList: list, filterCount: filterCount };
  }, [
    catalogType,
    brand,
    genders,
    basecats,
    colors,
    materials,
    priceRange,
    dateRange,
  ]);

  useEffect(() => {
    if (isMobile) {
      setIsOpenFilter(false);
    } else {
      setIsOpenFilter(true);
    }
  }, [isMobile]);

  const filtersProps = {
    catalogType,
    setCatalogType,
    colors,
    setColors,
    genders,
    setGenders,
    basecats,
    setBasecats,
    brand,
    setBrand,
    materials,
    setMaterials,
    priceRange,
    setPriceRange,
    dateRange,
    setDateRange,
    benchmarkMetadata: benchmarkDataWithoutRange?.metadata,
    isLoadingbenchmarkMetadata:
      isLoadingAiImageDataWithoutRange ||
      (!benchmarkDataWithoutRange && isLoadingAiImageData),
  };

  const overviewProps = {
    image,
    pageNumber: pageNum,
    isTakeLong: isTakeLong,
    isFilterOpen: isOpenFilter,
    benchmarkData,
    isLoadingAiImageData,
    onSelectProduct: (product: IProductDetailData, pageNumber: number) => {
      setProduct(product);
      setIsOpenProduct(true);
      setPageNum(pageNumber);
    },
    filterParams,
  };

  return (
    <Modal
      isOpen={isOpen}
      className={cn(
        "xxs:w-full md:!w-[1168px] xxs:max-h-screen md:!max-h-[90dvh] xxs:h-full md:!h-[90vh] xxs:m-0 md:!m-6 relative xxs:rounded-none md:!rounded-2xl",
        isOpenProduct && "!h-fit !max-h-fit my-10"
      )}
      contentClassName={cn("xxs:p-0 md:!p-8 h-full overflow-y-auto")}
      overlayClassName="xxs:items-start md:!items-center"
      onClose={isMobile ? null : onCloseBenchmarkModal}
      {...props}
    >
      {isMobile ? (
        <BenchmarkMobileView
          onSimilarityChange={setSimilarity}
          similarity={similarity}
          filterCount={filterCount}
          benchmarkData={benchmarkData}
          onClose={onClose}
          filtersProps={filtersProps}
          overviewProps={overviewProps}
          isOpenProduct={isOpenProduct}
        />
      ) : (
        <div
          className={cn("w-full relative", isOpenProduct ? "hidden" : "block")}
        >
          <div className="flex items-start justify-between pb-4">
            <div className="text-lg font-bold text-typography-headline leading-tight w-[350px] flex-none">
              {t("benchmark")}
            </div>
            {/* benchmark  */}
            <BenchmarkHeader
              setSimilarity={setSimilarity}
              similarity={similarity}
              filterCount={filterCount}
              handleFilter={() => {
                setIsOpenFilter(!isOpenFilter);
              }}
            />
          </div>
          <div className="w-full relative flex gap-4">
            <div className="w-full h-full">
              {filterCount > 1 && (
                <div className="w-full flex flex-col gap-2 pb-4">
                  <div className="flex items-center justify-between w-full">
                    <div className="text-xs font-bold text-gray-950">
                      {benchmarkData?.metadata?.length && (
                        <span>
                          {benchmarkData?.metadata.length}{" "}
                          {benchmarkData?.metadata.length > 1
                            ? t("results")
                            : t("result")}
                        </span>
                      )}
                    </div>
                    <button
                      className="text-cta-600 font-bold text-xs"
                      onClick={onClear}
                    >
                      {t("Clear all filters")}
                    </button>
                  </div>
                  <FilterLabelButtonGroup filterLabels={filterLabelList} />
                </div>
              )}
              <BenchmarkOverview {...overviewProps} />
            </div>
            {isOpenFilter && (
              <div className="xxs:absolute -top-4 bg-white md:!static flex-none w-[260px] overflow-y-hidden border border-normal rounded-t-xl">
                <BenchmarkFilters {...filtersProps} />
              </div>
            )}
          </div>
        </div>
      )}
      {product && (
        <div
          className={cn(
            "w-full xxs:h-screen xxs:p-4 md:!p-0 md:!h-[calc(90vh-32px)]",
            product && isOpenProduct ? "block" : "hidden"
          )}
        >
          <ProductDetails
            product={product}
            onClose={() => setIsOpenProduct(false)}
          />
        </div>
      )}
    </Modal>
  );
};

export default BenchmarkModal;
