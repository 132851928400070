import React from "react";
import MediaAssetCard, { MediaAssetCardProps } from "./MediaAssetCard";
import { useDrag } from "react-dnd";
import { useTeam } from "contexts/TeamContext";

export interface DropResult {
  id: number;
  name: string;
}

interface DragableMediaAssetCardProps extends MediaAssetCardProps {
  currentFolder: number | null;
  onMoveAsset: (assetId: number, folderId: number) => void;
}

const DragableMediaAssetCard: React.FC<DragableMediaAssetCardProps> = (
  props
) => {
  const { teamId } = useTeam();
  const { asset, currentFolder, onMoveAsset } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Card",
    item: { name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult && asset?.id && dropResult.id !== currentFolder) {
        onMoveAsset(asset?.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      ref={asset?.team?.id === teamId ? drag : null}
      data-testid={`box`}
      style={{
        opacity: isDragging ? 0.4 : 1,
      }}
    >
      <MediaAssetCard {...props} />
    </div>
  );
};

export default DragableMediaAssetCard;
