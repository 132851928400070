import React, { useEffect } from "react";
import { useState } from "react";
import { ISelectValue, KeywordType } from "types";
import cloneDeep from "lodash/cloneDeep";
import { ITvGarmentKeyword } from "api/TvChannelsApi";
import DropZone from "components/atoms/DropZone";
import Icon from "components/atoms/Icon";
import useTranslation from "next-translate/useTranslation";
import TrashIcon from "public/icons/trash-2.svg";
import UploadIcon from "public/icons/upload.svg";
import cn from "classnames";
import CtaButton from "components/atoms/CtaButton";
import { AIDesignAssetType, CreateAssetPayload } from "api/AIDesignAssetsApi";
import { useMemo } from "react";
import isEqual from "lodash/isEqual";
import { capitalize, isEmpty } from "lodash";
import Tooltip from "components/atoms/Tooltip";
import { useUploadFile, useUploadManyFiles } from "hooks/admin";
import isArray from "lodash/isArray";
import { FormikProvider, useFormik } from "formik";
import { Translate } from "next-translate";
import * as yup from "yup";
import { isGuestUser } from "utils/isGuestUser";
import { isViewerUser } from "utils/isViewerUser";
import { useTeam } from "contexts/TeamContext";
import CustomButton from "components/atoms/CustomButton";
import FormikInput from "components/molecules/FormikInput";
import MediaStyleDetailsFilters from "components/molecules/MediaLibrary/MediaStyleDetailsFilters";
import TrendSelect from "components/atoms/TrendSelect";
import { ColorNames } from "utils/constants";
import {
  getImageDetails,
  useGetImageDetails,
  useUploadImageAndGetDetails,
  useWarmGettingImageDetailsEnv,
} from "hooks/useGetImageDetails";
import AutoClassificationView from "components/molecules/MediaLibrary/AutoClassificationView";
import AIStreammingIcon from "public/icons/ai_generate_16.svg";
import ImageResponsive from "components/atoms/ImageResponsive";
import { getKeywordsFromUrlDetails } from "utils/transformKeywords";

const getValidationSchema = (t: Translate) =>
  yup.object({
    name: yup.string(),
    description: yup.string(),
    prices: yup.array().of(
      yup.object({
        quantity: yup
          .string()
          .min(1, "Should not be empty")
          .matches(/^[\d+-><=]*$/, 'Allows numbers and "+>-<="')
          .required(t("field_is_required")),
        price: yup
          .string()
          .min(1, "Should not be empty")
          .required(t("field_is_required")),
        leadTime: yup
          .string()
          .min(1, "Should not be empty")
          .required(t("field_is_required")),
      })
    ),
  });

interface IMediaAssetFormProps {
  asset?: AIDesignAssetType | null;
  onSaveAsset?: (payload: Partial<CreateAssetPayload>) => void;
  onSaveMany?: (payload: CreateAssetPayload[]) => void;
  onApplyAsset?: (asset: AIDesignAssetType) => void;
  onCreateAiProject?: (asset: AIDesignAssetType) => void;
  onSaveAndApplyAsset?: (payload: Partial<CreateAssetPayload>) => void;
  onDelete?: (id: number) => void;
  className?: string;
  onBack?: () => void;
  saveLoading?: boolean;
  applyLoading?: boolean;
  formDisabled?: boolean;
  createProjectLoading?: boolean;
  applyTipText?: string;
  tempImages: File | File[] | null;
  setTempImages: Function;
  open?: boolean;
  disableSave?: boolean;
}

const MediaAssetForm: React.FC<IMediaAssetFormProps> = ({
  asset,
  onSaveAsset,
  onSaveMany,
  onApplyAsset,
  onSaveAndApplyAsset,
  saveLoading,
  applyLoading,
  createProjectLoading,
  formDisabled = false,
  onCreateAiProject,
  onDelete,
  onBack,
  className,
  applyTipText,
  tempImages,
  setTempImages,
  open,
  disableSave = false,
}) => {
  const { t } = useTranslation("common");
  const { currentTeam } = useTeam();
  const isGuest = isGuestUser(currentTeam?.teamRole);
  const isViewer = isViewerUser(currentTeam?.teamRole);
  const [keywords, setKeywords] = useState<Array<ITvGarmentKeyword>>(
    asset?.keywordSet?.keywordSet || []
  );
  const [urlToClassify, setUrlToClassify] = useState<string | null>(null);
  const [isManyClassifying, setIsManyClassifying] = useState<boolean>(false);

  const { mutate: warmAutoClassification } = useWarmGettingImageDetailsEnv();

  useEffect(() => {
    warmAutoClassification({});
  }, []);

  const {
    data: autoClassificationsData,
    isError: isAutoClassificationsError,
    isLoading: autoClassificationsLoading,
  } = useUploadImageAndGetDetails(tempImages as File, {
    enabled: !!tempImages && !isArray(tempImages),
  });

  const autoClassifications = autoClassificationsData.data;
  const uploadedUrl = autoClassificationsData.uploadedUrl;

  const {
    data: forcedClassifications,
    isLoading: isForcedClassificationsLoading,
    isError: isForcedClassificationsError,
  } = useGetImageDetails(urlToClassify!, {
    enabled: !!urlToClassify,
  });

  const classifications = useMemo(() => {
    if (forcedClassifications) {
      return {
        data: forcedClassifications.data,
        errors: forcedClassifications.errors,
      };
    }

    if (autoClassifications) {
      return {
        data: autoClassifications.data,
        errors: autoClassifications.errors,
      };
    }
  }, [autoClassifications, forcedClassifications]);

  const colorOptions = [{ name: t("automatic") }, ...ColorNames].map(
    (item: any) => ({
      ...item,
      name: t(item.value),
    })
  );

  const color = keywords.find((el) => el.style === "color");

  const colorValue = useMemo(() => {
    return color
      ? {
          name: capitalize(t(color.value)),
          value: color.value,
          preselected: color?.preselected,
        }
      : { name: t("automatic") };
  }, [color]);

  const assetUrl = asset?.assetUrl;

  const formik = useFormik({
    initialValues: {
      name: asset?.name || "",
      description: asset?.description || "",
      prices: asset?.prices || [],
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(t),
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: () => {},
  });

  useEffect(() => {
    setKeywords(asset?.keywordSet?.keywordSet || []);
    formik.setFieldValue("name", asset?.name || "");
    formik.setFieldValue("description", asset?.description || "");
    formik.setFieldValue("prices", asset?.prices || []);
  }, [onreset]);

  const isEditted = useMemo(() => {
    return (
      asset &&
      (!isEqual(asset?.keywordSet?.keywordSet, keywords) ||
        tempImages ||
        asset.name !== formik.values.name ||
        asset.description !== formik.values.description ||
        !isEqual(asset.prices, formik.values.prices))
    );
  }, [asset, keywords, tempImages, formik.values]);

  const addKeyword = (style: string) => (val: ISelectValue) => {
    const { name, value } = val;
    if (name === t("automatic")) {
      setKeywords((s: any) => {
        let newState = cloneDeep(s);
        newState = newState.filter(
          (el: ITvGarmentKeyword) => el.style !== style
        );

        return newState;
      });
    } else {
      setKeywords((s: any) => {
        let newState = cloneDeep(s);
        const item = { style, value: name, origin: value };
        if (newState.find((el: ITvGarmentKeyword) => el.style === style)) {
          newState = newState.filter(
            (el: ITvGarmentKeyword) => el.style !== style
          );
        }

        return [...newState, item];
      });
    }
  };

  const onApplyImageClassification = (keywords: KeywordType[]) => {
    setKeywords((s: any) => {
      const newState = cloneDeep(s);
      const filteredKeywords = newState.filter(
        (el: KeywordType) =>
          !keywords.some((keyword) => el.style === keyword.style)
      );
      return [
        ...filteredKeywords,
        ...keywords.map((el) => ({ ...el, preselected: true })),
      ];
    });
  };

  const { mutate: uploadShopifyImage, isLoading: shopifyImageUploading } =
    useUploadFile();

  const { mutate: uploadImage, isLoading: uploading } = useUploadFile();

  const { mutate: uploadManyImages, isLoading: manyLoading } =
    useUploadManyFiles();

  const onImageChange = (files: File[]) => {
    if (files.length > 1) {
      setTempImages(files);
    } else {
      setTempImages(files[0]);
    }
  };

  const validateFormikFields = async () => {
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      return formik.values;
    }

    Object.keys(errors).forEach((el) => formik.setFieldTouched(el));

    return null;
  };

  const onSaveAndApply = async () => {
    const preparedKeywords = keywords.map((el) => ({
      ...el,
      preselected: undefined,
    }));
    if (onSaveAndApplyAsset) {
      const formikValues = await validateFormikFields();
      if (formikValues) {
        if (tempImages) {
          const data = new FormData();
          data.append("file", tempImages as File);
          uploadImage(data, {
            async onSuccess({ data }) {
              onSaveAndApplyAsset({
                assetUrl: data,
                keywordSet: preparedKeywords,
                ...formikValues,
                prices: (formikValues.prices || []).filter(
                  (price) =>
                    !!price.quantity || !!price.price || !!price.leadTime
                ),
              });
            },
          });
        } else {
          onSaveAndApplyAsset({
            keywordSet: preparedKeywords,
          });
        }
      }
    }
  };

  const onSave = async () => {
    const formikValues = await validateFormikFields();
    if (formikValues) {
      const preparedKeywords = keywords.map((el) => ({
        ...el,
        preselected: undefined,
      }));
      if (tempImages) {
        if (isArray(tempImages)) {
          uploadManyImages(tempImages, {
            onSuccess: async (data) => {
              setIsManyClassifying(true);
              const details = await Promise.all(
                data.map(async (el) => ({
                  details: (await getImageDetails(el.data))?.data || {},
                  assetUrl: el.data,
                }))
              );
              setIsManyClassifying(false);

              onSaveMany &&
                onSaveMany(
                  details.map((el) => ({
                    assetUrl: el.assetUrl,
                    keywordSet: getKeywordsFromUrlDetails(el.details),
                    name: "",
                    description: "",
                    prices: [],
                  }))
                );
            },
          });
        } else {
          if (uploadedUrl) {
            onSaveAsset &&
              onSaveAsset({
                assetUrl: uploadedUrl,
                keywordSet: preparedKeywords,
                ...formikValues,
                prices: (formikValues.prices || []).filter(
                  (price) =>
                    !!price.quantity || !!price.price || !!price.leadTime
                ),
              });
          } else {
            const data = new FormData();
            data.append("file", tempImages as File);

            uploadImage(data, {
              onSuccess({ data }) {
                onSaveAsset &&
                  onSaveAsset({
                    assetUrl: data,
                    keywordSet: preparedKeywords,
                    ...formikValues,
                    prices: (formikValues.prices || []).filter(
                      (price) =>
                        !!price.quantity || !!price.price || !!price.leadTime
                    ),
                  });
              },
            });
          }
        }
      } else if (urlToClassify) {
        onSaveAsset &&
          onSaveAsset({
            assetUrl: urlToClassify,
            shopifyId: asset?.shopifyId,
            keywordSet: preparedKeywords,
            ...formikValues,
          });
      } else {
        onSaveAsset &&
          onSaveAsset({
            keywordSet: preparedKeywords,
            ...formikValues,
          });
      }
    }
  };

  const onDeleteImage = (e: any) => {
    e.stopPropagation();
    setTempImages(null);
    onBack && onBack();
  };

  const onDeleteImageFromList = (fileName: string) => (e: any) => {
    e.stopPropagation();
    setTempImages((s: any) => {
      if (s.length) {
        const newState = s.filter((el: File) => el.name !== fileName);
        return newState.length ? newState : null;
      }
    });
  };

  const onSetUrlToClassify = async (url: string) => {
    if (url.includes("shopify")) {
      const response = await fetch(url);
      const blob = await response.blob();

      const file = new File([blob], "image.jpg", {
        type: "image/jpeg",
      });

      const data = new FormData();
      data.append("file", file);

      uploadShopifyImage(data, {
        onSuccess({ data }) {
          setUrlToClassify(data);
        },
      });
    } else {
      setUrlToClassify(url);
    }
  };

  useEffect(() => {
    if (asset?.assetUrl.includes("shopify")) {
      onSetUrlToClassify(asset?.assetUrl);
    }
  }, []);

  const onBackClick = () => {
    setTempImages(null);
    setKeywords([]);
    onBack && onBack();
  };

  return (
    <div className={cn("pr-2", className)}>
      <div className="pb-6 text-title text-base font-bold leading-tight pr-2">
        {t("asset_details")}
      </div>
      <FormikProvider value={formik}>
        <div className="flex items-start 5xl:flex-row 4xl:flex-row 3xl:flex-row 2.5xl:flex-row xl:flex-row lg:flex-row md:flex-col flex-col gap-4">
          <div className="mb-8">
            {isArray(tempImages) ? (
              <ul className="flex flex-wrap gap-4 mb-4 group">
                {tempImages?.map((el, index) => (
                  <li
                    className="w-[285px] h-[285px] flex relative cursor-pointer items-center justify-center rounded-2xl bg-[#DDD] group hover:border-border-hover border border-border-normal"
                    key={el.name + index.toString()}
                  >
                    <img
                      src={URL.createObjectURL(el)}
                      width={265}
                      className="max-h-[198px] object-contain"
                      alt="garment"
                    />
                    <button
                      disabled={saveLoading || uploading || manyLoading}
                      onClick={onDeleteImageFromList(el.name)}
                      className="absolute top-2 right-2 stroke-cobalt-500 hover:stroke-indigo-600 !invisible group-hover:!visible"
                    >
                      <Icon
                        component={TrashIcon}
                        viewBox="0 0 14 14"
                        width={20}
                        height={20}
                      />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <div>
                <DropZone
                  isOpenDialog={open}
                  onFileDialogCancel={onBackClick}
                  disabled={formDisabled}
                  accept={{
                    "image/*": [],
                  }}
                  onChange={onImageChange}
                  zoneComponent={({ isDragActive }) => (
                    <div
                      className={cn(
                        "flex w-[248px] h-[248px] relative cursor-pointer group hover:border-border-hover border border-border-normal items-center justify-center rounded-2xl",
                        formDisabled && "cursor-auto"
                      )}
                    >
                      {tempImages || assetUrl ? (
                        <>
                          {tempImages ? (
                            <img
                              src={URL.createObjectURL(tempImages)}
                              width={265}
                              className="max-h-[198px] object-contain"
                              alt="garment"
                            />
                          ) : (
                            <ImageResponsive
                              url={assetUrl}
                              width={265}
                              className="max-h-[198px] object-contain"
                              alt="garment"
                            />
                          )}
                          {!!tempImages && (
                            <button
                              onClick={onDeleteImage}
                              className="absolute top-2 right-2 stroke-cobalt-500 hover:stroke-indigo-600 invisible group-hover:!visible"
                            >
                              <Icon
                                component={TrashIcon}
                                viewBox="0 0 14 14"
                                width={20}
                                height={20}
                              />
                            </button>
                          )}
                        </>
                      ) : (
                        <div className="flex flex-col items-center">
                          <Icon
                            component={UploadIcon}
                            width={23}
                            height={23}
                            className={
                              isDragActive
                                ? "stroke-indigo-700"
                                : "stroke-cobalt-400 group-hover:stroke-cta-600"
                            }
                            viewBox="0 0 12 12"
                          />
                          <p
                            className={cn(
                              "text-gray-600 text-xs mt-7 group-hover:text-indigo-600",
                              isDragActive && "text-indigo-600"
                            )}
                          >
                            {t("upload_image")}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                />
                {!shopifyImageUploading &&
                  !!assetUrl &&
                  !urlToClassify &&
                  !formDisabled && (
                    <CtaButton
                      variant="secondary"
                      onClick={() => onSetUrlToClassify(assetUrl)}
                      className="!mt-4 !text-cta-600 !py-2"
                      iconLeft={AIStreammingIcon}
                      iconLeftProps={{
                        viewBox: "0 0 16 16",
                        width: 16,
                        height: 16,
                        className: "fill-cta-600 !mr-0",
                      }}
                    >
                      {t("classify_image")}
                    </CtaButton>
                  )}
                {((tempImages && !isArray(tempImages)) ||
                  urlToClassify ||
                  shopifyImageUploading) &&
                  !formDisabled && (
                    <AutoClassificationView
                      loading={
                        autoClassificationsLoading ||
                        isForcedClassificationsLoading ||
                        shopifyImageUploading
                      }
                      data={classifications?.data}
                      errors={classifications?.errors}
                      isRequestError={
                        isAutoClassificationsError ||
                        isForcedClassificationsError
                      }
                      isAutomatic={!urlToClassify}
                      className="mt-4"
                      onApply={onApplyImageClassification}
                    />
                  )}
              </div>
            )}
            <div className="grid grid-cols-2 w-full gap-2 max-w-[285px]">
              {!!onCreateAiProject && !isGuest && !isViewer && (
                <Tooltip
                  className="!w-fit"
                  content={t("create_ai_from_sample_desc")}
                >
                  <CtaButton
                    className={cn(
                      "!w-fit whitespace-nowrap !py-2 !text-cta-600"
                    )}
                    variant="secondary"
                    onClick={() => onCreateAiProject(asset!)}
                    spinner={createProjectLoading}
                    iconLeft={AIStreammingIcon}
                    iconLeftProps={{
                      viewBox: "0 0 16 16",
                      width: 16,
                      height: 16,
                      className: "fill-cta-600 !mr-0",
                    }}
                  >
                    {t("create_ai_project")}
                  </CtaButton>
                </Tooltip>
              )}
            </div>
          </div>
          {(!!assetUrl || (!!tempImages && !isArray(tempImages))) && (
            <div className="ml-2 w-full max-w-[292px]">
              <div className="flex">
                <div className="w-full">
                  <FormikInput
                    disabled={formDisabled}
                    label={t("asset_name")}
                    name="name"
                    className="!bg-transparent"
                  />
                  <FormikInput
                    disabled={formDisabled}
                    placeholder={t("asset_description")}
                    name="description"
                    className="!bg-transparent"
                  />
                  <div className="text-sm leading-tight font-medium text-title">
                    {t("style_details")}
                  </div>
                  <MediaStyleDetailsFilters
                    className="mt-2 grid xxs:grid-cols-1 lg:!grid-cols-2 gap-2"
                    disabled={formDisabled}
                    keywords={keywords}
                    onChange={addKeyword}
                  />
                  {!isGuest ? (
                    <div className="w-full mt-2 grid gap-2 xxs:grid-cols-1 lg:!grid-cols-2">
                      <TrendSelect
                        onSelect={addKeyword("color")}
                        options={colorOptions}
                        selectedItem={colorValue}
                        className="w-full"
                        textClassName="body-100"
                        buttonClassName="md:w-[116px] xxs:w-full !py-[8.5px]"
                        color
                        disabled={formDisabled}
                        optionsContainerClassName="!w-fit !max-h-[130px]"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-end gap-4 mt-6 pb-6">
          {!disableSave && (!onApplyAsset || !!isEditted || !!tempImages) && (
            <>
              <Tooltip className="!w-fit" content={t("")}>
                <CustomButton
                  variant="secondary"
                  className="w-full !py-2"
                  onClick={() =>
                    asset?.id ? onDelete && onDelete(asset.id) : onBackClick()
                  }
                  label={asset?.id ? t("Delete") : t("Cancel")}
                />
              </Tooltip>
              {(onSaveAsset || onSaveMany || onSaveAndApplyAsset) && (
                <Tooltip className="!w-fit" content={t("save_to_media_lib")}>
                  <CustomButton
                    className={cn(
                      "w-full !py-2",
                      isArray(tempImages) && "!w-fit"
                    )}
                    onClick={onSaveAndApplyAsset ? onSaveAndApply : onSave}
                    isLoading={
                      saveLoading ||
                      uploading ||
                      manyLoading ||
                      applyLoading ||
                      isManyClassifying
                    }
                    label={t("save")}
                  />
                </Tooltip>
              )}
            </>
          )}
          {!!asset &&
            ((!isEditted && !tempImages) || disableSave) &&
            onApplyAsset && (
              <Tooltip
                className="!w-fit"
                content={applyTipText || t("apply_to_filters")}
              >
                <CtaButton
                  onClick={() => onApplyAsset(asset)}
                  spinner={applyLoading}
                  className="w-full !py-2"
                >
                  {capitalize(t("apply"))}
                </CtaButton>
              </Tooltip>
            )}
        </div>
      </FormikProvider>
    </div>
  );
};

export default MediaAssetForm;
