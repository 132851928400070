import { useApi } from "contexts/ApiContext";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  AIDesignAssetType,
  CreateAssetPayload,
  DeleteAIDesignAssetsPayload,
  ShareAssetPayload,
} from "api/AIDesignAssetsApi";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";
import { PaginatedResponse } from "types";

export const useGetAssets = (
  teamId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useQuery<AIDesignAssetType[]>(
    ["ai-design-project-assets", teamId, params],
    () => aiDesignAssets.getAssets(params).then((res) => res?.data),
    config
  );
};

export const useGetRemainingAssets = (
  teamId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useInfiniteQuery<PaginatedResponse<AIDesignAssetType[]>>(
    ["ai-design-project-remaining-assets", teamId, params],
    ({ pageParam = 1 }) =>
      aiDesignAssets.getRemainingAssets({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useCreateAsset = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useMutation(
    (payload: CreateAssetPayload) => aiDesignAssets.createAsset(payload),
    config
  );
};

export const useCreateManyAssets = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: CreateAssetPayload[]) =>
      api.aiDesignAssets.createManyAssets(payload),
    config
  );
};

export const useDeleteAsset = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useMutation(
    (payload: DeleteAIDesignAssetsPayload) =>
      aiDesignAssets.deleteAsset(payload),
    config
  );
};

export const useEditAsset = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useMutation(
    ({ id, payload }: { id: number; payload: Partial<CreateAssetPayload> }) =>
      aiDesignAssets.editAsset(id, payload),
    config
  );
};

export const useShareAsset = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useMutation(
    ({ id, payload }: { id: number; payload: Partial<ShareAssetPayload> }) =>
      aiDesignAssets.shareAsset(id, payload),
    config
  );
};

export const useUndoSharingAsset = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useMutation(
    ({ id, payload }: { id: number; payload: Partial<ShareAssetPayload> }) =>
      aiDesignAssets.undoSharingAsset(id, payload),
    config
  );
};

export const useReplaceSharing = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignAssets } = useApi();
  return useMutation(
    ({ id, payload }: { id: number; payload: Partial<ShareAssetPayload> }) =>
      aiDesignAssets.replaceSharing(id, payload),
    config
  );
};
