import Dropdown from "components/atoms/DropDown";
import { useTeam } from "contexts/TeamContext";
import React, { useMemo } from "react";
import cn from "classnames";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { TeamRoleEnum } from "types";
import CheckIcon from "public/icons/check_16.svg";
import Icon from "../atoms/Icon";
import capitalize from "lodash/capitalize";
import ChevronDown from "public/icons/chevron_down_16.svg";
import { useEffect } from "react";
import { useGetTeams, useSetCurrentTeam, useSetTeamVisited } from "hooks/teams";
import Team from "models/Team";
import PlusIcon from "public/icons/plus_18.svg";
import sortBy from "lodash/sortBy";
import { useErrorHandler } from "hooks/useErrorHandler";
import { isOwnerUser } from "utils/isOwnerUser";
import { warning } from "utils/toast";
import { usePortal } from "contexts/PortalContext";
import Tooltip from "components/atoms/Tooltip";
import { useUser } from "contexts/UserContext";
import { usePrepareInfiniteData } from "hooks/usePrepareInfiniteData";
import Avatar from "components/atoms/Avatar";
import { JELLI_NAME } from "utils/constants";
import BigSpinner from "components/atoms/BigSpinner";
import pluralize from "pluralize";

interface ITeamDropdownProps {
  dropdownClassName?: string;
  triggerClassName?: string;
  createTeamDisabled?: boolean;
  isExpanded: boolean;
}

const TeamItem: React.FC<{
  team: Team;
  active?: boolean;
  onSelect: (id: number) => void;
  loading?: boolean;
}> = ({ team, onSelect, active, loading }) => {
  const { t } = useTranslation("common");

  return (
    <div className="px-2">
      <div
        onClick={() => onSelect(team.id)}
        className={cn(
          "cursor-pointer w-full overflow-hidden px-3 text-sm flex rounded-md items-center justify-between py-2 hover:bg-gray-20"
        )}
      >
        <div className="flex items-center max-w-[70%]">
          <Avatar
            className="!w-7 !h-7 shrink-0 mr-2 bg-gray-50 text-gray-950"
            fullName={team.name}
            image={team.logo?.url}
          />
          <div className="w-full">
            <Tooltip className="!w-fit !p-2" content={team.name}>
              <p className="whitespace-nowrap overflow-hidden text-ellipsis body-100">
                {team.name}
              </p>
            </Tooltip>
            <p className="hint-100 whitespace-nowrap !text-gray-600">
              {`${t(capitalize(TeamRoleEnum[team.users?.[0]?.teamRole]))} • ${
                team.teamMembersCount
              } ${pluralize(t("member"), team.teamMembersCount)}`}
            </p>
          </div>
        </div>
        <div className="shrink-0 w-4">
          {active && (
            <>
              {loading ? (
                <BigSpinner className="w-4 h-4" />
              ) : (
                <Icon
                  viewBox="0 0 16 17"
                  width={16}
                  height={16}
                  className="!stroke-gray-950"
                  component={CheckIcon}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const TeamDropdown: React.FC<ITeamDropdownProps> = ({
  dropdownClassName,
  triggerClassName,
  createTeamDisabled,
  isExpanded,
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { onError } = useErrorHandler();

  const isTeamRoute = router.pathname.includes("[teamId]");
  const isSubscriptionsPage = router.pathname === "/subscriptions";
  const isSubscriptionExpiredPage = router.pathname.includes(
    "/subscription-expired"
  );
  const { portalName, userPortal, isPortal, userPortalFetched } = usePortal();
  const { user } = useUser();

  const { currentTeam, refetchTeam } = useTeam();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch: refetchTeams,
  } = useGetTeams({
    teamId: currentTeam?.id!,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage]);

  const teams = usePrepareInfiniteData<Team>(data);

  const disableCreateTeam = isPortal
    ? !userPortal?.isCurrentUserVerified
    : null;
  // const supportedTeamId = teams?.reduce(
  //   (acc, team) => ({ ...acc, [team.id]: team.id }),
  //   {}
  // );
  const pathAfterTeam =
    "/" +
    router.asPath
      .split("/")
      .filter((_, index) => index > 2)
      .join("/");

  const defaultTeam = teams?.find((el) => el.defaultTeam);

  const { mutate: setTeamVisited } = useSetTeamVisited({
    onSuccess() {
      refetchTeams();
    },
  });

  const {
    mutate: setCurrentTeam,
    isSuccess,
    isLoading,
  } = useSetCurrentTeam({
    async onSuccess(_, teamId: number) {
      await refetchTeam();
      refetchTeams();
      if (isSubscriptionsPage || isSubscriptionExpiredPage) {
        router.push(`/${portalName}/${teamId}`);
      }

      if (isTeamRoute) {
        router.push(`/${portalName}/${teamId + pathAfterTeam}`);
      }
    },
    onError,
  });

  useEffect(() => {
    if (currentTeam?.newTeam) {
      setTeamVisited();
    }
  }, [currentTeam]);

  const sortedTeams = useMemo(() => {
    if (teams && currentTeam) {
      const filteredTeams = teams.filter((el) => el.users[0]?.inviteAccepted);
      const sortedTeams = sortBy(filteredTeams, [
        (el) => el.users?.[0]?.teamRole,
        (el) => el.name.toLowerCase(),
      ]);
      return sortedTeams;
    }

    return [];
  }, [teams, currentTeam]);

  const onSelectTeam = (teamId: number) => {
    setCurrentTeam(teamId);
  };

  useEffect(() => {
    if (isSubscriptionsPage && !isSuccess) {
      const isOwner = isOwnerUser(currentTeam?.teamRole);
      if (!isOwner && defaultTeam) {
        setCurrentTeam(defaultTeam.id, {
          onSuccess() {
            warning({ message: t("your_team_changed_to_default") });
          },
        });
      }
    }
  }, [isSubscriptionsPage, isSuccess, defaultTeam, currentTeam]);

  return (
    <div className="w-full flex items-center justify-center">
      <Dropdown
        dropdownClassname={cn(
          "w-[240px] !top-[48px] px-0 !pt-2 !pb-0 left-0 rounded-xl max-w-[240px] !max-h-[400px] overflow-y-auto !gap-0 z-50",
          dropdownClassName
        )}
        trigger={
          <div className="cursor-pointer group">
            <div
              className={cn(
                "group-hover:bg-gray-20 w-[178px] transition-all duration-200 rounded-md ease-in-out px-2 py-0.5 overflow-hidden hidden",
                isExpanded && "!block",
                triggerClassName
              )}
            >
              <p className="text-xxs text-left">
                {isPortal
                  ? userPortalFetched
                    ? userPortal?.supplier?.name
                    : "loading..."
                  : JELLI_NAME}
              </p>
              <div className="flex w-full items-center justify-between">
                <Tooltip className="!w-fit" content={currentTeam?.name || ""}>
                  <p className="max-w-[152px] whitespace-nowrap font-bold overflow-hidden text-ellipsis text-base leading-tight">
                    {currentTeam?.name}
                  </p>
                </Tooltip>
                <Icon
                  component={ChevronDown}
                  width={12}
                  height={12}
                  viewBox="0 0 16 16"
                  fill="black"
                  className="shrink-0"
                />
              </div>
            </div>
            <Tooltip className="!w-fit" content={currentTeam?.name as string}>
              <Avatar
                className={cn(
                  "w-8 h-8 block shrink-0 bg-gray-50 text-gray-950",
                  isExpanded && "!hidden"
                )}
                fullName={currentTeam?.name}
                image={currentTeam?.logo?.url}
              />
            </Tooltip>
          </div>
        }
        itemComponent={({ item }) => (
          <TeamItem
            team={item}
            onSelect={onSelectTeam}
            active={currentTeam?.id === item?.id}
            loading={isLoading}
          />
        )}
        footer={
          createTeamDisabled ? null : (
            <div
              onClick={(e) => {
                if (!disableCreateTeam) {
                  e.stopPropagation();
                  router.push(
                    `/${portalName}/${currentTeam?.id}/team?create=true`
                  );
                }
              }}
              className={cn(
                "border-t py-2 px-2 group cursor-pointer border-border-normal sticky -bottom-[1px] bg-white z-50",
                disableCreateTeam && "cursor-auto"
              )}
            >
              <Tooltip
                content={disableCreateTeam ? t("admin_cant_create_portal") : ""}
              >
                <div
                  className={cn(
                    "px-3 py-2 group-1 body-100 flex justify-start items-center rounded-md gap-3",
                    {
                      "group-hover:bg-gray-20": !disableCreateTeam,
                      "text-gray-400": disableCreateTeam,
                    }
                  )}
                >
                  <Icon
                    component={PlusIcon}
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    className={
                      disableCreateTeam ? "fill-gray-400" : "fill-gray-950"
                    }
                  />
                  {t("create_team")}
                </div>
              </Tooltip>
            </div>
          )
        }
        items={sortedTeams}
      />
    </div>
  );
};

export default TeamDropdown;
