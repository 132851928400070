import React, { useCallback, useEffect } from "react";
import { DropzoneProps, useDropzone } from "react-dropzone";
import cn from "classnames";
import UploadIcon from "public/icons/upload.svg";
import Icon from "./Icon";
import { getShortFileName } from "utils/getShortFileName";
import { error } from "utils/toast";
import isNumber from "lodash/isNumber";
import isNan from "lodash/isNaN";
import { isUndefined } from "lodash";
import { MAX_FILE_SIZE } from "utils/constants";

const normalizeErrorText = (error: string) => {
  const splitted = error.split(" ");
  const el = splitted.find((el) => !isNan(+el) && isNumber(+el));

  if (!isUndefined(el)) {
    return `Larger than ${+el / 1000000}Mb`;
  }
};

export interface IDropZoneProps extends DropzoneProps {
  isOpenDialog?: boolean;
  onChange: (files: File[]) => void;
  onFileDialogCancel?: () => void;
  className?: string;
  buttonClassName?: string;
  errorMessage?: string;
  zoneComponent?: (props: {
    isDragActive: boolean;
    open: Function;
  }) => React.ReactNode;
}

const DropZone: React.FC<IDropZoneProps> = ({
  isOpenDialog,
  onChange,
  onFileDialogCancel,
  buttonClassName,
  zoneComponent: ZoneComponent,
  className,
  errorMessage,
  maxSize = MAX_FILE_SIZE,
  ...props
}) => {
  const onDrop = useCallback((files) => {
    if (files?.length) {
      onChange(files);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop,
      onFileDialogCancel,
      maxSize,
      ...props,
    });

  useEffect(() => {
    if (fileRejections?.length) {
      const fileNames = fileRejections
        .map((el) => getShortFileName(el.file, 15))
        .join(", ");
      const errorText = fileRejections[0]?.errors[0]?.message;

      error({
        message: `${fileNames} ${
          fileRejections.length > 1 ? "are" : "is"
        } not uploaded. ${normalizeErrorText(errorText)}`,
        contentClassName: "line-clamp-none",
      });
    }
  }, [fileRejections]);

  useEffect(() => {
    if (isOpenDialog) {
      open();
    }
  }, []);

  return (
    <div className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      {ZoneComponent ? (
        <>
          {ZoneComponent({ isDragActive, open })}
          {!!errorMessage && <p className={cn("error-100")}>{errorMessage}</p>}
        </>
      ) : (
        <div
          className={cn(
            "rounded-full cursor-pointer w-8 h-8 flex items-center group hover:border-cta-600 justify-center border border-gray-900",
            isDragActive && "border-indigo-700 animate-ping",
            buttonClassName
          )}
        >
          <Icon
            component={UploadIcon}
            className={
              isDragActive
                ? "stroke-indigo-700"
                : "stroke-gray-900 group-hover:stroke-cta-600"
            }
            viewBox="0 0 12 12"
            width={12}
            height={12}
          />
        </div>
      )}
    </div>
  );
};

export default DropZone;
