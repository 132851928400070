import cn from "classnames";
import SelectButton from "components/atoms/SelectButton";
import React, { useEffect, useState } from "react";
import MoreIcon from "public/icons/more-vertical.svg";
import BenchmarkIcon from "public/icons/benchmark_18.svg";
import AIStreammingIcon from "public/icons/ai_generate_16.svg";
import PenIcon from "public/icons/pencil_16.svg";
import useTranslation from "next-translate/useTranslation";
import Pagination from "../Pagination";
import { Img, useImage } from "react-image";
import SkeletonLoading from "components/atoms/SkeletonLoading";
import { AIDesignAssetType } from "api/AIDesignAssetsApi";

interface ShopifyCatalogueTableProps {
  tableData: {
    id: string;
    image: string[];
    brandName: string;
    productName: string;
    details: AIDesignAssetType;
    uploadedDate: string;
  }[];
  handleOpenBenchmark?: Function;
  handleOpenBenchmarkWithImgUrl?: Function;
  onCreateAiProject?: Function;
  onOpenDetails?: Function;
  step: number;
  setStep: Function;
  totalDataLength: number;
}

export default function ShopifyCatalogueTable({
  tableData,
  handleOpenBenchmark,
  onCreateAiProject,
  onOpenDetails,
  handleOpenBenchmarkWithImgUrl,
  step,
  setStep,
  totalDataLength,
}: ShopifyCatalogueTableProps) {
  const { t } = useTranslation("common");
  const [movePageValue, setMovePageValue] = useState<string>(
    (step + 1).toString()
  );

  useEffect(() => {
    setMovePageValue((step + 1).toString());
  }, [step]);

  return (
    <div className="relative flex flex-col">
      <div className="overflow-auto thin-height-scrollbar my-2 pb-30">
        <div className="inline-block w-full rounded-2xl overflow-hidden">
          <div className="rounded-2xl border border-border-normal overflow-x-scroll extra-thin-height-scrollbar">
            <table className="min-w-full text-center text-sm font-light">
              <thead className="border-b bg-white text-typography-body-subtle rounded-t-2xl text-xs">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium whitespace-nowrap rounded-tl-2xl"
                  >
                    Shopify product ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium w-[64px] whitespace-nowrap"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium whitespace-nowrap"
                  >
                    Brand Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium whitespace-nowrap"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium whitespace-nowrap"
                  >
                    Details
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium whitespace-nowrap"
                  >
                    Uploaded Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap rounded-tr-2xl"
                  />
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => (
                  <TableRow
                    key={index}
                    item={item}
                    border={index < tableData.length - 1}
                    onCreateAiProject={onCreateAiProject}
                    handleOpenBenchmark={handleOpenBenchmark}
                    handleOpenBenchmarkWithImgUrl={
                      handleOpenBenchmarkWithImgUrl
                    }
                    onOpenDetails={onOpenDetails}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-end gap-4 mt-6">
          <div className="flex items-center justify-end gap-1">
            <div className="text-sm">Move page: </div>
            <input
              type="text"
              value={Number(movePageValue)}
              className="border border-border-normal rounded-md w-10 text-center text-sm"
              onChange={(e) => setMovePageValue(e.target.value)}
              onBlur={(e) => setStep(Number(e.target.value) - 1)}
            />
            <div className="text-sm">
              of {Math.floor(totalDataLength / 10)} pages
            </div>
          </div>
          <Pagination
            activePage={step}
            onChange={(page) => setStep(page)}
            totalPages={Math.floor(totalDataLength / 10) || 0}
            maxButton
          />
        </div>
      </div>
    </div>
  );
}

interface TableRowProps {
  item: {
    id: string;
    image: string[];
    brandName: string;
    productName: string;
    details: AIDesignAssetType;
    uploadedDate: string;
  };
  border?: boolean;
  handleOpenBenchmark?: Function;
  handleOpenBenchmarkWithImgUrl?: Function;
  onCreateAiProject?: Function;
  onOpenDetails?: Function;
}

const TableRow: React.FC<TableRowProps> = ({
  item,
  border = false,
  handleOpenBenchmark,
  handleOpenBenchmarkWithImgUrl,
  onCreateAiProject,
  onOpenDetails,
}) => {
  const { src } = useImage({
    srcList: [...item.image, "/images/no-data.png"],
    useSuspense: false,
  });

  const { t } = useTranslation("common");

  return (
    <tr
      key={item.id}
      className={cn(
        "dark:border-gray-40 group hover:bg-gray-20",
        border && "border-b"
      )}
    >
      <td className="whitespace-nowrap px-6 py-4 font-medium">{item.id}</td>
      <td className="py-4">
        <Img
          src={[...item.image, "/images/no-data.png"]}
          loader={<SkeletonLoading width={50} height={50} />}
          className="w-[50px] h-[50px] mx-auto"
        />
      </td>
      <td className="whitespace-nowrap px-6 py-4">{item.brandName}</td>
      <td className="whitespace-nowrap px-6 py-4">{item.productName}</td>
      <td className="px-6 py-4 max-w-[250px] w-full">
        {item.details?.keywordSet?.keywordSet
          .filter((item) => !!item.value)
          .map((item) => item.value)
          .join(", ")}
      </td>
      <td className="whitespace-nowrap px-6 py-4">{item.uploadedDate}</td>
      <td className="whitespace-nowrap px-6 py-4 w-[95px]">
        <SelectButton
          icon={MoreIcon}
          options={[
            {
              icon: BenchmarkIcon,
              title: t("view_benchmark"),
              onClick: () => {
                if (item.details) {
                  handleOpenBenchmark && handleOpenBenchmark(item.details);
                } else {
                  handleOpenBenchmarkWithImgUrl &&
                    handleOpenBenchmarkWithImgUrl(item.image);
                }
              },
              iconProps: {
                viewBox: "0 0 18 18",
              },
            },
            {
              icon: AIStreammingIcon,
              iconProps: {
                viewBox: "0 0 16 16",
              },
              onClick: () => {
                onCreateAiProject &&
                  onCreateAiProject(
                    item.details || {
                      assetUrl: src,
                      name: item.productName,
                    }
                  );
              },
              title: t("generate_ai_design"),
            },
            {
              icon: PenIcon,
              iconProps: {
                viewBox: "0 0 16 16",
              },
              onClick: () => {
                onOpenDetails &&
                  onOpenDetails(
                    item.details || {
                      shopifyId: item.id,
                      assetUrl: src,
                      name: item.productName,
                    }
                  );
              },
              title: t("edit_details"),
            },
          ]}
          iconClassName="rotate-90"
          buttonClassName="border-0 hover:border-0 active:border-0 bg-gray-30 active:bg-gray-40 hover:bg-gray-40"
          listClassName="min-w-[165px] gap-0"
          className={cn("invisible group-hover:visible")}
          wrapperMaxHeight={800}
          wrapperMaxWidth={1166}
          disabledTooltip
        />
      </td>
    </tr>
  );
};
