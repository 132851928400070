import { ICreatePortalPayload, IPortalBlacklistItem } from "api/PortalApi";
import { useApi } from "contexts/ApiContext";
import Portal from "models/Portal";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  IPaginateParams,
  ITeamStats,
  PaginatedResponse,
  TeamMember,
} from "types";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";

export const useGetPortals = (
  filters: string[],
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["portals", filters, params],
    ({ pageParam = 1 }) => {
      return api.portal.getPortals({ page: pageParam, filters, ...params });
    },
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useCreatePortal = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ICreatePortalPayload) => api.portal.createPortal(payload),
    config
  );
};

export const useEditPortal = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: Partial<Portal> & { id: number }) =>
      api.portal.editPortal(payload),
    config
  );
};

export const useDeletePortal = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.portal.deletePortal(id), config);
};

export const useGetPortalSamples = (
  portalId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["portal-samples", portalId, params],
    ({ pageParam = 1 }) =>
      api.portal.getPortalSamples({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPortalUsersStatistics = (
  params: IPaginateParams,
  sortString: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["portal-users-statistics", params, sortString],
    () => api.portal.getPortalUsersStatistics(params, sortString),
    config
  );
};

export const useGetInfinitePortalUsersStatistics = (
  params: IPaginateParams,
  sortString: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["infinite-portal-users-statistics", params, sortString],
    ({ pageParam = 1 }) =>
      api.portal.getPortalUsersStatistics(
        { page: pageParam, ...params },
        sortString
      ),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPortalUserTeammates = (
  userId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["portal-user-teammates", userId, params],
    ({ pageParam = 1 }) =>
      api.portal.getPortalUserTeammates(userId, {
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPortalSignUpCode = (
  portalId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["portal-signup-code", portalId],
    () => api.portal.getPortalSignUpCode(),
    config
  );
};

export const useGetPortalMaintainers = (
  portalId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["maintainers", portalId, params],
    ({ pageParam = 1 }) =>
      api.portal.getPortalMaintainers({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPortalByUrlSlug = (
  url: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Portal>(
    ["portal-by-domain", url],
    () => api.portal.getPortalByPortalUrlSlug(url),
    config
  );
};

export const useGetUserPortals = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["user-portals", params],
    ({ pageParam = 1 }) =>
      api.portal.getUserPortals({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useDeletePortalUser = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (userId: number) => api.portal.deletePortalUser(userId),
    config
  );
};

export const useGetBlacklist = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<IPortalBlacklistItem>>(
    ["portal-blacklist", params],
    ({ pageParam = 1 }) =>
      api.portal.getPortalBlacklist({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useAddEmailToBlacklist = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (email: string) => api.portal.addEmailToBlacklist(email),
    config
  );
};

export const useDeleteBlacklistItem = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (id: number) => api.portal.deleteBlacklistItem(id),
    config
  );
};

export const useGetPortalBrandTeams = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["portal-brand-teams", params],
    ({ pageParam = 1 }) =>
      api.portal.getPortalBrandTeams({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPortalUserTeamsStatistic = (
  userId: number,
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<ITeamStats>>(
    ["portal-user-teams-statistic", userId, params],
    ({ pageParam = 1 }) =>
      api.portal.getPortalUserTeamsStatistic(userId, {
        ...params,
        page: pageParam,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPortalTeamMembers = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<TeamMember>>(
    ["portal-team-members", teamId],
    ({ pageParam = 1 }) =>
      api.portal.getPortalTeamMembers(teamId, {
        page: pageParam,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPortalTeamGuests = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<TeamMember>>(
    ["portal-team-guests", teamId],
    ({ pageParam = 1 }) =>
      api.portal.getPortalTeamGuests(teamId, {
        page: pageParam,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};
