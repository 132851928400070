import { useApi } from "contexts/ApiContext";
import { sortBy } from "lodash";
import uniqBy from "lodash/uniqBy";
import { useQuery, UseQueryOptions } from "react-query";
import { addInfiniteStale } from "utils/addInfiniteStale";

export const useGetCategoryFacets = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["categoryFacets"],
    () => api.global.query("/facets/cstats/datasources/daily/latest"),
    addInfiniteStale(config)
  );
};

export const useGetCategories = (
  gender: string,
  catgroup: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["categories", gender, catgroup],
    () =>
      api.global
        .query("/stats/gstats/daily/latest", {
          gender,
          catgroup,
          adjust_defaults: "False",
          colnames: "gender,catgroup,basecat,num_items",
        })
        .then((res) => {
          const adjusted = sortBy(
            uniqBy(
              res.filter((item: any) => item.basecat !== "_"),
              "basecat"
            ),
            "num_items"
          ).reverse();
          return adjusted;
        }),
    config
  );
};

export const useGetCategoryStats = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["category-stats", params],
    () => api.global.query("/stats/cstats/daily/latest", params),
    addInfiniteStale(config)
  );
};
