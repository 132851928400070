import CtaButton, { Variant } from "components/atoms/CtaButton";
import Modal, { IModalProps } from "components/molecules/Modal";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import cn from "classnames";

interface IConfirmDialogProps extends IModalProps {
  title: string;
  subtitle?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onDelete?: () => void;
  cancelText?: string;
  deleteText?: string;
  confirmText?: string;
  confirmVariant?: Variant;
  cancelVariant?: Variant;
  deleteVariant?: Variant;
  loading?: boolean;
  isDeleting?: boolean;
  titleClassName?: string;
  subtitleClassName?: string;
  buttonClassName?: string;
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  title,
  subtitle,
  onCancel,
  onDelete,
  onConfirm,
  cancelText,
  deleteText,
  confirmText,
  cancelVariant,
  deleteVariant,
  confirmVariant,
  children,
  loading,
  isDeleting,
  titleClassName,
  subtitleClassName,
  buttonClassName,
  ...props
}) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      className="max-w-[480px] w-full"
      contentClassName="sm:p-8 p-6"
      {...props}
      onRequestClose={props.onClose}
    >
      <h2
        className={cn(
          "headline-100 mb-6 sm:text-2xl text-lg overflow-hidden text-ellipsis",
          titleClassName
        )}
      >
        {title}
      </h2>
      {subtitle && (
        <p className={cn("mb-10 body-100", subtitleClassName)}>{subtitle}</p>
      )}

      {children}

      {onCancel || onConfirm || onDelete ? (
        <div className="flex items-center justify-end">
          {onDelete && (
            <CtaButton
              onClick={onDelete}
              spinner={isDeleting}
              variant={deleteVariant || "danger"}
              className={cn("capitalize mr-2 !py-2", buttonClassName)}
            >
              {deleteText || t("Delete")}
            </CtaButton>
          )}
          {onCancel && (
            <CtaButton
              onClick={onCancel}
              variant={cancelVariant || "secondary"}
              className={cn("capitalize mr-2 !py-2", buttonClassName)}
            >
              {cancelText || t("cancel")}
            </CtaButton>
          )}
          {onConfirm && (
            <CtaButton
              onClick={onConfirm}
              spinner={loading}
              variant={confirmVariant || "danger"}
              className={cn("capitalize !py-2", buttonClassName)}
            >
              {confirmText || t("confirm")}
            </CtaButton>
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default ConfirmDialog;
