import { useMemo, useState } from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import { AIImageSearchResultType } from "hooks/useAiImageSearch";
import { BenchmarkProducts } from "./BenchmarkProducts";
import BigSpinner from "components/atoms/BigSpinner";
import BenchmarkAnalytics from "./BenchmarkAnalytics";
import DropdownText from "components/atoms/Benchmark/DropdownText";
import Icon from "components/atoms/Icon";
import SortDownIcon from "public/icons/sort_down.svg";
import SortUpIcon from "public/icons/sort_up.svg";
import { DropdownTextItemType } from "components/atoms/BrandDropdownText";
import ImageResponsive from "components/atoms/ImageResponsive";
import { convertUSLocalString } from "utils/beautifyNumbers";
import { useEffect } from "react";

interface IBenchmarkOverviewProps {
  image?: string;
  isTakeLong: boolean;
  pageNumber?: number;
  filterCount?: number;
  isFilterOpen?: boolean;
  filterParams?: any;
  isLoadingAiImageData: boolean;
  benchmarkData?: AIImageSearchResultType;
  onSelectProduct: (product: any, pageNumber: number) => void;
  selectedTab?: string;
}

const BenchmarkOverview: React.FC<IBenchmarkOverviewProps> = ({
  image,
  isTakeLong,
  pageNumber,
  filterCount,
  isFilterOpen = false,
  filterParams,
  benchmarkData,
  onSelectProduct,
  isLoadingAiImageData,
  selectedTab,
}) => {
  const { t } = useTranslation("common");
  const [curTab, setCurTab] = useState<string>("products");
  const [orderBy, setOrderBy] = useState<DropdownTextItemType>({
    name: "",
    value: "desc",
  });
  const [sortKeyword, setSortKeyword] = useState<DropdownTextItemType>({
    name: t("Similarity"),
    value: "similarity",
  });

  const scores = useMemo(
    () => benchmarkData?.score || benchmarkData?.euclidian_distances || [],
    [benchmarkData]
  );

  useEffect(() => {
    if (selectedTab) {
      setCurTab(selectedTab);
    }
  }, [selectedTab]);

  const productIds = useMemo(() => {
    return benchmarkData?.similar_jbids || [];
  }, [benchmarkData?.similar_jbids]);

  const filtered_euclidian_data = useMemo(() => {
    const temp: { [jbid: string]: any } = [];
    if (benchmarkData) {
      for (let i = 0; i < benchmarkData?.similar_jbids?.length; i++) {
        temp[benchmarkData?.similar_jbids[i]] = {
          distance: scores[i],
          caption: benchmarkData.caption?.[i],
        };
      }
    }

    return temp;
  }, [benchmarkData]);

  const SortByOption = [
    {
      name: t("similarity"),
      value: "similarity",
    },
    {
      name: t("Price"),
      value: "price",
    },
    {
      name: t("date"),
      value: "date",
    },
  ];

  const orderByOption = useMemo(() => {
    if (sortKeyword?.value === "similarity") {
      return [
        {
          name: t("similar_first"),
          value: "desc",
        },
        {
          name: t("diverse_first"),
          value: "asc",
        },
      ];
    } else if (sortKeyword?.value === "price") {
      return [
        {
          name: t("low_first"),
          value: "asc",
        },
        {
          name: t("high_first"),
          value: "desc",
        },
      ];
    } else {
      return [
        {
          name: t("latest_first"),
          value: "desc",
        },
        {
          name: t("oldest_first"),
          value: "asc",
        },
      ];
    }
  }, [sortKeyword?.value]);

  return (
    <div className="w-full">
      <div className="xxs:hidden md:!flex items-center pb-4 justify-between gap-4">
        <div className="w-full flex items-center justify-start gap-4">
          <TabButton
            label={`${t("products")} (${convertUSLocalString(
              productIds?.length || 0
            )})`}
            active={curTab === "products"}
            onClick={() => setCurTab("products")}
          />
          <TabButton
            label={t("analytics")}
            active={curTab === "analytics"}
            onClick={() => setCurTab("analytics")}
          />
        </div>
        <DropdownText
          items={SortByOption}
          labelIcon={
            <Icon
              component={orderBy?.value === "desc" ? SortDownIcon : SortUpIcon}
              viewBox="0 0 16 16"
              width={16}
              height={16}
              className={cn("fill-gray-950 stroke-gray-950")}
            />
          }
          selectedItems={sortKeyword}
          onChange={setSortKeyword}
          menuSubTitle={t("SORT BY")}
          checkIcon={true}
          labelClassName="!text-xs"
          menuClassName="!max-h-fit !right-0"
          secondItems={orderByOption}
          secondMenuSubTitle={t("SORT BY")}
          secondSelectedItems={orderBy}
          onChangeSecondMenu={setOrderBy}
        />
      </div>
      <div className="relative w-full h-full">
        {curTab === "products" && (
          <div className="flex xxs:flex-col md:!flex-row xxs:items-center md:!items-start justify-start gap-4">
            <div className="w-[250px] xxs:py-10 md:!py-0 px-4 flex-none">
              {image && (
                <ImageResponsive url={image} className="w-full h-auto" />
              )}
            </div>
            {isLoadingAiImageData ? (
              <div className="flex flex-col items-center justify-center w-full h-[calc(100vh-290px)]">
                <div className="h-[120px]">
                  <BigSpinner />
                  {isTakeLong && t("ready_soon")}
                </div>
              </div>
            ) : (
              <BenchmarkProducts
                benchmarkData={benchmarkData}
                productIds={productIds}
                euclidianData={filtered_euclidian_data}
                onSelectProduct={onSelectProduct}
                orderKeyword={orderBy.value}
                sortKeyword={sortKeyword.value}
                pageNumber={pageNumber}
                isFilterOpen={isFilterOpen}
                filterCount={filterCount}
              />
            )}
          </div>
        )}
        {curTab === "analytics" && (
          <BenchmarkAnalytics
            filterParams={filterParams}
            benchmarkData={benchmarkData}
          />
        )}
      </div>
    </div>
  );
};

export default BenchmarkOverview;

interface ITabButtonProps {
  active: boolean;
  label: string;
  onClick: () => void;
}

const TabButton: React.FC<ITabButtonProps> = ({ active, label, onClick }) => (
  <div
    className={cn(
      "text-sm leading-normal text-typography-tag-passive py-2 cursor-pointer border-b-[1.5px] border-white",
      active && "text-typography-title !border-gray-950 font-semibold"
    )}
    onClick={onClick}
  >
    {label}
  </div>
);
