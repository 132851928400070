import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import MediaCard from "components/atoms/AIStreaming/MediaCard";
import DropdownText from "components/atoms/Benchmark/DropdownText";
import BigSpinner from "components/atoms/BigSpinner";
import { usePortal } from "contexts/PortalContext";
import { useTeam } from "contexts/TeamContext";
import { useUser } from "contexts/UserContext";
import { useGetPortalSamples } from "hooks/portal";
import useTranslation from "next-translate/useTranslation";
import { getImageAvailabilityFilterOptions } from "pages/[portalName]/[teamId]/media-lib";
import React, { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ISelectValue } from "types";
import BackButton from "../BackButton";
import SamplesFilter from "./SamplesFilter";

interface ISharedUserMediaSamplesProps {
  onSelect: (sample: AIDesignAssetType) => void;
  setImageAvailabilityFilter?: (val: ISelectValue) => void;
  selectedImageAvailabilityFilter: ISelectValue;
}

const SharedUserMediaSamples: React.FC<ISharedUserMediaSamplesProps> = ({
  onSelect,
  setImageAvailabilityFilter,
  selectedImageAvailabilityFilter,
}) => {
  const { t } = useTranslation("common");
  const [filter, setFilter] = useState<Record<string, ISelectValue>>({
    basecat: { name: t("all_categories") },
    gender: { name: t("all_genders") },
    color: { name: t("all_colors") },
  });
  const { userPortal } = usePortal();
  const { user } = useUser();
  const { isMaintainer } = useTeam();

  const { ref, inView } = useInView();

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetPortalSamples(
      userPortal?.id!,
      {
        limit: 20,
        "filter.team.portalId": userPortal?.id,
        filters: filter,
      },
      {
        enabled: !!userPortal?.id && !!user,
        keepPreviousData: true,
      }
    );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, inView]);

  const preparedSamples: AIDesignAssetType[] = useMemo(() => {
    if (data && data.pages.length) {
      return data.pages.reduce((acc, el) => acc.concat(el.data), []);
    }

    return [];
  }, [data]);

  const onSelectKeywordsFilter = (field: string) => (val: ISelectValue) => {
    setFilter((s) => ({ ...s, [field]: val }));
  };

  const imageAvailabilityFilterOptions = getImageAvailabilityFilterOptions(
    t,
    !!isMaintainer
  );

  return (
    <div>
      <h2 className="headline-200">
        {t("shared_samples_by", { companyName: userPortal?.supplier?.name })}
      </h2>
      <p className="body-100 mb-4">
        {t("shared_samples_by_desc", {
          companyName: userPortal?.supplier?.name,
        })}
      </p>
      <div className="mb-6 flex gap-4 xs:justify-end xxs:justify-start xxs:flex-wrap xs:flex-nowrap items-center">
        <SamplesFilter
          basecat={filter.basecat}
          gender={filter.gender}
          color={filter.color}
          onSelect={onSelectKeywordsFilter}
        />
        <DropdownText
          onChange={setImageAvailabilityFilter}
          label={imageAvailabilityFilterOptions[0].name}
          labelClassName="!text-xs whitespace-nowrap"
          className="mr-4"
          items={imageAvailabilityFilterOptions}
          selectedItems={selectedImageAvailabilityFilter}
        />
      </div>
      {preparedSamples.length ? (
        <div className="grid xl:!grid-cols-5 lg:!grid-cols-4 sm:!grid-cols-3 xs:grid-cols-2 xxs:grid-cols-1 gap-4">
          {preparedSamples?.map((asset) => (
            <div key={asset?.id}>
              <MediaCard asset={asset} onSelect={onSelect} />
            </div>
          ))}
          <div ref={ref}>
            {isFetchingNextPage && (
              <div className="w-full h-[90px] grid place-items-center">
                <BigSpinner className="w-10 h-10" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <p className="headline-300">{t("no_samples")}</p>
      )}
    </div>
  );
};

export default SharedUserMediaSamples;
