import { useApi } from "contexts/ApiContext";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  AIDesignProjectMoodboardType,
  AIDesignProjectType,
  CreateAIDesignProjectPayload,
  DeleteAIDesignProjectPayload,
  UpdateAIDesignProjectPayload,
} from "api/AIDesignProjectApi";

export const useGetProjects = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { aiDesignProject } = useApi();
  return useQuery<AIDesignProjectType[]>(
    ["ai-design-projects", teamId],
    () => aiDesignProject.getProjects(teamId).then((res) => res?.data),
    config
  );
};

export const useGetProjectMoodboard = (
  tvProjectId: number,
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { aiDesignProject } = useApi();
  return useQuery<AIDesignProjectMoodboardType[]>(
    ["ai-design-project-moodboard", teamId, tvProjectId],
    () =>
      aiDesignProject
        .getProjectMoodboard(teamId, tvProjectId)
        .then((res) => res?.data),
    config
  );
};

export const useCreateProject = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignProject } = useApi();
  return useMutation(
    (payload: CreateAIDesignProjectPayload) =>
      aiDesignProject.createProject(payload),
    config
  );
};

export const useChangeProjectTitle = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignProject } = useApi();
  return useMutation(
    (payload: UpdateAIDesignProjectPayload) =>
      aiDesignProject.updateProjectName(payload),
    config
  );
};

export const useDeleteAIDesignProject = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { aiDesignProject } = useApi();
  return useMutation(
    (payload: DeleteAIDesignProjectPayload) =>
      aiDesignProject.deleteProject(payload),
    config
  );
};

export const useCheckInvite = (
  token: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<AIDesignProjectType>(
    ["ai-design-project-invite", token],
    () => api.aiDesignProject.checkInvite(token),
    config
  );
};

export const useCheckPortalTvProjectInvite = (
  token: string,
  invitationCode: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<AIDesignProjectType>(
    ["ai-design-portal-project-invite", token, invitationCode],
    () =>
      api.aiDesignProject.checkPortalInvite(
        token,
        encodeURIComponent(invitationCode)
      ),
    config
  );
};

export const useRemoveGuestFromTvProject = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { projectId: number; userId: number }) =>
      api.aiDesignProject.removeGuestFromTvProject(payload),
    config
  );
};

export const useResendTvProjectInvite = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    ({ projectId, email }: { projectId: number; email: string }) =>
      api.aiDesignProject.resendTvProjectInvite(projectId, email),
    config
  );
};

export const useGetTvProjectById = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<AIDesignProjectType>(
    ["ai-project", id],
    () => api.aiDesignProject.getTvProjectById(id),
    config
  );
};

export const useGetAllMoodboards = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["ai-projects-moodboards"],
    () => api.aiDesignProject.getMoodboards(),
    config
  );
};

export const useGetSharedMoodboards = (
  params: { teamId: number; projectIds?: string },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["ai-shared-moodboards", params],
    () => api.aiDesignProject.getSharedMoodboards(params),
    config
  );
};
