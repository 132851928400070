import React from "react";
import cn from "classnames";

const SmallSpinner = ({
  className = "",
  style,
  stroke = "#fff",
  svgClassName,
}: {
  className?: string;
  style?: any;
  stroke?: string;
  svgClassName?: string;
}) => {
  return (
    <div
      style={style}
      className={cn("flex items-center justify-center", className)}
    >
      <svg
        className={cn("inline w-20 h-20 text-white animate-spin", svgClassName)}
        width="64"
        height="64"
        viewBox="0 0 84 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="42" cy="42" r="40" stroke="#341BF9" strokeWidth="4" />
        <path
          d="M73.9895 17.9813C66.691 8.27637 55.0788 2 42.0006 2C28.9225 2 17.3102 8.27637 10.0117 17.9813"
          stroke={stroke}
          strokeWidth="6"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default SmallSpinner;
