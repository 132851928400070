import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import {
  AddAssetFolderPayload,
  AssetFolderPayload,
  AssetFolderType,
  CreateAssetFolderPayload,
} from "api/AssetFoldersApi";
import { useApi } from "contexts/ApiContext";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";

export const useGetAssetFolders = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useQuery<{ data: AssetFolderType[] }>(
    ["get-asset-folders", teamId],
    () => assetFolder.getAssetFolders(teamId),
    config
  );
};

export const useGetAssetFolderById = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useQuery<AssetFolderType>(
    ["get-asset-folder", id],
    () => assetFolder.getAssetFolder(id),
    config
  );
};

export const useGetAssetSubFolders = (
  assetFolderId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useQuery<AssetFolderType[]>(
    ["get-asset-folders", assetFolderId],
    () => assetFolder.getAssetSubFolders(assetFolderId),
    config
  );
};

export const useCreateAssetFolder = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useMutation(
    (payload: CreateAssetFolderPayload) =>
      assetFolder.createAssetFolder(payload),
    config
  );
};

export const useGetFolderAssets = (
  payload: AssetFolderPayload,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useQuery<{ data: AIDesignAssetType[] }>(
    ["get-folder-assets", payload],
    () => assetFolder.getFolderAssets(payload),
    config
  );
};

export const useAddFolderAsset = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useMutation(
    (payload: AddAssetFolderPayload) => assetFolder.addFolderAsset(payload),
    config
  );
};

export const useRemoveFolderAsset = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useMutation(
    (payload: AddAssetFolderPayload) => assetFolder.removeFolderAsset(payload),
    config
  );
};

export const useDeleteAssetFolder = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useMutation(
    (payload: AssetFolderPayload) => assetFolder.deleteAssetFolder(payload),
    config
  );
};

export const useEditAssetFolder = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useMutation(
    (payload: AssetFolderPayload) => assetFolder.editAssetFolder(payload),
    config
  );
};

export const useGetRemainingFolders = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useQuery<AssetFolderType[]>(
    ["ai-design-project-remaining-folders", teamId],
    () => assetFolder.getRemainingFolders(),
    config
  );
};

export interface ShareAssetFolderPayload {
  teamIds: number[];
}

export const useShareAssetFolder = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useMutation(
    ({
      id,
      payload,
    }: {
      id: number;
      payload: Partial<ShareAssetFolderPayload>;
    }) => assetFolder.shareAssetFolder(id, payload),
    config
  );
};

export const useUndoSharingAssetFolder = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { assetFolder } = useApi();
  return useMutation(
    ({
      id,
      payload,
    }: {
      id: number;
      payload: Partial<ShareAssetFolderPayload>;
    }) => assetFolder.undoSharingAssetFolder(id, payload),
    config
  );
};
