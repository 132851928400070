import merge from "lodash/merge";
import { ApexOptions } from "apexcharts";
import { theme } from "tailwind.config";
// @mui

// ----------------------------------------------------------------------

export default function useChart(options?: ApexOptions) {
  const LABEL_TOTAL = {
    show: true,
    label: "Total",
    color: "#434343",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: "#434343",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  };

  const baseOptions = {
    // Colors
    colors: ["#341BF9"],

    // Chart
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: "#434343",
      fontFamily: "Inter",
    },

    // States
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: "darken",
          value: 0.88,
        },
      },
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },

    // Datalabels
    dataLabels: { enabled: false },

    // Stroke
    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round",
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: "#E5E6EB",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: {
        style: {
          colors: ["#434343"],
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 400,
          cssClass: "text-body",
        },
      },
      // crosshairs: {
      //   width: 1,
      //   opacity: 0.8,
      //   position: "front",
      //   stroke: {
      //     color: "#434343",
      //     width: 1,
      //     dashArray: 0,
      //   },
      //   fill: {
      //     type: "solid",
      //     color: "#434343",
      //   },
      // },
    },

    // Yaxis
    yaxis: {
      labels: {
        style: {
          colors: ["#434343"],
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 400,
          cssClass: "text-body",
        },
      },
    },

    // Markers
    markers: {
      size: 0,
    },

    // Tooltip
    tooltip: {
      x: {
        show: false,
      },
    },

    // Legend
    legend: {
      show: true,
      fontSize: String(14),
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 12,
      },
      fontWeight: 400,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: "#434343",
      },
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        columnWidth: "28%",
      },

      // Pie + Donut
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL,
          },
        },
      },

      // Radialbar
      radialBar: {
        track: {
          strokeWidth: "100%",
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL,
        },
      },

      // Radar
      radar: {
        polygons: {
          fill: { colors: ["transparent"] },
          strokeColors: "#434343",
          connectorColors: "#434343",
        },
      },

      // polarArea
      polarArea: {
        rings: {
          strokeColor: "#434343",
        },
        spokes: {
          connectorColors: "#434343",
        },
      },
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.extend.screens.sm,
        options: {
          plotOptions: { bar: { columnWidth: "40%" } },
        },
      },
      {
        // md
        breakpoint: theme.extend.screens.md,
        options: {
          plotOptions: { bar: { columnWidth: "32%" } },
        },
      },
    ],
  };

  return merge(baseOptions, options);
}
