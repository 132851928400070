import CtaButton from "components/atoms/CtaButton";
import DropZone from "components/atoms/DropZone";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import cn from "classnames";
import { useUploadFile, useUploadManyFiles } from "hooks/admin";
import { useErrorHandler } from "hooks/useErrorHandler";
import SavedImagesModal from "components/organisms/AIDesign/SavedImagesModal";
import { useState } from "react";
import { TVGarment } from "models/TvGarment";
import AiDesignMediaLibraryModal from "./AIDesign/AiDesignMediaLibraryModal";
import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import PlusIcon from "public/icons/plus_bold.svg";
import { useField } from "formik";
import { useMemo } from "react";
import { IUploadFileResponse } from "types";

interface IFilesPickerProps {
  accept?: Record<string, string[]>;
  containerClassName?: string;
  multiple?: boolean;
  disabled?: boolean;
  hideMoodboardOption?: boolean;
  hideMediaLibOption?: boolean;
  onFilesChange: (
    data: IUploadFileResponse | Array<IUploadFileResponse>
  ) => void;
  onSelectSavedImage?: (garment: TVGarment) => void;
  onSelectMediaImage?: (asset: AIDesignAssetType) => void;
  zoneClassName?: string;
  addButtonText?: string;
  name?: string;
  maxFileSize?: number;
  buttonView?: boolean;
  iconHidden?: boolean;
  addButtonClassName?: string;
}

const FilesPicker: React.FC<IFilesPickerProps> = ({
  onFilesChange,
  onSelectSavedImage,
  onSelectMediaImage,
  containerClassName,
  accept,
  multiple,
  disabled,
  hideMediaLibOption,
  hideMoodboardOption,
  zoneClassName,
  addButtonText,
  name,
  maxFileSize,
  buttonView,
  iconHidden,
  addButtonClassName,
}) => {
  const { t } = useTranslation("common");
  const { handleError } = useErrorHandler();
  const [isSavedImagesOpened, setIsSavedImagesOpened] =
    useState<boolean>(false);
  const [isMediaLibOpened, setIsMediLibOpened] = useState<boolean>(false);

  const openMediaLib = () => setIsMediLibOpened(true);
  const closeMediaLib = () => setIsMediLibOpened(false);

  const openSavedImages = () => setIsSavedImagesOpened(true);
  const closeSavedImages = () => setIsSavedImagesOpened(false);

  const onError = (err: any) => handleError({ err });
  const onSuccess = (data: IUploadFileResponse | Array<IUploadFileResponse>) =>
    onFilesChange(data);

  const { mutate: uploadFile, isLoading: fileLoading } = useUploadFile({
    onError,
    onSuccess,
  });
  const { mutate: uploadManyFiles, isLoading: filesLoading } =
    useUploadManyFiles({
      onError,
      onSuccess,
    });

  const onTempFilesChange = (files: File[]) => {
    if (files.length > 1) {
      uploadManyFiles(files);
    } else {
      const data = new FormData();
      data.append("file", files[0] as File);
      uploadFile(data);
    }
  };

  const selectMediaImage = (asset: AIDesignAssetType) => {
    onSelectMediaImage && onSelectMediaImage(asset);
    closeMediaLib();
  };

  const selectSavedImage = (garment: TVGarment) => {
    onSelectSavedImage && onSelectSavedImage(garment);
    closeSavedImages();
  };

  const [field, meta] = useField({ name: name || "" });

  const errorMessage = useMemo(() => {
    if (name && meta.touched && meta.error) {
      return meta.error;
    }
  }, [field, name, meta]);

  return (
    <>
      <div className={cn("", containerClassName)}>
        <DropZone
          disabled={disabled}
          multiple={multiple}
          onChange={onTempFilesChange}
          noClick
          noKeyboard
          maxSize={maxFileSize}
          errorMessage={errorMessage}
          accept={
            accept || {
              "image/*": [],
              "application/pdf": [".pdf"],
              "application/msword": [".doc", ".docx", ".xls", ".xlsx"],
              "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
              "application/rtf": [".rtf"],
              "text/plain": [".txt"],
              "text/markdown": [".md"],
            }
          }
          zoneComponent={({ isDragActive, open }) => (
            <div
              className={cn(
                "border border-dashed border-border-normal bg-gray-10 w-full h-full rounded-2xl py-6 flex flex-col justify-center items-center",
                isDragActive && "border-indigo-700 animate-ping",
                buttonView &&
                  "border-none h-fit !py-0 !px-0 !items-start w-fit",
                zoneClassName
              )}
            >
              <CtaButton
                className={cn(
                  "font-semibold",
                  (!hideMoodboardOption || !hideMediaLibOption) && "mb-4",
                  addButtonClassName
                )}
                size="sm"
                onClick={() => open()}
                variant="secondary"
                spinner={filesLoading || fileLoading}
                iconLeft={iconHidden ? null : PlusIcon}
                iconLeftProps={{
                  viewBox: "0 0 18 18",
                  className: "fill-black",
                  width: 18,
                  height: 18,
                }}
              >
                {addButtonText || t("add")}
              </CtaButton>
              {!hideMoodboardOption && (
                <CtaButton
                  className="!font-semibold"
                  onClick={openSavedImages}
                  variant="link"
                >
                  {t("add_from_saved_images")}
                </CtaButton>
              )}
              {!hideMediaLibOption && (
                <CtaButton
                  onClick={openMediaLib}
                  className="!mt-2 !font-semibold"
                  variant="link"
                >
                  {t("add_from_media_lib")}
                </CtaButton>
              )}
            </div>
          )}
        />
      </div>
      <SavedImagesModal
        isOpen={isSavedImagesOpened}
        onClose={closeSavedImages}
        setPreviewGarment={selectSavedImage}
        disableVariation
      />
      <AiDesignMediaLibraryModal
        isOpen={isMediaLibOpened}
        onClose={closeMediaLib}
        hideUploadButton
        selectImageAndClose={selectMediaImage}
        onApplyAsset={selectMediaImage}
      />
    </>
  );
};

export default FilesPicker;
