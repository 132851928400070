import Icon from "../Icon";
import MoreIcon from "public/icons/more-vertical.svg";
import FolderIcon from "public/icons/folder-icon_16.svg";
import PenIcon from "public/icons/pencil_16.svg";
import TrashIcon from "public/icons/bin_16.svg";
import { useMemo, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import FolderMoreButton from "./FolderMoreButton";
import cn from "classnames";
import { useDrag, useDrop } from "react-dnd";
import ShareIcon from "public/icons/share_16.svg";
import { useTeam } from "contexts/TeamContext";
import { usePortal } from "contexts/PortalContext";
import { useUser } from "contexts/UserContext";
import SharedFolderIcon from "public/icons/shared_folder_16.svg";
import { AssetFolderType } from "api/AssetFoldersApi";
import { DropResult } from "react-beautiful-dnd";

interface FolderButtonProps {
  id: number;
  text?: string;
  folder: AssetFolderType;
  active?: boolean;
  isShared?: boolean;
  onClick?: () => void;
  onEditFolderName?: () => void;
  onDeleteFolder?: (id: number) => void;
  onShareFolder?: ((id: number) => void) | null;
  onMoveFolder?: (
    selectedFolder: AssetFolderType,
    targetFolder?: AssetFolderType
  ) => void;
}

export default function FolderButton({
  id,
  text,
  active = false,
  isShared = false,
  onClick,
  folder,
  onEditFolderName,
  onDeleteFolder,
  onShareFolder,
  onMoveFolder,
}: FolderButtonProps) {
  const { t } = useTranslation("common");
  const { user, isAdmin } = useUser();
  const { userPortal } = usePortal();
  const { isMaintainer, teamId } = useTeam();

  const isPortalOwner = useMemo(() => {
    if (userPortal && userPortal.portalOwnerId && user && user.id)
      return user.id !== userPortal.portalOwnerId;
    else return isAdmin;
  }, [user, userPortal, isAdmin]);

  const [folderName, setFolderName] = useState<string>(text || "");
  const [displayName, setDisplayName] = useState<string>(text || "");

  const moreButtons = useMemo(() => {
    const items = [
      {
        icon: PenIcon,
        title: t("rename"),
        onClick: () => {
          onEditFolderName && onEditFolderName();
        },
        iconProps: {
          viewBox: "0 0 16 16",
        },
      },
      ...(!folder.copyFromFolderId &&
      onShareFolder &&
      (isMaintainer || isPortalOwner)
        ? [
            {
              divider: true,
            },
            {
              icon: ShareIcon,
              iconProps: {
                viewBox: "0 0 16 16",
                className: "fill-none",
              },
              onClick: () => onShareFolder(id),
              title: t("share_to_others"),
            },
            {
              divider: true,
            },
          ]
        : []),
      {
        icon: TrashIcon,
        title: t("Delete"),
        onClick: () => {
          onDeleteFolder && onDeleteFolder(id);
        },
      },
    ];
    return items;
  }, [onDeleteFolder, onEditFolderName, id, isPortalOwner, isMaintainer]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "Card",
    drop: () => ({ name: folderName, id: id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Card",
    item: { ...folder },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult && onMoveFolder) {
        onMoveFolder(item, dropResult as unknown as AssetFolderType);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const isActive = canDrop && isOver;

  return (
    <button ref={drop} data-testid="dustbin">
      <div
        ref={drag}
        data-testid={`box`}
        style={{
          opacity: isDragging ? 0.4 : 1,
        }}
        className="relative rounded-2xl border border-border-normal flex items-center justify-between gap-2 bg-white hover:bg-gray-10 h-[52px] w-full group"
      >
        <div
          onClick={onClick}
          className={cn(
            "px-4 py-2 flex items-center justify-start gap-2 active:bg-gray-20 h-[50px] w-full rounded-2xl",
            active && "bg-gray-20",
            isActive && "border-gray-950 border-2"
          )}
        >
          <Icon
            component={isShared ? SharedFolderIcon : FolderIcon}
            viewBox="0 0 16 16"
            width={16}
            height={16}
            className="shrink-0"
          />
          <span className="w-[146px] text-left text-sm text-typography-body-normal font-medium truncate">
            {displayName}
          </span>
        </div>
        <div className="w-[34px] h-[34px] absolute right-4">
          <div className="group-hover:!block xxs:block md:!hidden">
            <FolderMoreButton
              icon={MoreIcon}
              options={moreButtons}
              iconClassName="rotate-90"
              buttonClassName="border-0 hover:border-0 active:border-0 !bg-gray-10 active:!bg-gray-30 hover:!bg-gray-20"
              listClassName="min-w-[165px] gap-0"
              disabledTooltip
            />
          </div>
        </div>
      </div>
    </button>
  );
}
