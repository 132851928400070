import { useState } from "react";
import cn from "classnames";
import { Combobox } from "@headlessui/react";
import Team from "models/Team";

interface TeamSearchInputProps {
  teams?: any[];
  inputClassName?: string;
  containerClassName?: string;
  value: Team | null;
  onChange: (value: Team) => void;
}

const TeamSearchInput: React.FC<TeamSearchInputProps> = ({
  teams,
  value,
  onChange,
  inputClassName,
  containerClassName,
}) => {
  const [query, setQuery] = useState("");

  const filteredPeople =
    (query === ""
      ? teams
      : teams?.filter((team: any) => {
          return team.name.toLowerCase().includes(query.toLowerCase());
        })) || [];

  return (
    <Combobox value={value} onChange={onChange}>
      {(close) => (
        <>
          <Combobox.Input
            aria-label="Assignee"
            displayValue={(team: any) => team?.name}
            onChange={(event) => setQuery(event.target.value)}
            className={cn(
              "pl-4 h-10 rounded-md border border-border-normal text-sm leading-normal w-full",
              inputClassName
            )}
          />
          <Combobox.Options className="absolute top-12 w-full bg-white empty:invisible rounded-md border border-border-normal p-2 max-h-[310px] overflow-y-scroll">
            {filteredPeople.map((team) => (
              <Combobox.Option
                key={team.id}
                value={team}
                className={cn(
                  "pl-4 py-2 rounded-md text-sm leading-normal cursor-pointer hover:bg-gray-30",
                  query === team.name && "bg-gray-30"
                )}
              >
                {team.name}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </>
      )}
    </Combobox>
  );
};

export default TeamSearchInput;
