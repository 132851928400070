import cn from "classnames";
import { Popover } from "@headlessui/react";
import BellIcon from "public/icons/bell_16.svg";
import useTranslation from "next-translate/useTranslation";
import CtaButton from "components/atoms/CtaButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "contexts/UserContext";
import { useTeam } from "contexts/TeamContext";
import { usePortal } from "contexts/PortalContext";
import { useRouter } from "next/router";
import { useLocalStorage } from "hooks/useLocalStorage";
import { QueryObserver, useQueryClient } from "react-query";
import { capitalize } from "lodash";
import { isPlan } from "utils/isPlan";
import { openUrl } from "utils/openUrl";
import CreateEditTeamModal from "components/organisms/CreateEditTeamModal";
import {
  INotificationType,
  useGetNotifications,
  useMarkAllAsRead,
  useMarkAsRead,
} from "hooks/useNotifications";
import NotificationMessage, {
  SystemInfoIcon,
} from "components/atoms/NotificationMessage";
import CustomButton from "components/atoms/CustomButton";

interface NotificationBtnProps {
  className?: string;
}

const NotificationBtn: React.FC<NotificationBtnProps> = ({ className }) => {
  const { t } = useTranslation("common");
  const { currentTeam, push, teamId, isMaintainer } = useTeam();
  const { user, subscription } = useUser();
  const { portalName, isPortal } = usePortal();
  const { getItemFromLocalStorage, setItemToLocalStorage } = useLocalStorage();
  const queryClient = useQueryClient();

  const [messages, setMessages] = useState<
    Array<{
      id?: string;
      title: string;
      onClick: () => void;
      onClose: () => void;
      isRead?: boolean;
    }>
  >([]);
  const router = useRouter();
  const [domainData, setDomainData] = useState<any>();

  const [isTeamModalOpened, setIsTeamModalOpened] = useState<boolean>(false);

  const openTeamModal = () => setIsTeamModalOpened(true);
  const closeTeamModal = () => setIsTeamModalOpened(false);

  const query = new QueryObserver(queryClient, { queryKey: "domain-active" });

  useEffect(() => {
    const unsubscribe = query.subscribe(({ data }) => {
      if (data) {
        setDomainData(data);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (subscription && !isPortal) {
      const subscriptionMessage = getItemFromLocalStorage(
        `subscription-warning-${subscription.id}`
      );

      const subscriptionName = capitalize(
        (
          subscription?.items?.data?.find((el: any) => isPlan(el))?.price
            ?.product as any
        )?.metadata?.name || ""
      );

      if (
        subscriptionMessage !== "closed" &&
        !messages?.some(
          (el) =>
            el.title ===
            t("subscription_warning_title", { name: subscriptionName })
        )
      ) {
        setMessages((s) => [
          ...s,
          {
            id: `subscription-warning-${subscription.id}`,
            title: t("subscription_warning_title", { name: subscriptionName }),
            onClick: () => {
              setItemToLocalStorage(
                `subscription-warning-${subscription.id}`,
                "read"
              );
              router.push(`/${portalName}/profile/subscription`);
            },
            onClose: () => {
              setItemToLocalStorage(
                `subscription-warning-${subscription.id}`,
                "closed"
              );
              setMessages((s) =>
                s.filter(
                  (el) =>
                    el.title !==
                    t("subscription_warning_title", { name: subscriptionName })
                )
              );
            },
            isRead: subscriptionMessage === "read",
          },
        ]);
      }
    }

    if (
      domainData &&
      !messages.some(
        (el) =>
          el.title ===
          t("domain_is_active", { domain: (domainData as any).domain })
      )
    ) {
      const domainActiveMessage = getItemFromLocalStorage(`domain-is-active`);

      setMessages((s) => [
        ...s,
        {
          id: `domain-is-active`,
          title: t("domain_is_active", { domain: (domainData as any).domain }),
          onClick: () => {
            setItemToLocalStorage(`domain-is-active`, "read");
            openUrl(`https://${(domainData as any).domain}`);
          },
          onClose: () => {
            setDomainData(null);
            queryClient.removeQueries("domain-active");
            setMessages((s) =>
              s.filter(
                (el) =>
                  el.title !==
                  t("domain_is_active", { domain: (domainData as any).domain })
              )
            );
          },
          isRead: domainActiveMessage === "read",
        },
      ]);
    }

    if (user && !user.name) {
      const userNameMessage = getItemFromLocalStorage(
        `name-warning-${user?.id}`
      );
      if (
        userNameMessage !== "closed" &&
        !messages.some((el) => el.title === t("no_name_message"))
      ) {
        setMessages((s) => [
          ...s,
          {
            id: `name-warning-${user?.id}`,
            title: t("no_name_message"),
            onClick: () => {
              setItemToLocalStorage(`name-warning-${user?.id}`, "read");
              router.push(`/${portalName}/profile/account`);
            },
            onClose: () => {
              setItemToLocalStorage(`name-warning-${user?.id}`, "closed");
              setMessages((s) =>
                s.filter((el) => el.title !== t("no_name_message"))
              );
            },
            isRead: userNameMessage === "read",
          },
        ]);
      }
    }

    if (currentTeam && !currentTeam.companyName && !isMaintainer) {
      const companyNameMessage = getItemFromLocalStorage(
        `company-name-warning-${currentTeam.id}`
      );
      if (
        companyNameMessage !== "closed" &&
        !messages.some((el) => el.title === t("no_company_name"))
      ) {
        setMessages((s) => [
          ...s,
          {
            id: `company-name-warning-${currentTeam.id}`,
            title: t("no_company_name"),
            onClick: () => {
              setItemToLocalStorage(
                `company-name-warning-${currentTeam.id}`,
                "read"
              );
              openTeamModal();
            },
            onClose: () => {
              setItemToLocalStorage(
                `company-name-warning-${currentTeam.id}`,
                "closed"
              );
              setMessages((s) =>
                s.filter((el) => el.title !== t("no_company_name"))
              );
            },
            isRead: companyNameMessage === "read",
          },
        ]);
      }
    } else if (
      ((currentTeam && currentTeam.companyName) || isMaintainer) &&
      messages.some((el) => el.title === t("no_company_name"))
    ) {
      setMessages((s) => s.filter((el) => el.title !== t("no_company_name")));
    }
  }, [currentTeam, user, messages, domainData, subscription]);

  // const notifications = useMemo(
  //   () => [
  //     {
  //       username: "{user_name}",
  //       orderName: "Order #12345",
  //       message: "accepted",
  //       time: dayjs().subtract(28, "second").toISOString(),
  //       isRead: false,
  //     },
  //   ],
  //   []
  // );

  const { data: notifications = [], refetch: refetchNotifications } =
    useGetNotifications({
      enabled: !!teamId,
    });

  const { mutate: markNotificationsAllAsRead } = useMarkAllAsRead({
    onSuccess() {
      refetchNotifications();
    },
  });

  const { mutate: markNotificationAsRead } = useMarkAsRead({
    onSuccess() {
      refetchNotifications();
    },
  });

  const { unreadMessages, readMessages } = useMemo(() => {
    const unreadMessages = notifications?.filter((item) => !item.isRead);
    const readMessages = notifications?.filter((item) => item.isRead);
    return { unreadMessages, readMessages };
  }, [notifications]);

  const unreadSystemMessages = useMemo(() => {
    return messages.filter((message) => !message.isRead) || [];
  }, [messages]);

  const handleMarkAllAsRead = useCallback(() => {
    setMessages((s) =>
      s.map((item) => {
        if (item.id) setItemToLocalStorage(item.id, "read");
        return { ...item, isRead: true };
      })
    );

    markNotificationsAllAsRead(null);
  }, [setMessages, markNotificationsAllAsRead]);

  const handleClickNotification = useCallback(
    (notification: INotificationType) => {
      markNotificationAsRead(notification.id);
      push(`/orders/${notification.dataReferences.productOrder.entityId}`);
    },
    [markNotificationAsRead, push]
  );

  return (
    <>
      <Popover className={cn("relative", className)}>
        <Popover.Button className={cn("relative outline-none ring-0")}>
          <CustomButton
            variant="tertiary"
            icon={BellIcon}
            viewBox="0 0 16 16"
            className="!p-2"
          />
          {(unreadMessages.length > 0 || unreadSystemMessages.length > 0) && (
            <div className="absolute top-[5px] right-[5px] h-1.5 w-1.5 rounded-full bg-red-50" />
          )}
        </Popover.Button>
        <Popover.Panel
          as={"div"}
          className="absolute bg-white top-full xxs:-right-10 xs:right-0 flex flex-col p-2 xxs:w-[260px] xs:w-[320px] border border-border-default rounded-xl z-[9999]"
        >
          <div className="flex items-center justify-between p-2">
            <div className="text-gray-950 leading-tight text-sm">
              {t("notifications")}
            </div>
            <CtaButton
              variant="link"
              className="!no-underline !text-xs !leading-normal"
              onClick={handleMarkAllAsRead}
            >
              {t("mark_all_as_read")}
            </CtaButton>
          </div>
          <div className="max-h-[350px] overflow-y-scroll">
            {messages.length > 0 || notifications.length > 0 ? (
              <>
                {unreadMessages.map((notification, index) => (
                  <NotificationMessage
                    key={index}
                    notification={notification}
                    handleClickNotification={handleClickNotification}
                  />
                ))}
                {messages.map((item, index) => (
                  <div
                    key={index}
                    className="p-2 flex items-center justify-start gap-4 cursor-pointer rounded-xl hover:bg-gray-20"
                    onClick={item.onClick}
                  >
                    <div className="flex items-center gap-1 flex-none">
                      <div className="w-[5px] h-full">
                        {!item.isRead && (
                          <div className="h-[5px] w-[5px] rounded-full bg-red-50" />
                        )}
                      </div>
                      <SystemInfoIcon />
                    </div>
                    <div className="text-xs text-gray-950 leading-normal">
                      {item.title}
                    </div>
                  </div>
                ))}
                {readMessages.map((notification, index) => (
                  <NotificationMessage
                    key={index}
                    notification={notification}
                    handleClickNotification={handleClickNotification}
                  />
                ))}
              </>
            ) : (
              <div className="text-sm w-full text-center mx-auto py-4">
                No messages
              </div>
            )}
          </div>
        </Popover.Panel>
      </Popover>
      <CreateEditTeamModal
        isOpen={isTeamModalOpened}
        onClose={closeTeamModal}
        fieldToTouch="companyName"
        editCandidate={currentTeam}
        onUpdateSuccess={() => {
          setMessages((s) =>
            s.filter((el) => el.title !== t("no_company_name"))
          );
        }}
      />
    </>
  );
};

export default NotificationBtn;
