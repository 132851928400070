import FormikForm from "components/molecules/FormikForm";
import FormikInput from "components/molecules/FormikInput";
import { FormikProvider, useFormik } from "formik";
import Team from "models/Team";
import React from "react";
import useTranslation from "next-translate/useTranslation";
import * as yup from "yup";
import { Translate } from "next-translate";
import { capitalize } from "lodash";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useRouter } from "next/router";
import { useTeam } from "contexts/TeamContext";
import { useCreateTeam, useUpdateTeam } from "hooks/teams";
import { usePortal } from "contexts/PortalContext";
import { success } from "utils/toast";
import { useUser } from "contexts/UserContext";
import { useEffect } from "react";
import Modal from "components/molecules/Modal";
import CtaButton from "components/atoms/CtaButton";
import Avatar from "components/atoms/Avatar";
import { theme } from "tailwind.config";
import FilesPicker from "components/molecules/FilesPicker";
import { IUploadFileResponse } from "types";
import cn from "classnames";

const getValidationSchema = (t: Translate) =>
  yup.object({
    name: yup
      .string()
      .required(t("field_required", { field: capitalize(t("name")) }))
      .max(50, t("greater_error", { count: 50 })),
    description: yup
      .string()
      .nullable()
      .max(500, t("greater_error", { count: 500 })),
    companyName: yup
      .string()
      .max(50, t("greater_error", { count: 50 }))
      .required(t("field_required", { field: t("company_name") })),
    logo: yup
      .object({
        url: yup.string(),
        mimetype: yup.string(),
      })
      .optional()
      .nullable(),
  });

const CreateEditTeamModal = ({
  onClose,
  isOpen,
  editCandidate,
  fieldToTouch,
  onUpdateSuccess,
  message,
  title,
  saveButtonText,
}: {
  onClose: any;
  isOpen: boolean;
  editCandidate?: Team | null;
  onUpdateSuccess?: (data: Team) => void;
  fieldToTouch?: string;
  message?: string;
  title?: string;
  saveButtonText?: string;
}) => {
  const { t } = useTranslation("common");
  const { handleError } = useErrorHandler();
  const router = useRouter();
  const { user } = useUser();
  const { refetchTeam } = useTeam();
  const { portalName } = usePortal();

  const onError = (err: any) => handleError({ err });

  const { mutate: createTeam, isLoading: createLoading } = useCreateTeam({
    onSuccess: async (team: Team) => {
      await refetchTeam();
      router.push(`/${portalName}/${team?.id}/team`);
      onClose();
    },
    onError,
  });

  const { mutate: updateTeam, isLoading: updateLoading } = useUpdateTeam({
    onError,
    async onSuccess(team: Team) {
      await refetchTeam();
      success({ message: t("team_updated") });
      onClose();
      if (onUpdateSuccess) {
        onUpdateSuccess(team);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: editCandidate?.name || "",
      companyName:
        (editCandidate ? editCandidate?.companyName : user?.organizationName) ||
        "",
      description: editCandidate?.description || "",
      logo: editCandidate?.logo,
    },
    onSubmit: (values) => {
      if (editCandidate) {
        return updateTeam({ teamPart: values, teamId: editCandidate?.id });
      }

      createTeam(values);
    },
    validationSchema: getValidationSchema(t),
    enableReinitialize: true,
  });

  const onLogoChange = (
    file: IUploadFileResponse | Array<IUploadFileResponse>
  ) => {
    const { data, mimetype } = file as IUploadFileResponse;
    formik.setFieldValue("logo", { url: data, mimetype });
  };

  const onRemoveLogo = () => formik.setFieldValue("logo", null);

  useEffect(() => {
    if (fieldToTouch) {
      formik.setFieldTouched(fieldToTouch);
    }
  }, [fieldToTouch]);

  return (
    <Modal className="w-[480px]" isOpen={isOpen} onClose={onClose}>
      <div className="mb-8">
        <h2 className={cn("headline-100")}>
          {title ? title : editCandidate ? t("edit_team") : t("create_team")}
        </h2>
        {!!message && <p className="body-100">{message}</p>}
      </div>
      <FormikProvider value={formik}>
        <FormikForm className="flex flex-col">
          <FormikInput
            name="name"
            label={t("team_name")}
            containerClassName="w-full"
            placeholder={t("team")}
          />
          <FormikInput
            name="companyName"
            label={t("company_name")}
            containerClassName="w-full"
            placeholder={t("company")}
            description={t("company_name_desc")}
            descriptionClassName="text-xs"
          />

          <div>
            <div className="mb-4">
              <p className="label-100 mb-2">{t("company_logo")}</p>
              <div className="flex items-center justify-between">
                <Avatar
                  avatarColor={theme.extend.colors.gray[40]}
                  className="w-20 h-20 !border-none font-semibold text-black text-[34px]"
                  fullName={formik.values.companyName || "C"}
                  image={formik.values.logo?.url}
                />
                <div className="flex xs:items-center xxs:flex-col xs:flex-row xxs:items-start gap-2">
                  <FilesPicker
                    name="logo"
                    onFilesChange={onLogoChange}
                    hideMediaLibOption
                    hideMoodboardOption
                    buttonView
                    addButtonText={t("upload_logo")}
                    accept={{ "image/png": [".png", ".jpeg", ".jpg"] }}
                    iconHidden
                    addButtonClassName="xs:!py-[11px] xxs:!py-[7.25px]"
                  />
                  <CtaButton
                    disabled={!formik.values.logo}
                    variant="secondary"
                    onClick={onRemoveLogo}
                    size="md"
                    className="font-semibold"
                  >
                    {t("remove")}
                  </CtaButton>
                </div>
              </div>
            </div>
            <FormikInput name="description" optional label={t("description")} />
          </div>

          <div className="flex justify-end mt-10 gap-2">
            <CtaButton size="md" variant="secondary" onClick={onClose}>
              {capitalize(t("cancel"))}
            </CtaButton>
            <CtaButton
              size="md"
              type="submit"
              disabled={!formik.values.name || !formik.values.companyName}
              spinner={createLoading || updateLoading}
            >
              {saveButtonText
                ? saveButtonText
                : editCandidate
                ? t("save")
                : capitalize(t("create"))}
            </CtaButton>
          </div>
        </FormikForm>
      </FormikProvider>
    </Modal>
  );
};

export default CreateEditTeamModal;
