export const truncateString = (str: string, length?: number) => {
  const maxLength = length || 30;
  if (str.length <= maxLength) {
    return str;
  }

  const halfLength = Math.floor((maxLength - 3) / 2);
  const truncatedString =
    str.slice(0, halfLength) + "..." + str.slice(-halfLength);

  return truncatedString;
};
