import React, { useState } from "react";
import cn from "classnames";

interface ScrollbarProps {
  className?: string;
  scrollingClassName?: string;
  style?: {};
}

const Scrollbar: React.FC<ScrollbarProps> = ({
  className,
  scrollingClassName,
  children,
  ...props
}) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const handleScroll = (e: any) => {
    if (timer) clearTimeout(timer);

    if (isScrolling === false) {
      setIsScrolling(true);
    }

    setTimer(
      setTimeout(() => {
        setIsScrolling(false);
      }, 1000)
    );
  };

  return (
    <div
      {...props}
      className={cn(
        "overflow-y-scroll firefox-scrollbar",
        !isScrolling
          ? "hidden-scrollbar firefox-hidden-scrollbar"
          : scrollingClassName,
        className
      )}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
};

export default Scrollbar;
