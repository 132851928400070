import ColorTile from "components/atoms/ColorTile";
import cn from "classnames";
import React from "react";
import ImageResponsive from "components/atoms/ImageResponsive";
import { convertUSLocalString } from "utils/beautifyNumbers";
import useTranslation from "next-translate/useTranslation";

type ProgressListType = {
  title?: string;
  list: {
    id?: string;
    name: string;
    value?: number;
    rate: number;
    img: string;
    color?: string;
  }[];
  baseRate?: number;
  className?: string;
  listTitleClassName?: string;
  titleClassName?: string;
  colorTileClassName?: string;
  onSelect?: Function;
};

const ProgressList = ({
  title,
  list,
  baseRate = 100,
  className,
  listTitleClassName,
  titleClassName,
  colorTileClassName,
  onSelect,
}: ProgressListType) => {
  const { t } = useTranslation("common");

  return (
    <div className={cn("mt-8", className)}>
      {title && (
        <div
          className={cn(
            "text-xs font-medium py-4 border-b text-typography-body-subtle",
            listTitleClassName
          )}
        >
          {title}
        </div>
      )}
      {list?.map((item, i) => (
        <div key={i}>
          <div
            className={cn(
              "flex justify-between text-body text-sm font-normal p-3 pb-[1px] px-0",
              onSelect && "cursor-pointer hover:bg-gray-20"
            )}
            onClick={() => onSelect && onSelect(item?.id || item.name)}
          >
            <div className="flex items-center gap-4">
              {item.img && (
                <span>
                  <ImageResponsive
                    url={item.img}
                    className="w-6 h-6 rounded-full"
                  />
                </span>
              )}
              {item.color && (
                <ColorTile color={item.color} className={colorTileClassName} />
              )}
              <span className={titleClassName}>
                {t(item.name).toUpperCase()}
              </span>
            </div>
            <div className="flex gap-1">
              {item?.value && (
                <span className="font-medium">
                  ({convertUSLocalString(item.value)})
                </span>
              )}
              <span className="font-medium">{item.rate}%</span>
            </div>
          </div>
          <div
            className={cn(
              "w-full border-b border-gray-40 h-1",
              onSelect && "hover:bg-gray-20"
            )}
          >
            <div
              className={`bg-indigo-40 h-1`}
              style={{ width: `${(item.rate / baseRate) * 100}%` }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressList;
