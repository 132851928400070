import { ITvGarmentKeyword } from "api/TvChannelsApi";
import { useGetFacets } from "hooks/facets";
import { capitalize, isEmpty } from "lodash";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { useMemo } from "react";
import { ISelectValue } from "types";
import { Catgroup, Gender } from "utils/constants";
import cn from "classnames";
import SearchDropdown from "components/atoms/SearchDropdown";

const BUTTON_CLASS =
  "!rounded-lg disabled:hover:!bg-gray-100 xxs:w-full md:!w-inherit !min-w-[142px] !truncate";
const CLASS = "mb-4 w-full";

const genderOptions = Object.entries(Gender)
  .filter(([_, value]) => !!value && value !== Gender.Y && value !== Gender.K)
  .map(([key, value]: string[]) => ({
    name: value,
    value: key,
  }));

const sortCategory = (a: ISelectValue, b: ISelectValue) =>
  a.name.localeCompare(b.name);

interface IMediaStyleDetailsFiltersProps {
  keywords: ITvGarmentKeyword[];
  onChange: (
    field: "gender" | "catgroup" | "basecat" | "subcat"
  ) => (val: ISelectValue) => void;
  disabled?: boolean;
  className?: string;
}

const MediaStyleDetailsFilters: React.FC<IMediaStyleDetailsFiltersProps> = ({
  onChange,
  keywords,
  disabled,
  className,
}) => {
  const { t } = useTranslation("common");

  const values = useMemo(() => {
    if (keywords.length) {
      const gender = keywords.find((el) => el.style === "gender");
      const catgroup = keywords.find((el) => el.style === "catgroup");
      const basecat = keywords.find((el) => el.style === "basecat");
      const subcat = keywords.find((el) => el.style === "subcat");

      return {
        gender,
        catgroup,
        basecat,
        subcat,
      };
    }

    return {};
  }, [keywords]);

  const { data: nestedFacets = {}, isLoading } = useGetFacets({
    enabled: !!values.gender,
  });

  const transformedGenderOptions = useMemo(() => {
    if (values.gender) {
      return genderOptions.map((el) => {
        if (el.name === values.gender?.value) {
          return {
            ...el,
            preselected: values.gender?.preselected,
          };
        }
        return {
          ...el,
          preselected: false,
        };
      });
    }

    return genderOptions;
  }, [values.gender]);

  const catgroupOptions = useMemo(() => {
    if (!isEmpty(nestedFacets) && values.gender) {
      const genderValue = transformedGenderOptions.find(
        (el) => el.name === values.gender?.value
      )?.value as string;

      const options = Object.keys(nestedFacets[genderValue] || {})
        .filter((el) => Catgroup[el as keyof typeof Catgroup] !== Catgroup.Y)
        .map((el) => ({
          name: Catgroup[el as keyof typeof Catgroup],
          value: el,
          preselected:
            values.catgroup?.value === Catgroup[el as keyof typeof Catgroup] &&
            values.catgroup.preselected,
        }))
        .sort(sortCategory);

      return options as Array<ISelectValue>;
    }
  }, [nestedFacets, values.gender, transformedGenderOptions, values.catgroup]);

  const basecatOptions = useMemo(() => {
    if (!isEmpty(nestedFacets) && values.gender && values.catgroup) {
      const genderValue = genderOptions.find(
        (el) => el.name === values.gender?.value
      )?.value as string;
      const catgroupValue = catgroupOptions?.find(
        (el) => el.name === values.catgroup?.value
      )?.value as string;

      const options = Object.keys(
        nestedFacets[genderValue]?.[catgroupValue] || {}
      )
        .map((el) => ({
          name: capitalize(t(el)),
          value: el,
          preselected:
            capitalize(t(el)) === values.basecat?.value &&
            values.basecat?.preselected,
        }))
        .sort(sortCategory);

      return options as Array<ISelectValue>;
    }
  }, [nestedFacets, values.gender, values.catgroup, values.basecat]);

  const subcatOptions = useMemo(() => {
    if (
      !isEmpty(nestedFacets) &&
      values.gender &&
      values.catgroup &&
      values.basecat
    ) {
      const genderValue = genderOptions.find(
        (el) => el.name === values.gender?.value
      )?.value as string;
      const catgroupValue = catgroupOptions?.find(
        (el) => el.name === values.catgroup?.value
      )?.value as string;
      const basecatValue = basecatOptions?.find(
        (el) => el.name === values.basecat?.value
      )?.value as string;

      const options = Object.keys(
        nestedFacets[genderValue]?.[catgroupValue]?.[basecatValue] || {}
      )
        .filter(
          (key) =>
            nestedFacets[genderValue]?.[catgroupValue]?.[basecatValue]?.[key]
              ?.pcnt > 0.01
        )
        .map((el) => ({
          name: capitalize(t(el)),
          value: el,
          preselected:
            capitalize(t(el)) === values.subcat?.value &&
            values.subcat?.preselected,
        }))
        .filter((el) => el.value !== "other")
        .sort(sortCategory);

      return options;
    }
  }, [
    nestedFacets,
    values.gender,
    values.catgroup,
    values.basecat,
    values.subcat,
  ]);

  return (
    <div className={cn("mt-4", className)}>
      <SearchDropdown
        disabled={disabled}
        options={transformedGenderOptions}
        selectedItem={
          transformedGenderOptions.find(
            (el) => el.name === values.gender?.value
          ) || {
            name: "simple_gender",
            value: "",
          }
        }
        onSelect={onChange("gender")}
        containerClassName="xxs:w-full md:!w-auto !max-h-[130px]"
        buttonWrapperClassName="!min-w-[142px]"
        className={"mb-2 xxs:w-full md:!w-auto !min-w-[142px]"}
        buttonClassName={cn(
          "xxs:w-full md:!w-inherit !min-w-[142px] !truncate",
          BUTTON_CLASS
        )}
      />
      <SearchDropdown
        disabled={!catgroupOptions?.length || disabled}
        options={catgroupOptions || []}
        selectedItem={
          (catgroupOptions || []).find(
            (el) => el.name === values.catgroup?.value
          ) || {
            name: t("catgroup"),
            value: "",
          }
        }
        onSelect={onChange("catgroup")}
        containerClassName="xxs:w-full md:!w-auto !max-h-[130px]"
        className={"mb-2 xxs:w-full md:!w-auto !min-w-[142px]"}
        buttonWrapperClassName="!min-w-[142px]"
        buttonClassName={BUTTON_CLASS}
      />
      <SearchDropdown
        disabled={!basecatOptions?.length || disabled}
        options={basecatOptions || []}
        selectedItem={
          (basecatOptions || []).find(
            (el) => el.name === values.basecat?.value
          ) || {
            name: "category",
            value: "",
          }
        }
        onSelect={onChange("basecat")}
        containerClassName="xxs:w-full md:!w-auto !max-h-[130px]"
        className={"mb-2 xxs:w-full md:!w-auto !min-w-[142px]"}
        buttonWrapperClassName="!min-w-[142px]"
        buttonClassName={BUTTON_CLASS}
      />
      <SearchDropdown
        disabled={!subcatOptions?.length || disabled}
        options={subcatOptions || []}
        selectedItem={
          (subcatOptions || []).find(
            (el) => el.name === values.subcat?.value
          ) || {
            name: t("subcat"),
            value: "",
          }
        }
        onSelect={onChange("subcat")}
        containerClassName="xxs:w-full md:!w-auto !max-h-[130px]"
        className={"mb-2 xxs:w-full md:!w-auto !min-w-[142px]"}
        buttonWrapperClassName="!min-w-[142px]"
        buttonClassName={BUTTON_CLASS}
      />
    </div>
  );
};

export default MediaStyleDetailsFilters;
