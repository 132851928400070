import React from "react";
import Link from "next/link";
import cn from "classnames";
// import Icon from "components/atoms/Icon";
import { useRouter } from "next/router";
import { theme } from "tailwind.config";
import Tooltip from "components/atoms/Tooltip";
import Badge from "components/atoms/Badge";
import { PageNames } from "utils/constants";

interface SidebarItemProps {
  href: string;
  title: string;
  icon: any;
  expanded: boolean;
  className?: string;
  showBadge?: boolean;
  name: string;
  hideIcon?: boolean;
  onClick?: Function;
}

const SidebarItem = ({
  href,
  title,
  icon,
  expanded,
  className,
  showBadge = false,
  name,
  hideIcon = false,
  onClick,
}: SidebarItemProps) => {
  const router = useRouter();
  const isPortalHomePage = router.pathname.replace("[portalName]", "") === "/";
  const active =
    (name === PageNames.HOME && isPortalHomePage) ||
    router.pathname.includes(name);

  return (
    <Tooltip
      oneline
      arrowHidden
      direction="right"
      folowCursor={false}
      className="!p-1.5"
      disabled={expanded}
      content={title}
      offset={[0, -5]}
    >
      <div
        className={cn(
          `py-2 px-0 last:mb-0 cursor-pointer relative group`,
          className
        )}
      >
        <Link href={href}>
          <div
            className={cn(
              "flex justify-between shrink-0 bg-white w-full h-full relative transition-all duration-200 ease-in-out active:bg-gray-10 rounded-xl",
              active && "absolute bg-gray-20"
            )}
            onClick={(e: any) => onClick && onClick(e)}
          >
            <div
              className={cn(
                "py-2 px-3 flex items-center font-bold w-full",
                !expanded &&
                  "flex-col text-[8px] font-normal justify-center py-2.5"
              )}
            >
              {!hideIcon && (
                <span className="min-w-fit flex justify-center ">
                  {icon({
                    color: active
                      ? theme.extend.colors.black
                      : theme.extend.colors.gray[70],
                  })}
                </span>
              )}
              {expanded && (
                <span
                  className={cn(
                    "duration-200 relative text-gray-70 font-normal text-sm text-center group-hover:text-typography-title whitespace-nowrap group-hover:font-bold",
                    active && "!font-bold !text-black",
                    !expanded && "mt-1",
                    expanded && "ml-3"
                  )}
                >
                  {title}
                  {showBadge && (
                    <Badge className="absolute top-0 bg-red-50 !w-2 !h-2 -right-4" />
                  )}
                </span>
              )}
              {showBadge && !expanded && (
                <Badge className="absolute top-0 bg-red-50 !w-2 !h-2 right-2" />
              )}
            </div>
          </div>
        </Link>
      </div>
    </Tooltip>
  );
};

export default SidebarItem;
