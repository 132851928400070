import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useTeam } from "contexts/TeamContext";
import { useRouter } from "next/router";
import ProfileDropdown from "components/molecules/ProfileDropdown";
import TeamDropdown from "components/molecules/TeamDropdown";
import CommentsButton from "./Comments/CommentsButton";
import { usePortal } from "contexts/PortalContext";
import cn from "classnames";
import Tag from "components/atoms/Tag";
import CheckoutHeaderActions from "components/molecules/CheckoutHeaderActions";
import NotificationBtn from "components/molecules/Header/NotificationBtn";
import { isGuestUser } from "utils/isGuestUser";
import { HEADER_HEIGHT } from "utils/constants";
import TutorialButton from "components/molecules/Header/TutorialButton";
import SupportButton from "components/molecules/Header/SupportButton";
import { useUser } from "contexts/UserContext";
import { useGetIsSupportMember } from "hooks/teams";
import { SubscriptionStatuses } from "types";
import { useMemo } from "react";
import { useBreakpoint } from "contexts/BreakpointContext";
import Tooltip from "components/atoms/Tooltip";

export interface IMainHeaderProps {
  showComments?: boolean;
  showTeams?: boolean;
  showAvatar?: boolean;
  showNotifications?: boolean;
  showTutorial?: boolean;
  showCheckoutActions?: boolean;
  showSupportMessage?: boolean;
  showUpdates?: boolean;
  className?: string;
  homeDisabled?: boolean;
  isExpanded: boolean;
}

const MainHeader: React.FC<IMainHeaderProps> = ({
  showComments = true,
  showAvatar = true,
  showNotifications = true,
  showCheckoutActions = false,
  showSupportMessage = true,
  showTutorial = true,
  showUpdates = true,
  className,
  isExpanded,
  showTeams = false,
}) => {
  const { t } = useTranslation("common");
  const { currentTeam, push } = useTeam();
  const { isPortal } = usePortal();
  const { user, isAdmin } = useUser();
  const isGuest = isGuestUser(currentTeam?.teamRole);
  const { xxs } = useBreakpoint();

  const { isTeamSupporting } = currentTeam || {};

  const router = useRouter();
  const isSubscriptionsPage = router.pathname.includes("/subscription-expired");
  const isAiDesignPage = router.asPath.includes("/ai-design");

  const { data, isFetched } = useGetIsSupportMember(
    {
      teamId: currentTeam?.id!,
      userId: user?.id!,
    },
    {
      enabled: isAdmin && !!user && !!currentTeam,
    }
  );

  const isSupportMember = isFetched && data;

  const subscriptionStatus = useMemo(() => {
    if (currentTeam && isAdmin && isSupportMember) {
      switch (currentTeam.limits?.subscriptionStatus) {
        case SubscriptionStatuses.PAST_DUE:
          return {
            className: "bg-orange-75",
            text: t("subscription_status", { status: "expired" }),
          };
        case SubscriptionStatuses.UNPAID:
          return {
            className: "bg-red-10",
            text: t("subscription_status", { status: "unpaid" }),
          };
        default:
          return {
            className: "!bg-green-200",
            text: t("subscription_status", { status: "active" }),
          };
      }
    }

    return null;
  }, [currentTeam, isAdmin, isSupportMember]);

  return (
    <header
      style={{ height: HEADER_HEIGHT }}
      className={cn(
        "top-0 flex justify-between items-center bg-white px-4 z-[48]",
        className
      )}
    >
      <div className="flex">
        {showTeams && (
          <TeamDropdown
            isExpanded={isExpanded}
            createTeamDisabled={(isPortal && isGuest) || showCheckoutActions}
          />
        )}
      </div>
      <div className="flex items-center h-full">
        <div className="flex items-center gap-4">
          {subscriptionStatus && !xxs && (
            <Tag className={cn(subscriptionStatus.className)}>
              {subscriptionStatus.text}
            </Tag>
          )}
          {isTeamSupporting && showSupportMessage && !xxs && (
            <Tag>
              {isPortal ? t("technical_support_in_team") : t("team_supporting")}
            </Tag>
          )}
          <div className="flex items-center gap-2">
            {showUpdates && <SupportButton />}
            {showTutorial && (
              <Tooltip
                content={t("tutorial_short")}
                arrowHidden
                className="!bg-gray-950 !rounded-[4px]"
              >
                <TutorialButton />
              </Tooltip>
            )}
            {showNotifications && (
              <Tooltip
                content={t("notifications")}
                arrowHidden
                className="!bg-gray-950 !rounded-[4px]"
              >
                <NotificationBtn />
              </Tooltip>
            )}
          </div>
          {showComments && isAiDesignPage && <CommentsButton />}
          {showCheckoutActions && <CheckoutHeaderActions />}
        </div>
        {!isSubscriptionsPage && showAvatar && (
          <ProfileDropdown
            triggerClassName="py-3 w-[80px]"
            dropdownClassName="right-0"
          />
        )}
      </div>
    </header>
  );
};

export default MainHeader;
