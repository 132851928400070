import React from "react";
import Close from "public/icons/close.svg";
import cn from "classnames";

interface ICloseButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const CloseButton: React.FC<ICloseButtonProps> = ({ className, ...props }) => {
  return (
    <button
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      className={cn(
        "flex items-center justify-center hover:scale-110",
        className
      )}
    >
      <Close />
    </button>
  );
};

export default CloseButton;
