import { useApi } from "contexts/ApiContext";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";

export interface INotificationType {
  id: number;
  content: string;
  createdat: string;
  dataReferences: {
    productOrder: {
      endtityType: string;
      entityId: number;
    };
    supplier?: {
      entityType: string;
      entityId: number;
      placeholder: string;
    };
    actor?: {
      entityType: string;
      entityId: number;
      placeholder: string;
    };
  };
  isRead: boolean;
  recipients: {
    id: number;
    createdat: string;
    isRead: boolean;
    notificationId: number;
    recipientId: number;
  }[];
  template: string;
  type: string;
  updatedat: string;
  thumbnailData: {
    imageUrl?: string;
    alternativeText?: string;
  };
}

export const useGetNotifications = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<INotificationType[]>(
    [`all-notifications`],
    () => api.notifications.getAllNotifications().then((res: any) => res.data),
    config
  );
};

export const useMarkAllAsRead = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { notifications } = useApi();

  return useMutation(() => notifications.readAllNotifications(), config);
};

export const useMarkAsRead = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { notifications } = useApi();

  return useMutation(
    (id: number) => notifications.readNotification({ notificationId: id }),
    config
  );
};
