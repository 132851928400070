import {
  ICreateWarmingSchedulerPayload,
  IGenerationOption,
  IGenerationOptionsPayload,
  IUpdateAppEnvPayload,
  IUsersActivityStatistic,
  IWhitelistedEmail,
  UserStatistics,
} from "api/AdminApi";
import axios from "axios";
import { useApi } from "contexts/ApiContext";
import { useUser } from "contexts/UserContext";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { AppEnvType, PaginatedResponse, UserActivityLog } from "types";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";

export const useGetWhitelistedEmails = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<{ data: IWhitelistedEmail[] }>(
    "whitelisted-emails",
    () => api.admin.getWhitelistedEmails(),
    config
  );
};

export const useAddEmailToWhitelist = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { emailPattern: string }) =>
      api.admin.addEmailToWhitelist(payload),
    config
  );
};

export const useRemoveEmail = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.admin.removeEmail(id), config);
};

export const useLeaveTeamAdmin = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const { userToken } = useUser();

  const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_HOST,
  });

  return useMutation(
    ({ teamId, portalId }) =>
      api.delete("/teams/leave/" + teamId, {
        headers: { teamId, portalId, Authorization: `Bearer ${userToken}` },
      }),
    config
  );
};

export const useGetGenerationOptions = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<IGenerationOption[]>(
    "generation-options",
    () => api.admin.getGenerationOptions(),
    config
  );
};

export const useUpdateGenerationOptions = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IGenerationOptionsPayload) =>
      api.admin.updateGenerationOptions(payload),
    config
  );
};

export const useGetUsersStatistics = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<PaginatedResponse<UserStatistics>>(
    ["users-statistics", params],
    () => api.admin.getUsersStatistics(params),
    config
  );
};

export const useGetUsersActivityStatistics = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<IUsersActivityStatistic>(
    ["users-report"],
    () => api.admin.getUsersActivityStatistics(),
    config
  );
};

export const useCreateWarmingScheduler = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ICreateWarmingSchedulerPayload) =>
      api.admin.createWarmingScheduler(payload),
    config
  );
};

export const useGetWarmingSchedules = (
  params: { startDate: string; endDate: string },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["warming-schedules", params],
    () => api.admin.getWarmingSchedules(params),
    config
  );
};

export const useDeleteWarmingScheduler = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (id: number) => api.admin.deleteWarmingScheduler(id),
    config
  );
};

export const useUploadFile = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: FormData) => api.admin.uploadFile(payload),
    config
  );
};

export const useUploadManyFiles = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((payload: File[]) => {
    return Promise.all(
      payload.map((el) => {
        const data = new FormData();
        data.append("file", el);
        return api.admin.uploadFile(data).then((res) => res);
      })
    );
  }, config);
};

export const useGetAppEnvs = (config?: UseQueryOptions<any, any, any, any>) => {
  const api = useApi();
  return useQuery<AppEnvType[]>(
    "app-envs",
    () => api.admin.getAppEnvs(),
    config
  );
};

export const useGetAppEnvByName = (
  name: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<AppEnvType>(
    ["app-env", name],
    () => api.admin.getAppEnvByName(name),
    config
  );
};

export const useUpdateAppEnv = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IUpdateAppEnvPayload[]) => api.admin.updateAppEnv(payload),
    config
  );
};

export const useGetAiProvidersStatus = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    "ai-providers-status",
    () => api.admin.getAiProvidersStatus(),
    config
  );
};

export const useGetUserActivityLogs = (
  params: Record<string, number | string>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  const { filter, ...rest } = params;
  return useInfiniteQuery<PaginatedResponse<UserActivityLog>>(
    ["user-activity-logs", params],
    () => api.admin.getUserActivityLogs(rest, filter as string),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};
