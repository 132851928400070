import { useMemo, useState } from "react";
import cn from "classnames";
import ReactModal from "react-modal";
import { useTeam } from "contexts/TeamContext";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { IModalProps } from "../Modal";
import Icon from "components/atoms/Icon";
import CloseIcon from "public/icons/close_16.svg";
import {
  useCreateAsset,
  useCreateManyAssets,
  useDeleteAsset,
  useEditAsset,
  useGetAssets,
} from "hooks/useAIDesignAssets";
import { AIDesignAssetType, CreateAssetPayload } from "api/AIDesignAssetsApi";
import { usePortal } from "contexts/PortalContext";
import SharedUserMediaSamples from "../Portal/SharedUserMediaSamples";
import { Translate } from "next-translate";
import { ISelectValue, TeamRoleEnum } from "types";
import { useErrorHandler } from "hooks/useErrorHandler";
import { checkRole } from "utils/checkRole";
import CustomButton from "components/atoms/CustomButton";
import MeidaLibraryModal from "public/icons/media_library_16.svg";
import MediaAssetCard from "components/atoms/MediaLibrary/MediaAssetCard";
import MediaAssetForm from "components/organisms/AIStreaming/MediaAssetForm";
import BackButton from "../BackButton";
import SearchInput from "components/atoms/SearchInput";
import { getFileExtension, getFileName } from "utils/getFileInformation";
import Select from "components/atoms/Select";
import { capitalize } from "lodash";
import MadiaLibrarySearchFilter from "../MediaLibrary/MadiaLibrarySearchFilter";
import DropdownText from "components/atoms/Benchmark/DropdownText";
import AlphaSortAscIcon from "public/icons/alpha-asc-sort_16.svg";
import AlphaSortDescIcon from "public/icons/alpha-desc-sort_16.svg";
import RangeBar from "../RangeBar";
import { PaintingToolValues } from "components/organisms/AIStreaming/painting/PaintingTools";
import { getImageAvailabilityFilterOptions } from "pages/[portalName]/[teamId]/media-lib";
import { useBreakpoint } from "contexts/BreakpointContext";

const getFilterOptions = (t: Translate) => [
  { name: t("all_images"), value: {} },
  { name: t("shared_images"), value: { "filter.isPortalSample": true } },
  { name: t("private_images"), value: { "filter.isPortalSample": false } },
];

const getSortOptions = (t: Translate) => [
  { name: t("modified_date_desc"), value: "updatedat:DESC" },
  { name: t("modified_date_asc"), value: "updatedat:ASC" },
];

const getInitialFilters = (t: Translate) => ({
  basecat: { name: capitalize(t("basecat")) },
  gender: { name: capitalize(t("simple_gender")) },
  color: { name: capitalize(t("color")) },
});

interface IAiDesignMediaLibraryModalProps extends IModalProps {
  onApplyAsset?: (asset: AIDesignAssetType, patternIntensity?: number) => void;
  updatingChannel?: boolean;
  applyTipText?: string;
  isPainting?: boolean;
  paintingType?: PaintingToolValues;
  hideUploadButton?: boolean;
  selectImageAndClose?: (asset: AIDesignAssetType) => void;
}

const AiDesignMediaLibraryModal: React.FC<IAiDesignMediaLibraryModalProps> = ({
  onApplyAsset,
  selectImageAndClose,
  updatingChannel,
  onClose,
  applyTipText,
  isPainting,
  paintingType,
  hideUploadButton,
  ...props
}) => {
  const { t } = useTranslation("common");
  const { teamId, currentTeam, isMaintainer } = useTeam();
  const imageAvailabilityOptions = getImageAvailabilityFilterOptions(
    t,
    !!isMaintainer
  );
  const { isPortal } = usePortal();
  const [isFormOpened, setIsFormOpened] = useState<boolean>(false);
  const [tempImages, setTempImages] = useState<File | File[] | null>(null);
  const [filterKeyword, setFilterKeyword] = useState<string>("");
  const [editCandidate, setEditCandidate] = useState<AIDesignAssetType | null>(
    null
  );
  const [imagesAvailabilityFilter, setImagesAvailabilityFilter] =
    useState<ISelectValue>(imageAvailabilityOptions[0]);
  const [selectedAsset, setSelectedAsset] = useState<AIDesignAssetType>();
  const [selectedFilter, setSelectedFilter] = useState<ISelectValue>(
    getFilterOptions(t)[0]
  );
  const [sortKeyword, setSortKeyword] = useState<ISelectValue>(
    getSortOptions(t)[0]
  );
  const [saveAndApplyLoading, setSaveAndApplyLoading] =
    useState<boolean>(false);
  const [keywordsFilter, setKeywordsFilter] = useState<
    Record<string, ISelectValue>
  >(getInitialFilters(t));
  const [patternIntensity, setPatternIntensity] = useState<number>(60);
  const { xxs, xs } = useBreakpoint();

  const isMobile = xxs || xs;

  const isAllowToIntaract = checkRole(currentTeam?.teamRole, [
    TeamRoleEnum.editor,
  ]);

  const { handleError } = useErrorHandler();

  const resetState = () => {
    setIsFormOpened(false);
    setEditCandidate(null);
    setTempImages(null);
    setImagesAvailabilityFilter(imageAvailabilityOptions[0]);
  };

  const { data: assets, refetch: refetchAssets } = useGetAssets(
    teamId,
    {
      ...selectedFilter.value,
      sortBy: sortKeyword.value,
      keywordsFilter,
    },
    { keepPreviousData: true }
  );

  const onSuccess = () => {
    refetchAssets();
    resetState();
  };

  const onError = (err: any) => handleError({ err });

  const onCloseModal = () => {
    onClose();
    resetState();
  };

  const { mutate: deleteAsset } = useDeleteAsset({
    onSuccess,
    onError,
  });

  const { mutate: editAsset, isLoading: isEditing } = useEditAsset({
    onSuccess,
    onError,
  });

  const { mutate: createAsset, isLoading: isCreating } = useCreateAsset({
    onSuccess,
    onError,
  });

  const { mutate: createManyAssets, isLoading: createManyLoading } =
    useCreateManyAssets({
      onSuccess,
      onError,
    });

  const handleDeleteAsset = (id: number) => {
    deleteAsset({ id });
  };

  const onSaveAsset = (
    payload: Partial<CreateAssetPayload>,
    onSuccess?: (data: AIDesignAssetType) => void
  ) => {
    if (editCandidate) {
      editAsset(
        { id: editCandidate.id, payload },
        {
          onSuccess,
        }
      );
    } else {
      createAsset(payload as CreateAssetPayload, {
        onSuccess,
        onError() {
          setSaveAndApplyLoading(false);
        },
      });
    }
  };

  const onSaveManyAssets = (payload: CreateAssetPayload[]) => {
    createManyAssets(payload);
  };

  const onSaveAndApplyAsset = (payload: Partial<CreateAssetPayload>) => {
    setSaveAndApplyLoading(true);
    const onSuccess = (data: AIDesignAssetType) => {
      onApplyAsset && onApplyAsset(data);
      onCloseModal();
      setSaveAndApplyLoading(false);
    };

    onSaveAsset(payload, onSuccess);
  };

  const onSelectKeywordsFilter = (field: string) => (val: ISelectValue) => {
    setKeywordsFilter((s) => ({
      ...s,
      [field]: {
        name: val.name,
        value: val.value,
      },
    }));
  };

  const isAssetFormDisabled = useMemo(() => {
    return (
      (isPortal && !isMaintainer && editCandidate?.isPortalSample) ||
      !isAllowToIntaract
    );
  }, [isPortal, editCandidate, isMaintainer, isAllowToIntaract]);

  const filteredAssets = useMemo(() => {
    try {
      return assets?.filter((item) => {
        const values = item.keywordSet.keywordSet.map((item) => item.value);

        return (
          filterKeyword === "" ||
          item.name?.toLowerCase().includes(filterKeyword.toLowerCase()) ||
          getFileName(item?.assetUrl)
            ?.toLowerCase()
            .includes(filterKeyword.toLowerCase()) ||
          item.description
            ?.toLowerCase()
            .includes(filterKeyword.toLowerCase()) ||
          getFileExtension(item?.assetUrl)
            ?.toLowerCase()
            .includes(filterKeyword.toLowerCase()) ||
          values.filter((item) =>
            item.toLowerCase().includes(filterKeyword.toLowerCase())
          ).length > 0
        );
      });
    } catch (e) {
      console.error(e);
      return assets;
    }
  }, [assets, filterKeyword]);

  const isTextilePattern =
    paintingType === PaintingToolValues.ChangeTextilePattern ||
    PaintingToolValues.ChangeWAPattern;

  const showSharedSamples =
    imagesAvailabilityFilter.name === t("shared_images");

  return (
    <ReactModal
      {...props}
      onRequestClose={onCloseModal}
      onAfterClose={() => resetState()}
      className={cn(
        "bg-white relative max-w-[1240px] h-[90dvh]  w-full m-10 rounded-2xl border-custom-color-1 border overflow-y-auto",
        (editCandidate || tempImages) && "!w-[640px]"
      )}
      overlayClassName="fixed bg-gray-400/80 inset-0 z-50 flex items-center justify-center"
    >
      <div className="relative w-full h-full p-8">
        {showSharedSamples && !editCandidate ? (
          <SharedUserMediaSamples
            setImageAvailabilityFilter={setImagesAvailabilityFilter}
            selectedImageAvailabilityFilter={imagesAvailabilityFilter}
            onSelect={setEditCandidate}
          />
        ) : (
          <div className="flex xs:flex-row xxs:flex-col xxs:items-start xs:items-center justify-between gap-4">
            <div className="flex-1">
              {editCandidate || tempImages ? (
                <BackButton
                  className="self-start"
                  onClick={() => {
                    setEditCandidate(null);
                    setTempImages(null);
                    setIsFormOpened(false);
                  }}
                />
              ) : (
                <h2 className="font-bold text-lg leading-tight text-title">
                  {t("select_images")}
                </h2>
              )}
            </div>
            <div className="flex xxs:w-full md:!w-auto xs:flex-row xxs:flex-col items-center gap-4">
              {!(editCandidate || tempImages) && (
                <>
                  <CustomButton
                    icon={MeidaLibraryModal}
                    viewBox="0 0 16 16"
                    label={t("upload_images")}
                    className="!py-2 xxs:w-full md:!w-auto"
                    onClick={() => {
                      setIsFormOpened(true);
                    }}
                  />
                  <SearchInput
                    namespace={""}
                    containerClassName="!pb-0 xxs:w-full md:!w-auto"
                    fieldClassName="!h-[34px]"
                    beforeIconClassName="!top-2.5"
                    value={filterKeyword}
                    onChange={(e) => setFilterKeyword(e.target.value)}
                  />
                </>
              )}
            </div>
          </div>
        )}
        {(isFormOpened || editCandidate) && (
          <MediaAssetForm
            onSaveMany={onSaveManyAssets}
            onBack={() => {
              setEditCandidate(null);
              setIsFormOpened(false);
            }}
            saveLoading={
              (isCreating || isEditing || createManyLoading) &&
              !saveAndApplyLoading &&
              !updatingChannel
            }
            applyLoading={updatingChannel || saveAndApplyLoading}
            formDisabled={isAssetFormDisabled}
            asset={editCandidate}
            className={cn(
              "pt-4",
              isMobile
                ? "block"
                : editCandidate || tempImages
                ? "block"
                : "hidden"
            )}
            onSaveAsset={onSaveAsset}
            onApplyAsset={onApplyAsset}
            applyTipText={applyTipText}
            onSaveAndApplyAsset={onSaveAndApplyAsset}
            onDelete={handleDeleteAsset}
            tempImages={tempImages}
            setTempImages={setTempImages}
            open={!editCandidate && isFormOpened}
            disableSave={showSharedSamples}
          />
        )}
        {!isFormOpened && !(editCandidate || tempImages) && (
          <>
            {!showSharedSamples && (
              <div>
                <div className="mt-8">
                  <div className="flex justify-end flex-wrap gap-4">
                    {isMaintainer && (
                      <div className="flex items-center">
                        <p className="body-100 mr-2">{t("filter") + ":"}</p>
                        <Select
                          options={getFilterOptions(t)}
                          onSelect={setSelectedFilter}
                          value={selectedFilter}
                          buttonClassName="!py-[5px]"
                          textClassName="!text-sm"
                          containerClassName="mr-4"
                          optionsContainerClassName="w-fit"
                        />
                      </div>
                    )}
                    <div className="flex items-center">
                      <MadiaLibrarySearchFilter
                        onSelect={onSelectKeywordsFilter}
                        basecat={keywordsFilter.basecat}
                        gender={keywordsFilter.gender}
                        color={keywordsFilter.color}
                      />
                    </div>
                    {isPortal &&
                      !isMaintainer &&
                      !isFormOpened &&
                      !editCandidate && (
                        <DropdownText
                          onChange={setImagesAvailabilityFilter}
                          label={imageAvailabilityOptions[0].name}
                          labelClassName="!text-xs"
                          items={imageAvailabilityOptions}
                          selectedItems={imagesAvailabilityFilter}
                        />
                      )}
                    <DropdownText
                      labelIcon={
                        <Icon
                          component={
                            sortKeyword?.value === "updatedat:DESC"
                              ? AlphaSortAscIcon
                              : AlphaSortDescIcon
                          }
                          viewBox="0 0 16 16"
                          width={16}
                          height={16}
                        />
                      }
                      items={getSortOptions(t)}
                      selectedItems={{
                        name: t("modified_date"),
                        value: sortKeyword.value,
                      }}
                      onChange={setSortKeyword}
                      menuSubTitle={t("SORT BY")}
                      checkIcon={true}
                      menuClassName="xxs:right-0 xs:right-none w-max"
                      labelClassName="!text-xs"
                      hideLabel
                    />
                  </div>
                </div>
                <div className="mt-6">
                  {!!assets?.length && (
                    <div
                      style={{
                        height: `calc(90dvh - ${
                          isPainting && isTextilePattern
                            ? isMobile
                              ? 350
                              : 230
                            : isMobile
                            ? 290
                            : 160
                        }px)`,
                      }}
                      className="grid overflow-y-auto xxs:grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2md:grid-cols-5 xl:grid-cols-6 gap-4"
                    >
                      {filteredAssets?.map((asset) => (
                        <MediaAssetCard
                          isActive={
                            isTextilePattern && selectedAsset?.id == asset.id
                          }
                          key={asset?.id}
                          asset={asset}
                          onSelect={(data) => {
                            if (selectImageAndClose) {
                              return selectImageAndClose(data);
                            }
                            if (isPainting) {
                              if (isTextilePattern) {
                                setSelectedAsset(asset);
                              } else {
                                onApplyAsset && onApplyAsset(asset);
                              }
                            } else {
                              setEditCandidate(data);
                            }
                          }}
                          // onCreateOrder={setCreateOrderWithMedia}
                          onDelete={handleDeleteAsset}
                          isSelectCard
                        />
                      ))}
                    </div>
                  )}
                </div>
                {isPainting && isTextilePattern && (
                  <div className="w-full flex items-center justify-end pt-6 md:gap-10 flex-wrap gap-4">
                    <div className="flex items-center justify-end gap-2">
                      <div className="text-sm leading-tight">
                        {t("pattern_intensity")}:
                      </div>
                      <RangeBar
                        min={0}
                        max={100}
                        value={patternIntensity}
                        setValue={setPatternIntensity}
                        width={150}
                      />
                    </div>
                    <div className="flex items-center justify-end gap-2">
                      <CustomButton
                        variant="secondary"
                        label={capitalize(t("cancel"))}
                        onClick={onCloseModal}
                      />
                      <CustomButton
                        label={capitalize(t("apply"))}
                        onClick={() => {
                          if (onApplyAsset && selectedAsset)
                            onApplyAsset(selectedAsset, patternIntensity / 100);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <div className="absolute top-4 right-4">
          <button className="p-0" onClick={onCloseModal}>
            <Icon
              component={CloseIcon}
              viewBox="0 0 16 16"
              width={16}
              height={16}
              className="fill-subtitle1"
            />
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default AiDesignMediaLibraryModal;
