export function getFileExtension(url?: string) {
  return url?.split(".")?.pop() || "";
}

export function getFileName(url?: string) {
  if (!url) return "";
  const parts = url.split("/");
  const fileFullName = parts[parts.length - 1];
  const fileNameParts = fileFullName.split(".");
  const fileName = fileNameParts[0];

  return fileName;
}

export function getFullFileName(url?: string) {
  if (!url) return "";
  const parts = url.split("/");
  const fileFullName = parts[parts.length - 1];

  return fileFullName;
}
