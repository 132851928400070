import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import cn from "classnames";
import Close from "public/icons/close.svg";
import FolderIcon from "public/icons/folder-icon_16.svg";
import MediaLibaryIcon from "public/icons/media_16.svg";
import CheckIcon from "public/icons/check_16.svg";
import useTranslation from "next-translate/useTranslation";
import Icon from "components/atoms/Icon";
import { useTeam } from "contexts/TeamContext";
import { useGetAssetFolders } from "hooks/useAssetFolders";
import { isDev } from "utils/checkEnv";
import BigSpinner from "components/atoms/BigSpinner";
import CustomButton from "components/atoms/CustomButton";
import { capitalize } from "lodash";
import { AIDesignAssetType } from "api/AIDesignAssetsApi";
import { getFullFileName } from "utils/getFileInformation";
import AddFolderIcon from "public/icons/add_folder_18.svg";

interface AssetMoveModalProps {
  asset: AIDesignAssetType;
  isOpen: boolean;
  currentFolderId: number;
  onClose: () => void;
  onMove: (assetId: number, folderId: number) => void;
  onOpenNewFolderDlg: () => void;
}

const AssetMoveModal: React.FC<AssetMoveModalProps> = ({
  asset,
  isOpen,
  onMove,
  onClose,
  currentFolderId,
  onOpenNewFolderDlg,
}) => {
  const { teamId } = useTeam();
  const { t } = useTranslation("common");

  const [targetId, setTargetId] = useState<number>(currentFolderId);
  const [isAddNewFolder, setIsAddNewFolder] = useState<boolean>(false);

  useEffect(() => {
    if (targetId !== currentFolderId) {
      setTargetId(currentFolderId);
    }
  }, [currentFolderId]);

  // get all asset folders
  const {
    data: assetFolers,
    isLoading: isLoadingAssetFolders,
    refetch: refetchAssetFolder,
  } = useGetAssetFolders(teamId, {
    enabled: isDev(),
  });

  const handleMoveAsset = () => {
    if (targetId !== currentFolderId) {
      onMove(asset.id, targetId);
      onClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`bg-white rounded-2xl w-[480px] max-h-[600px] border-custom-color-1 border`}
      overlayClassName="fixed bg-gray-400/80 h-full overflow-y-auto inset-0 z-[60] flex items-center lg:items-center justify-center"
    >
      {onClose && (
        <div className={"absolute top-4 right-4"}>
          <button
            onClick={onClose}
            className="flex items-center justify-center hover:scale-110"
          >
            <Close />
          </button>
        </div>
      )}
      <div className="p-8">
        <div className="text-2xl text-typography-headline leading-normal font-medium line-clamp-2">
          {t("move_asset", {
            assetName: asset.name || getFullFileName(asset.assetUrl),
          })}
        </div>
        {isLoadingAssetFolders ? (
          <div
            className={cn(
              "mt-8 flex items-center justify-center w-full h-[120px]"
            )}
          >
            <BigSpinner className="w-12 h-12" />
          </div>
        ) : (
          <div className="mt-8 overflow-y-scroll max-h-[330px]">
            <FolderItem
              icon={MediaLibaryIcon}
              folderName={t("library")}
              onSelect={() => setTargetId(0)}
              isActive={targetId === 0}
            />
            {assetFolers?.data.map((item) => (
              <FolderItem
                icon={FolderIcon}
                folderName={item.name}
                onSelect={() => setTargetId(item.id)}
                isActive={targetId === item.id}
              />
            ))}
          </div>
        )}
        <div className="mt-10 flex items-center justify-between gap-2 ">
          <CustomButton
            icon={AddFolderIcon}
            iconClassName="!fill-white"
            variant="tertiary"
            className="!p-2"
            onClick={onOpenNewFolderDlg}
          />
          <div className="flex items-center justify-end gap-2">
            <CustomButton
              variant="secondary"
              label={capitalize(t("cancel"))}
              onClick={onClose}
            />
            <CustomButton
              label={capitalize(t("move"))}
              onClick={handleMoveAsset}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default AssetMoveModal;

const FolderItem = (props: {
  icon: any;
  folderName: string;
  isActive: boolean;
  onSelect: () => void;
}) => (
  <div
    className={cn(
      "p-2 flex items-center justify-between rounded-md hover:bg-gray-20 cursor-pointer",
      props.isActive && "!bg-gray-30"
    )}
    onClick={props.onSelect}
  >
    <div className="flex items-center justify-start gap-2">
      <Icon
        component={props.icon}
        viewBox="0 0 16 16"
        width={16}
        height={16}
        className="fill-gray-950"
      />
      <span className="text-sm text-typography-headline">
        {props.folderName}
      </span>
    </div>
    {props.isActive && (
      <Icon
        component={CheckIcon}
        viewBox="0 0 16 16"
        width={16}
        height={16}
        className="fill-gray-950"
      />
    )}
  </div>
);

interface TabButtonProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const TabButton = ({ label, isActive, onClick }: TabButtonProps) => (
  <button
    className={cn(
      "text-sm leading-normal py-2 text-typography-tab-passive font-semibold",
      isActive && "text-gray-950 border-b border-gray-950"
    )}
    onClick={onClick}
  >
    {label}
  </button>
);
